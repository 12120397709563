import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import camera from "../../assets/camera.svg";
import { TextField, FormLabel, MenuItem, Input, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";
import { GetRoles } from "../../state/actions/roleActions";
import { AddAdmin, UpdateAdmin } from "../../state/actions/adminActions";
import validator from "validator";
import { useForm, Form } from "../../utils/useForm";
import { useSnackbar } from "notistack";
import BackdropComponent from "../backdrop/backdrop";

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "50px",
  },
  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "14px",
  },
}));
const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
}));
const StyledIconWrapper = styled(Box)(() => ({
  position: "relative",

  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",
  img: {
    marginTop: "30px",
  },
}));

const StyledInput = styled("input")({
  display: "none",
});
const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});

const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});

const initialValues = {
  name: "",
  email: "",
  mobile: "",
  role: "",
};

const Adduser = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { open, refreshScreen, handleClose, user } = props;
  const [rolesList, setRolesList] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [backdropState, setBackdropState] = useState(false);
  const [image, setImage] = useState("");

  const stopBackdrop = () => {
    setBackdropState(false);
  };
  const startBackdrop = () => {
    setBackdropState(true);
  };

  const getRolesList = async (page) => {
    let queryString = "?page=" + page + "&size=-1";
    const res = await GetRoles(queryString);
    if (res && res.status) {
      setRolesList(res.data);
    }
  };
  useEffect(() => {
    if (open) {
      getRolesList(1);
    }
  }, [open]);

  useEffect(() => {
    if (user) {
      setValues({
        ...values,
        name: user.name,
        email: user.email,
        role: user.role,
        mobile: user.mobile,
      });
      setImage(user.profile_image);
    }
  }, [user]);

  const resetImage = () => {
    setImage("");
  };
  const reset = () => {
    resetImage();
    resetForm();
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";

    if ("email" in fieldValues)
      temp.email = fieldValues.email ? "" : "This field is required.";

    if ("mobile" in fieldValues) {
      if (fieldValues.mobile !== "") {
        if (/^[0-9\s]*$/.test(fieldValues.mobile)) {
          temp.mobile = "";
        } else {
          temp.mobile = "Only Number allow";
        }
      } else {
        temp.mobile = "This field is required.";
      }
    }

    if ("role" in fieldValues)
      temp.role = fieldValues.role ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      if (!validator.isEmail(values.email)) {
        enqueueSnackbar("Invalid Email !", { variant: "error" });
      } else {
        startBackdrop();
        if (user) {
          const body = values;
          const data = { body, id: user.id };
          startBackdrop();
          const res = await UpdateAdmin(data);
          if (res && res.status === true) {
            close();
            refreshScreen();
            enqueueSnackbar(res.message, { variant: "success" });
          }
          if (res && res.status === false) {
            enqueueSnackbar(res.message, { variant: "error" });
          }
        } else {
          const res = await AddAdmin(values);
          if (res && res.status === true) {
            close();
            refreshScreen();
            enqueueSnackbar(res.message, { variant: "success" });
          }
          if (res && res.status === false) {
            enqueueSnackbar(res.message, { variant: "error" });
          }
        }
        stopBackdrop();
      }
    }
  };

  const openConfirmDialog = () => {
    setConfirmDialog(true);
  };
  const closeConfirmDialog = () => {
    setConfirmDialog(false);
  };
  const close = () => {
    reset();
    handleClose();
  };
  const removeImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["profile_image"];
      return newValues;
    });
  };
  async function handleImageItem(e) {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      const base64String = await getBase64(selected);
      setImage(base64String);
      setValues({ ...values, profile_image: selected });
    } else {
    }
  }
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  return (
    <div>
      <BackdropComponent open={backdropState} />
      {/* add new user modal */}
      <Dialog
        open={open}
        onClose={openConfirmDialog}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {user ? "Edit Admin" : "Add New Admin"}
          <Tooltip title="Close">
            <IconButton onClick={openConfirmDialog}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        {/* <form onSubmit={handleSubmit}> */}
        <DialogContent>
          <form onSubmit={handleSubmit} id="addNewAdmin">
            <Grid container spacing={2}>
              <Grid item md={3} sm={4} xs={12}>
                <StyledImageUploadWrapper>
                  <label htmlFor="image-upload">
                    <StyledInput
                      accept="image/*"
                      id="image-upload"
                      type="file"
                      onChange={handleImageItem}
                      onClick={(event) => {
                        event.currentTarget.value = null;
                      }}
                    />
                    <StyledIconWrapper
                      sx={
                        image == ""
                          ? {}
                          : {
                              backgroundImage: `url(${image})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                            }
                      }
                    >
                      {image === "" && <img src={camera} alt="Camera" />}
                    </StyledIconWrapper>
                  </label>
                  <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                    Add Photo
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(15, 15, 15, 0.5)",
                      mx: 2,
                      fontSize: "12px",
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, max size of 3.1 MB
                  </Typography>
                  {image != "" && (
                    <StyledRemoveButton
                      onClick={(e) => {
                        e.preventDefault();
                        removeImage();
                      }}
                    >
                      Remove
                    </StyledRemoveButton>
                  )}
                </StyledImageUploadWrapper>
              </Grid>
              <Grid item md={9} sm={8} xs={12} container spacing={1}>
                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <StyledFormLabel htmlFor="name">
                    Name
                    {Boolean(errors.name) && (
                      <StyledRequired> * </StyledRequired>
                    )}
                  </StyledFormLabel>
                  <StyledTextField
                    fullWidth
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleInputChange}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <StyledFormLabel htmlFor="email">
                    Email{" "}
                    {Boolean(errors.email) && (
                      <StyledRequired> * </StyledRequired>
                    )}
                  </StyledFormLabel>
                  <StyledTextField
                    fullWidth
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <StyledFormLabel htmlFor="phone_number">
                    Phone Number
                    {Boolean(errors.mobile) && (
                      <StyledRequired> * </StyledRequired>
                    )}
                  </StyledFormLabel>
                  <StyledTextField
                    fullWidth
                    name="mobile"
                    id="mobile"
                    value={values.mobile}
                    onChange={handleInputChange}
                    error={Boolean(errors.mobile)}
                    helperText={errors.mobile}
                    inputProps={{ maxLength: 15 }}
                  />
                </Grid>

                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <StyledFormLabel htmlFor="role">
                    Role
                    {Boolean(errors.role) && (
                      <StyledRequired> * </StyledRequired>
                    )}
                  </StyledFormLabel>
                  <StyledTextField
                    name="role"
                    fullWidth
                    select
                    value={values.role}
                    error={Boolean(errors.role)}
                    onChange={handleInputChange}
                    helperText={errors.role}
                  >
                    {rolesList.map((role) => {
                      return (
                        <MenuItem key={role.id} value={role.id}>
                          {role.name}
                        </MenuItem>
                      );
                    })}
                  </StyledTextField>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions sx={{ margin: "15px" }}>
          <Button
            sx={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid #0F0F0F80",
              borderRadius: "10px",
              height: "3em",
              padding: "10px 25px",
            }}
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: "#60579A",
              height: "3em",
              color: "white",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                backgroundColor: "#60579A",
              },
            }}
            type="submit"
            form="addNewAdmin"
          >
            {user ? "Update" : "Add"} Admin
          </Button>
        </DialogActions>
        {/* </form> */}
      </Dialog>
      {/* confirmation dialog */}
      <Dialog
        id="confirm-dialog"
        open={confirmDialog}
        onClose={closeConfirmDialog}
        sx={{
          "&#confirm-dialog .MuiPaper-root": {
            borderRadius: "15px",
          },
        }}
      >
        <DialogContent>
          Are you sure you want to Close this form ?
        </DialogContent>
        <DialogActions
          sx={{
            margin: "15px",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <Button
            sx={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid #0F0F0F80",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                // backgroundColor: "#60579A",
              },
            }}
            onClick={closeConfirmDialog}
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: "#60579A",
              color: "white",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                backgroundColor: "#60579A",
              },
            }}
            // type="submit"
            onClick={() => {
              closeConfirmDialog();
              close();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Adduser;
