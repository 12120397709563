import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
  Visibility,
  Visibility as VisibilityIcon,
  Sort as SortIcon
} from "@mui/icons-material";
import {
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  Pagination,
  tableCellClasses,
} from "@mui/material";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,

} from "@mui/material";
import {
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { Send as SendIcon, Details as DetailsIcon } from "@mui/icons-material";

// icons for user menu
import eye from "../../../assets/eye.svg";
import edit from "../../../assets/edit.svg";
import deleteIcon from "../../../assets/delete.svg";
import block from "../../../assets/block.svg";

//  filter icons
import filterIcon from "../../../assets/filter.svg";
//  modals with respect to menu
import SupplierDetailsModal from "../AsiSupplierDetails";
import SupplierBlockModal from "./SupplierBlock";
import SupplierDeleteModal from "./SupplierDelete";

import { useSnackbar } from "notistack";
import BackdropComponent from "../../backdrop/backdrop";

import { useForm } from "../../../utils/useForm";
import { GetSuppliersList } from "../../../state/actions/supplierActions";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "12px",
    paddingTop: "10px",
    paddingBottom: "10px",
    position: "sticky",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    // hover color add 
    "&:hover": {
      color: "#60579A",
    },
  },
}));

const StyledTableColumns = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  "&:hover": {
    cursor: "pointer",
    ".header-icon": {
      visibility: "visible",
    },
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
}));
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const SupplierList = (props) => {
  const { refresh, refreshScreen } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [backdropState, setBackdropState] = useState(false);
  const stopBackdrop = () => {
    setBackdropState(false);
  };
  const startBackdrop = () => {
    setBackdropState(true);
  };

  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const handleChangePage = (event, value) => {
    setPage(value);
    getSuppliersList(value);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentUser, setCurrentUser] = useState(-1);
  const selectCurrentUser = (index) => {
    setCurrentUser(index);
  };
  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCurrentUser(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setCurrentUser(-1);
  };

  const getSuppliersList = async (page) => {
    let queryString = "?page=" + page + "&size=" + pagePerSize;
    startBackdrop();
    const res = await GetSuppliersList(queryString);
    if (res && res.status) {
      setSuppliers(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setSuppliers([]);
      setTotalRecords([]);
      setTotalPages(0);
    }
    stopBackdrop();
  };

  useEffect(() => {
    getSuppliersList(page);
  }, [refresh]);

  const onSearch = async (search) => {
    let queryString =
      "?page=" + page + "&size=" + pagePerSize + "&search=" + search;
    startBackdrop();
    const res = await GetSuppliersList(queryString);
    if (res && res.status) {
      setSuppliers(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setSuppliers([]);
      setTotalRecords([]);
      setTotalPages(0);
    }
    stopBackdrop();
  };

  const [filter, setFilter] = useState(false);
  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
  };
  const initialValues = {
    name: "",
    email: "",
    mobile: "",
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const onFilterSubmit = async (e) => {
    e.preventDefault();

    let queryString = "?page=" + page + "&size=" + pagePerSize;
    Object.keys(values).map((key) => {
      queryString += "&" + key + "=" + values[key];
    });
    startBackdrop();
    const res = await GetSuppliersList(queryString);
    if (res && res.status) {
      setSuppliers(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setSuppliers([]);
      setTotalRecords([]);
      setTotalPages(0);
    }
    stopBackdrop();
    closeFilterDrawer();
  };

  const [userView, setUserView] = useState(false);

  const handleUserView = () => {
    setUserView(true);
  };
  const closeUserView = () => {
    setUserView(false);
    setCurrentUser(-1);
  };

  //  to enable sorting in every table while clicking on column name
  const [column, setColumn] = useState("");
  const handleColumn = (value) => {
    setColumn(value);
  };
  const [sort, setSort] = useState(false);
  const handleSort = () => {
    setSort(!sort);
  };
  const sorting = () => {
  };
  useEffect(() => {
    sorting();
  }, [column, sort]);

  const tableColumns = [
    { label: "Name", name: "name", isSortable: true },
    { label: "Email", name: "email", isSortable: true },
    { label: "Phone Number", name: "mobile", isSortable: true },
    { label: "ASI Number", name: "", isSortable: false },
    { label: "Action", name: "", isSortable: false, styleProps: { align: 'center' } },
  ];


  return (
    <>
      <BackdropComponent open={backdropState} />
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        {/* search bar and filter button */}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "50px",
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => onSearch(e.target.value)}
            />
          </Search>

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            Filter
          </Button>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form onSubmit={onFilterSubmit}>
            <Box
              sx={{
                width: 220,
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={{ fontWeight: "bold" }}>Filter</Typography>
                <IconButton sx={{ color: "black" }} onClick={closeFilterDrawer}>
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="name">Name</StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Name"
                  fullWidth
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="email">Email</StyledFormLabel>
                <StyledTextField
                  type="email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Email"
                  fullWidth
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="mobile">Phone Number</StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Phone Number"
                  fullWidth
                  id="mobile"
                  name="mobile"
                  value={values.workPhone}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />
            </Box>
            <Stack
              sx={{ margin: "195px 20px 10px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    // backgroundColor: "#60579A",
                  },
                }}
                onClick={closeFilterDrawer}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: "#60579A",
                  color: "white",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#60579A",
                  },
                }}
                type="submit"
              >
                Filter
              </Button>
            </Stack>
          </form>
        </Drawer>
        <TableContainer
          component={Paper}
          sx={{ minHeight: "235px" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>

                {tableColumns.map((columns, index) => {
                  const { label, isSortable, name } = columns;
                  return (
                    <StyledTableCell style={{
                      fontWeight: "700",
                      fontSize: "16px",
                    }} key={index}>
                      <StyledTableColumns
                        onClick={() => {
                          if (isSortable) {
                            handleColumn(name);
                            handleSort();
                          }
                        }}
                      >
                        {label}
                        <Typography
                          className={isSortable ? "header-icon" : ""}
                          sx={{
                            visibility:
                              isSortable && column === name
                                ? "visible"
                                : "hidden",
                          }}
                        >
                          <SortIcon
                            className={`sort-table ${sort ? "sort-desc" : "sort-asc"
                              }`}
                          />
                        </Typography>
                      </StyledTableColumns>
                    </StyledTableCell>
                  );
                })}

              </TableRow>
            </TableHead>
            <TableBody>
              {suppliers.map((row, index) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Typography>{row.name}</Typography>
                  </TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.workPhone}</TableCell>
                  {/*<TableCell>{row.type}</TableCell>*/}
                  <TableCell>{row.asiNumber}</TableCell>
                  <TableCell>
                    <Tooltip title="View Details">
                      <IconButton
                        sx={{ borderRadius: "0px" }}
                        onClick={() => {
                          selectCurrentUser(index);
                          handleUserView();
                        }}
                      >
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                    &nbsp;
                    <Tooltip title="Send Request">
                      <IconButton
                        sx={{ borderRadius: "0px" }}
                        onClick={() => {
                          selectCurrentUser(index);
                        }}
                      >
                        <SendIcon />
                      </IconButton>
                    </Tooltip>
                    &nbsp;
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <SupplierDetailsModal
          user={suppliers[currentUser]}
          open={userView}
          setOpen={setUserView}
          handleClose={closeUserView}
        />
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
};

export default SupplierList;
