import * as React from "react";
import Box from "@mui/material/Box";
import {
  Card,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SwipeableDrawer,
  Tab,
  TableContainer,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Grid, Button, Stack, Typography, IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import { Table, TableHead, TableBody, TableRow, Chip } from "@mui/material";
import "./orderDetails.css";
import {
  adminStatusChange,
  getOrderDetails,
  GetOrderStatusList,
  GetOrderSuppliersList,
  getSupplierOrderDetails,
  supplierQuatation,
  supplierQuotationsAccepted,
} from "../../state/actions/orderAction";
import moment from "moment";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import { useForm } from "../../utils/useForm";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "15px",
    paddingTop: "10px",
    paddingBottom: "10px",
    position: "sticky",
    fontWeight: "bold",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#0957dd0a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledLabel = styled("label")(() => ({
  display: "inline-block",
  fontWeight: "500",
  fontSize: "14px",
  mt: 1,
  borderRadius: "8px",
}));

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const initialValues = {
  name: "",
  status: "",
};

export default function FormPropsTextFields() {
  const [orderProductData, setorderProductData] = useState([]);
  const [orderData, setorderData] = useState([]);
  console.log(
    "🚀 ~ file: AdminSupplierOrdersDetails.jsx:88 ~ FormPropsTextFields ~ orderData:",
    orderData
  );

  const location = useLocation();
  const [supplshippingAddress, setshippingAddress] = useState({});

  const [value, setValue] = useState("1");

  const [supplierList, setSupplierList] = useState([]);
  const [supplierName, setSupplierName] = useState();

  const [isStatusEditing, setStatusEditing] = useState(false);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [quotationId, setQuotationId] = useState(0);
  const [statusMenu, setStatusMenu] = useState(true);
  const [city, setCity] = useState([]);
  const [states, setStates] = useState([]);
  const [country, setCountry] = useState([]);
  const [orderStatusList, setOrderStatusList] = useState([]);
  const [hideButton, setHideButton] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const openStatusMenu = () => {
    setStatusMenu(true);
  };
  const closeStatusMenu = () => {
    setStatusMenu(false);
  };

  const startStatusEditing = (id) => {
    setStatusEditing(id);
    setQuotationId(id);
  };

  const startStatusUpdate = (e, index, i) => {
    let newArr = [...orderData];
    newArr[index][i][e.target.name] = e.target.value;
    setorderData(newArr);
    // setorderData({ ...orderData, [e.target.name]: e.target.value });
  };

  const stopStatusEditing = () => {
    setStatusEditing();
  };

  const handleChanges = (e, index) => {
    // setSupplierName({ ...supplierName, [e.target.id]: e.target.value });
    let newArr = [...supplierName]; // copying the old datas array
    newArr[index][e.target.name] = e.target.value;
    setSupplierName(newArr);
  };

  const getAllSupplierList = async () => {
    const res = await GetOrderSuppliersList();
    if (res && res.status) {
      setSupplierList(res.data);
    }
  };

  useEffect(() => {
    setShowEditIcon(orderData[orderData.length - 1]);
    getAllSupplierList();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const { state } = useLocation();
  const id = state;

  const OrderDetails = async (id) => {
    const res = await getSupplierOrderDetails(id);
    console.log(
      "🚀 ~ file: AdminSupplierOrdersDetails.jsx:163 ~ OrderDetails ~ res:",
      res?.data[0]?.shippingAddress
    );
    if (res && res.status === true) {
      setorderProductData(res.orderData);
      setorderData(res.data);
      // setcustomerData(res.data.customer);
      setshippingAddress(res?.data[0]?.shippingAddress);
      // setCity(res.orderData.shippingAddressId?.city);
      // setStates(res.orderData.shippingAddressId?.state);
      // setCountry(res.orderData.shippingAddressId?.country);
      // setValues({
      //   ...values,
      //   status: res.data[0].status,
      // });
      // setbillingAddress(res.data.billingAddress);
    }
  };

  useEffect(() => {
    OrderDetails(id);
  }, [id]);

  const productStatus = (productstatus) => {
    const statusColors = {
      1: {
        key: "Request for quote",
        backgroundColor: "#ede7dd",
        color: "#0e9f6e",
      },
      2: {
        key: "Quote Provided",
        backgroundColor: "#fdf6b2",
        color: "#c27803",
      },

      3: {
        key: "Assign Order",
        backgroundColor: "#def7ec",
        color: "gray",
      },
      4: { key: "Order Accepted", backgroundColor: "#def7ec", color: "gray" },
      5: { key: "Rejected", backgroundColor: "#d1d1d1", color: "#f00e0e" },
    };

    return (
      <StyledChip
        style={{
          height: "39px",
          fontSize: "15px",
        }}
        sx={statusColors[productstatus]}
        label={
          statusColors[productstatus] ? statusColors[productstatus].key : ""
        }
      />
    );
  };

  const orderStatus = (orderstatus) => {
    const statusColors = {
      1: {
        key: "Pending",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      2: {
        key: "InProgress",
        backgroundColor: "#fdf6b2",
        color: "#c27803",
      },
      3: {
        key: " Ready to dispatch ",
        backgroundColor: "#def7ec",
        color: "#0e9f6e",
      },
      4: {
        key: "Shipped ",
        backgroundColor: "#def7ec",
        color: "blue",
      },
      5: {
        key: "Delivered",
        backgroundColor: "#def7ec",
        color: "green",
      },
      6: {
        key: "Cancel",
        backgroundColor: "#def7ec",
        color: "black",
      },
      7: {
        key: "Failed",
        backgroundColor: "#def7ec",
        color: "red",
      },
    };

    return (
      <StyledChip
        style={{
          height: "32px",
          fontSize: "17px",
        }}
        sx={statusColors[orderstatus]}
        label={statusColors[orderstatus] ? statusColors[orderstatus].key : ""}
      />
    );
  };

  // const orderstatus = orderData?.map((x) => x.status);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true);

  const user = useSelector((state) => state?.auth?.user);

  const onSubmit = async (datas) => {
    const body = {
      orderId: datas.orderId,
      supplierId: user?.id,
      productId: datas.itemId,
      itemQty: datas.itemQty,
      supplierPrice: datas.supplierPrice,
      totalAmount: datas.itemPrice,
      status: 3,
      type: 2,
      supplierOrderId: datas.supplierOrderId,
    };
    const res = await supplierQuatation(body);
    if (res && res.status === true) {
      enqueueSnackbar(res.message, { variant: "success" });
    }
    if (res && res.status === false) {
      enqueueSnackbar(
        res.message || "Unexpected error occured please try again later.",
        { variant: "error" }
      );
    }
  };

  const approveQuotation = async (quotationid, status) => {
    const body = {
      status: status,
    };
    const res = await supplierQuotationsAccepted(quotationid, body);
    setHideButton(true);
    if (res && res.status === true) {
      enqueueSnackbar(res.message, { variant: "success" });
    } else {
      enqueueSnackbar("Unexpected error occured please try again later.", {
        variant: "error",
      });
    }
  };

  const getorderStatus = async () => {
    const res = await GetOrderStatusList();
    if (res && res.status) {
      setOrderStatusList(res.data);
    }
  };

  useEffect(() => {
    getorderStatus();
  }, []);

  return (
    <>
      <Card
        style={{
          padding: "0px",
          borderRadius: "12px",
          marginTop: "20px",
          overflow: "visible",
          boxShadow: "none",
        }}
      >
        <Box sx={{ width: "100%", typography: "body1" }}>
          <Grid
            container
            spacing={2}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ marginTop: "10px", marginLeft: "10px" }}
          >
            <Grid xs={2} sm={4} md={6}>
              <Typography variant="h2" id="purchase-order-details-number">
                Order Details
              </Typography>
              {orderData?.map((row) => {
                <>
                  <Typography sx={{ marginTop: "10px" }}>
                    Product Order No. {row?.orderNumber}
                  </Typography>
                </>;
              })}

              <Typography
                sx={{
                  color: "rebeccapurple",

                  marginTop: "20px",
                  fontSize: "20px",
                }}
              >
                Shipping Address
              </Typography>
              <Typography>
                {supplshippingAddress?.address} {supplshippingAddress?.cityName}{" "}
                {supplshippingAddress?.stateName}, {supplshippingAddress?.name}{" "}
                {supplshippingAddress?.zipcode}
              </Typography>
            </Grid>
            <Grid xs={2} sm={4} md={2}>
              <Typography sx={{ fontSize: "14px", marginLeft: "6px" }}>
                Order Date
              </Typography>
              <Typography
                variant="h6"
                id="purchase-order-details-status-value2"
                sx={{ fontSize: "20px" }}
              >
                {moment(orderData.createdAt).format("DD/MM/YYYY")}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item>
              {orderData.map((row, i) => {
                return (
                  <TableContainer
                    component={Paper}
                    sx={{
                      minHeight: "300px",
                      marginTop: "50px",
                      boxShadow: "none",
                      width: "100%",
                    }}
                  >
                    <>
                      <Typography>Supplier Name: {row.supplierName}</Typography>
                    </>

                    <Table stickyHeader aria-label="simple table">
                      <TableHead>
                        <StyledTableCell>Product Name</StyledTableCell>
                        <StyledTableCell>Date</StyledTableCell>
                        <StyledTableCell>Quantity</StyledTableCell>
                        <StyledTableCell>Supplier Price</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                      </TableHead>
                      <TableBody>
                        {row?.item?.map((data, index) =>
                          row?.item?.length - 1 === index ? (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <TableCell>
                                <Stack flexDirection="row" alignItems="center">
                                  <Avatar
                                    component="span"
                                    variant="rounded"
                                    alt="profile_image"
                                    src={data?.images}
                                  />
                                  <Typography
                                    sx={{
                                      fontFamily: "Work Sans",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "16px",
                                      lineHeight: "34px",
                                      ml: 2,
                                    }}
                                  >
                                    {data.productName}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontFamily: "Work Sans",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  fontSize: "16px",
                                  lineHeight: "34px",
                                }}
                              >
                                {moment(data.createdAt).format(
                                  "MMM DD, YYYY, h:mm:ss a"
                                )}
                              </TableCell>
                              <TableCell>{data.itemQty}</TableCell>
                              {isStatusEditing === data.id &&
                              data.status != "4" &&
                              data.status != 5 ? (
                                <Box sx={{ marginTop: "15px" }}>
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    id="supplierPrice"
                                    name="supplierPrice"
                                    value={data?.supplierPrice}
                                    open={statusMenu}
                                    onOpen={openStatusMenu}
                                    onKeyPress={(e) => {
                                      if (e.key === "Enter") {
                                        stopStatusEditing();
                                        closeStatusMenu();
                                      }
                                    }}
                                    onChange={(event) =>
                                      startStatusUpdate(event, i, index)
                                    }
                                    onBlur={() => stopStatusEditing()}
                                    sx={{ width: "200px", marginTop: "45px" }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment
                                          position="end"
                                          sx={{ color: " rgba(0, 0, 0, 0.87)" }}
                                        >
                                          <EditIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  >
                                    {data?.supplierPrice}
                                  </TextField>
                                </Box>
                              ) : (
                                <Button
                                  onClick={() => {
                                    startStatusEditing(data.id);
                                    openStatusMenu();
                                  }}
                                  size="large"
                                  style={{
                                    color: " rgba(0, 0, 0, 0.87)",
                                    margin: "17px",
                                  }}
                                  endIcon={<EditIcon />}
                                >
                                  {data?.supplierPrice
                                    ? data?.supplierPrice
                                    : 0.0}
                                </Button>
                              )}
                              <TableCell>
                                {productStatus(data?.status)}
                              </TableCell>
                              <TableCell>
                                {data.status != "4" && data.status != 5 ? (
                                  <Button
                                    variant="contained"
                                    onClick={() => onSubmit(data)}
                                    sx={{
                                      fontFamily: "Work Sans",
                                      fontStyle: "normal",
                                      fontWeight: 500,
                                      fontSize: "13px",
                                      lineHeight: "24px",
                                      background: "#60579A",

                                      boxShadow:
                                        "0px 1px 2px rgba(16, 24, 40, 0.05)",
                                      borderRadius: "10px",
                                      width: "30%",
                                      marginTop: "5px",
                                    }}
                                  >
                                    Send
                                  </Button>
                                ) : null}
                                {!hideButton &&
                                data?.status !== 4 &&
                                data.status != 5 ? (
                                  <Button
                                    variant="contained"
                                    sx={{
                                      fontFamily: "Work Sans",
                                      fontStyle: "normal",
                                      fontWeight: 500,
                                      fontSize: "13px",
                                      lineHeight: "24px",
                                      background: "#60579A",
                                      boxShadow:
                                        "0px 1px 2px rgba(16, 24, 40, 0.05)",
                                      borderRadius: "10px",
                                      width: "49%",
                                      marginLeft: "10px",
                                      marginTop: "5px",
                                    }}
                                    onClick={() => approveQuotation(data.id, 4)}
                                  >
                                    Accepted
                                  </Button>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          ) : (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <TableCell>
                                <Stack flexDirection="row" alignItems="center">
                                  <Avatar
                                    component="span"
                                    variant="rounded"
                                    alt="profile_image"
                                    src={data?.images}
                                  />
                                  <Typography
                                    sx={{
                                      fontFamily: "Work Sans",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "16px",
                                      lineHeight: "34px",
                                      ml: 2,
                                    }}
                                  >
                                    {data.productName}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontFamily: "Work Sans",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  fontSize: "16px",
                                  lineHeight: "34px",
                                }}
                              >
                                {moment(data.createdAt).format(
                                  "MMM DD, YYYY, h:mm:ss a"
                                )}
                              </TableCell>
                              <TableCell>{data.itemQty}</TableCell>

                              <TableCell>{data.supplierPrice}</TableCell>

                              <TableCell sx={{ width: "auto" }}>
                                {productStatus(data?.status)}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
}
