import { useState } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  InputAdornment
} from "@mui/material";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { TextField, IconButton, Tooltip, Button, Stack } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { styled, useTheme, useMediaQuery } from "@mui/material";

import RectangleGlass from "../../assets/Rectangle_glass.png";

const StyledLeftSide = styled("div")(() => ({
  borderRadius: "10px",
  backgroundColor: "#60579A",
  height: "88vh",
  position: "relative"
}));
const StyledRightSide = styled("div")(() => ({
  borderRadius: "10px",
  height: "88vh"
}));
const StyledLabel = styled("label")(() => ({
  display: "inline-block",
  fontWeight: "500",
  fontSize: "14px",
  mt: 1,
  borderRadius: "8px"
}));
const StyledLogin = styled(Button)(() => ({
  backgroundColor: "#60579A",
  borderRadius: "10px",
  color: "white",
  fontSize: "16px",
  padding: "8px 20px",
  "&:hover": {
    backgroundColor: "#60579A",
    color: "white"
  }
}));
const ResetPassword = () => {
  const lg = useMediaQuery("@media (max-width:900px)");
  const xs = useMediaQuery("@media (max-width:600px)");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmNewPassword] = useState(false);

  const handleNewPasswordChange = e => {
    setNewPassword(e.target.value);
  };
  const handleConfirmPasswordChange = e => {
    setConfirmPassword(e.target.value);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmNewPassword(!showConfirmPassword);
  };
  const handleSubmit = e => {
    e.preventDefault();
    //    here starts our validation part
  };
  return (
    <div>
      <Grid
        container
        spacing={1}
        sx={{ backgroundColor: "#F8F8F8;", height: "100vh" }}
      >
        <Grid item lg={5} md={5} sm={5} xs={12} sx={{ margin: "35px 20px" }}>
          <StyledLeftSide>
            <Box
              sx={{
                position: "absolute",
                borderRadius: "10px",
                height: "-webkit-fill-available",
                backgroundColor: "rgba(255, 255, 255, 0.2);",
                margin: lg ? "20px" : "40px",
                color: "white",
                padding: lg ? "0px 15px" : "0px 50px"
              }}
            >
              <h1>Welcome to Press Emporium</h1>
              <p>
                “Our approach to sourcing is simple… We offer the finest quality
                materials and a final product that delivers with impact !”
              </p>
            </Box>
            <img
              src={RectangleGlass}
              style={{ width: "100%", height: "100%" }}
              alt="Login Background"
            />
          </StyledLeftSide>
        </Grid>
        <Grid item lg={5} md={5} sm={5} xs={12} sx={{ margin: "35px 20px" }}>
          <StyledRightSide>
            <Container sx={xs ? {} : { pt: "20%" }}>
              <Box sx={{ mb: 3 }}>
                <Typography
                  component={"h4"}
                  sx={{
                    display: "inline-block",
                    fontSize: "36px",
                    fontWeight: "600"
                  }}
                >
                  Reset Password
                </Typography>
                <br />
                <Typography
                  sx={{
                    display: "inline-block",
                    fontSize: "16px",
                    fontWeight: "400"
                  }}
                >
                  Please enter the new password
                </Typography>
              </Box>
              <form onSubmit={handleSubmit} autoComplete="off">
                <Box sx={{ mt: 2 }}>
                  <StyledLabel>New Password</StyledLabel>
                  <TextField
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    type={showNewPassword ? "text" : "password"}
                    sx={{ mt: 1, borderRadius: "8px" }}
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start" sx={{ m: 0 }}>
                          <Tooltip
                            title={
                              showNewPassword
                                ? "Hide Password"
                                : "Show Password"
                            }
                          >
                            <IconButton onClick={handleClickShowNewPassword}>
                              {showNewPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <StyledLabel>Confirm Password</StyledLabel>
                  <TextField
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    type={showConfirmPassword ? "text" : "password"}
                    sx={{ mt: 1, borderRadius: "8px" }}
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start" sx={{ m: 0 }}>
                          <Tooltip
                            title={
                              showConfirmPassword
                                ? "Hide Password"
                                : "Show Password"
                            }
                          >
                            <IconButton
                              onClick={handleClickShowConfirmPassword}
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>

                <StyledLogin sx={{ mt: 3 }} type="submit">
                  Reset Password
                </StyledLogin>
              </form>
            </Container>
          </StyledRightSide>
        </Grid>
      </Grid>
    </div>
  );
};
export default ResetPassword;
