import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Login from "./components/loginPage/login";
import Layout from "./components/Layout/Layout";
import ForgetPassword from "./components/forgetPassword/fogetPassword";
import ResetPassword from "./components/resetPassword/resetPassword";
import PrivateRoute from "./components/privateRoute/privateRoute";
import { loadUser } from "./state/actions/authActions";
// import { getCountries } from "./state/actions/buyerActions";

import "./App.css";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadUser());
    // dispatch(getCountries())
  }, []);

  return (
    <div>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/forgetpassword" element={<ForgetPassword />} />
        <Route exact path="/resetpassword" element={<ResetPassword />} />
        <Route
          path="*"
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        ></Route>
      </Routes>
    </div>
  );
}
export default App;
