import * as React from "react";
import Box from "@mui/material/Box";
import {
  Card,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SwipeableDrawer,
  Tab,
  TableContainer,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Grid, Button, Stack, Typography, IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import { Table, TableHead, TableBody, TableRow, Chip } from "@mui/material";
import "./orderDetails.css";
import {
  getOrderDetails,
  GetOrderStatusList,
  GetOrderSuppliersList,
  supplierOrderCreates,
  supplierQuatation,
  supplierStatusChange,
} from "../../state/actions/orderAction";
import moment from "moment";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import { useForm } from "../../utils/useForm";
import { useSelector } from "react-redux";
import { yellow } from "@material-ui/core/colors";
import { useSnackbar } from "notistack";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "14px",
    paddingTop: "10px",
    paddingBottom: "10px",
    position: "sticky",
    fontWeight: "bold",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#0957dd0a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledLabel = styled("label")(() => ({
  display: "inline-block",
  fontWeight: "500",
  fontSize: "14px",
  mt: 1,
  borderRadius: "8px",
}));

const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const initialValue = {
  orderId: "",
  supplierId: "",
  productId: "",
  itemQty: "",
  totalAmount: "",
  status: "1",
};

export default function FormPropsTextFields() {
  const [statess, setStatess] = useState([initialValue]);
  const [orderProductData, setorderProductData] = useState([]);
  const [orderData, setorderData] = useState([]);
  const location = useLocation();
  const [shippingAddress, setshippingAddress] = useState({});
  const [value, setValue] = useState("1");
  const [supplierList, setSupplierList] = useState([]);
  const [supplierName, setSupplierName] = useState();
  const [isStatusEditing, setStatusEditing] = useState();
  const [statusMenu, setStatusMenu] = useState(true);
  const [city, setCity] = useState([]);
  const [states, setStates] = useState([]);
  const [country, setCountry] = useState([]);
  const [orderStatusList, setOrderStatusList] = useState([]);
  const [quotationId, setQuotationId] = useState(0);

  const datas = supplierList?.map((x) => x.id);

  const { enqueueSnackbar } = useSnackbar();

  const openStatusMenu = () => {
    setStatusMenu(true);
  };
  const closeStatusMenu = () => {
    setStatusMenu(false);
  };

  const startStatusEditing = (id, e) => {
    setStatusEditing(id);
    setQuotationId(id);
  };

  const startStatusUpdate = (e, index) => {
    let newArr = [...orderProductData]; // copying the old datas array
    newArr[index][e.target.name] = e.target.value;
    setorderProductData(newArr);
    // setorderProductData({ ...orderProductData, [e.target.name]: e.target.value });
  };

  const stopStatusEditing = (id) => {
    setStatusEditing(id);
  };

  const handleChanges = (e, index) => {
    // setSupplierName({ ...supplierName, [e.target.id]: e.target.value });
    let newArr = [...supplierName]; // copying the old datas array
    newArr[index][e.target.name] = e.target.value;
    setSupplierName(newArr);
  };

  const getAllSupplierList = async (quotationId) => {
    const res = await GetOrderSuppliersList();
    if (res && res.status) {
      setSupplierList(res.data);
    }
  };

  useEffect(() => {
    getAllSupplierList();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const { state } = useLocation();

  const id = state;

  const OrderDetails = async (id) => {
    const state = location.state;
    const res = await getOrderDetails(id);

    if (res && res.status === true) {
      setorderProductData(res.productDetail);
      setorderData(res.data);
      // setcustomerData(res.data.customer);
      setshippingAddress(res.data.shippingAddressId);
      setCity(res.data.shippingAddressId?.city);
      setStates(res.data.shippingAddressId?.state);
      setCountry(res.data.shippingAddressId?.country);

      // setbillingAddress(res.data.billingAddress);
    }
  };

  useEffect(() => {
    OrderDetails(id);
  }, [id]);

  const productStatus = (productstatus) => {
    const statusColors = {
      0: {
        key: "Closed",
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#f05252;",
      },
      1: {
        key: "Paid",
        backgroundColor: "#ede7dd",
        color: "#0e9f6e",
      },
      2: {
        key: "Pending",
        backgroundColor: "#fdf6b2",
        color: "#c27803",
      },
      3: {
        key: "Partial",
        backgroundColor: "#def7ec",
        color: "gray",
      },
    };

    return (
      <StyledChip
        style={{
          height: "31px",
          fontSize: "17px",
          justifyContent: "center",
          display: "flex",
          width: "69%",
        }}
        sx={statusColors[productstatus]}
        label={
          statusColors[productstatus] ? statusColors[productstatus].key : ""
        }
      />
    );
  };

  const orderStatus = (orderstatus) => {
    const statusColors = {
      1: {
        key: "Pending",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      2: {
        key: "InProgress",
        backgroundColor: "#fdf6b2",
        color: "#c27803",
      },
      3: {
        key: " Ready to dispatch ",
        backgroundColor: "#def7ec",
        color: "#0e9f6e",
      },
      4: {
        key: "Shipped ",
        backgroundColor: "#def7ec",
        color: "blue",
      },
      5: {
        key: "Delivered",
        backgroundColor: "#def7ec",
        color: "green",
      },
      6: {
        key: "Cancel",
        backgroundColor: "#def7ec",
        color: "black",
      },
      7: {
        key: "Failed",
        backgroundColor: "#def7ec",
        color: "red",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[orderstatus]}
        label={statusColors[orderstatus] ? statusColors[orderstatus].key : ""}
      />
    );
  };

  const user = useSelector((state) => state?.auth?.user);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(true);

  const onSubmit = async () => {
    let data = orderProductData?.filter((x) => {
      if (x.id === quotationId) {
        return x;
      }
    });

    if (data.length > 0) {
      data = data[0];
      const body = {
        orderId: id,
        supplierId: datas[0],
        productId: data?.id,
        itemQty: data?.quantity,
        totalAmount: data?.sellPrice,
        status: 1,
        type: 2,
        supplierPrice: data?.supplierPrice,
      };

      const res = await supplierOrderCreates(body);
      if (res) {
        const body2 = {
          orderId: id,
          supplierId: datas[0],
          productId: data?.id,
          itemQty: data?.quantity,
          totalAmount: data?.sellPrice,
          status: 1,
          type: 2,
          supplierPrice: data?.supplierPrice,
          supplierOrderId: res.data.id,
        };
        const ress = await supplierQuatation(body2);

        if (ress && ress.status === true) {
          enqueueSnackbar(ress.message, { variant: "success" });
        }
        if (ress && ress.status === false) {
          enqueueSnackbar(
            ress.message || "Unexpected error occured please try again later.",
            { variant: "error" }
          );
        }
      }
    }
  };

  const getorderStatus = async () => {
    const res = await GetOrderStatusList();
    if (res && res.status) {
      setOrderStatusList(res.data);
    }
  };

  useEffect(() => {
    getorderStatus();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h1
            style={{
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "26px",
              marginBottom: "8px",
            }}
          >
            {" "}
          </h1>
        </div>
        <Box
          borderRadius="12px"
          style={{
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#60579A",
              borderRadius: "10px",
            }}
            onClick={() => {
              navigate(`/orders`);
            }}
          >
            <strong
              style={{ color: "white", fontWeight: "500", fontSize: "13px" }}
            >
              Back
            </strong>
          </Button>
        </Box>
      </div>
      <Card
        style={{
          padding: "20px",
          borderRadius: "12px",
          marginTop: "20px",
        }}
      >
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Order" value="1" />
                <Tab label="Products" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Grid
                container
                spacing={2}
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{ marginTop: "10px" }}
              >
                <Grid xs={2} sm={4} md={8}>
                  <Typography variant="h2" id="purchase-order-details-number">
                    Order Details
                  </Typography>
                  <Typography sx={{ marginTop: "10px" }}>
                    Order No. {orderData.orderNumber}
                  </Typography>

                  <Typography
                    sx={{ marginTop: "18px", color: "rebeccapurple" }}
                  >
                    Customer Details
                  </Typography>

                  <Typography sx={{ fontWeight: "bold" }}>
                    {orderData?.userId?.fname} {orderData?.userId?.lname}
                  </Typography>
                  <Typography>
                    {" "}
                    {orderData?.userId?.mobile} {orderData?.userId?.email}
                  </Typography>
                  <Typography>
                    {shippingAddress?.address} {city?.cityName}{" "}
                    {states?.stateName}, {country?.name}{" "}
                    {shippingAddress?.zipcode}
                  </Typography>
                </Grid>
                <Grid xs={2} sm={4} md={2}>
                  <Typography sx={{ fontSize: "14px", marginLeft: "6px" }}>
                    Order Date
                  </Typography>
                  <Typography
                    variant="h6"
                    id="purchase-order-details-status-value2"
                    sx={{ fontSize: "20px" }}
                  >
                    {moment(orderData.createdAt).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>

                <Grid xs={2} sm={4} md={2}>
                  <Typography sx={{ fontSize: "14px", marginLeft: "6px" }}>
                    Order Status
                  </Typography>

                  <Typography sx={{ marginTop: "3px" }}>
                    {orderStatus(orderData?.status)}
                  </Typography>
                </Grid>
              </Grid>

              <FlexBox id="purchase-order-details-footer">
                <Box display="flex">
                  <Box>
                    <Typography sx={{ mb: 2, fontWeight: "bold" }}>
                      Total
                      <span style={{ color: "rebeccapurple" }}>
                        :$ {orderData.amount}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </FlexBox>
            </TabPanel>

            <TabPanel value="2">
              <Typography>Product List</Typography>
              <TableContainer
                component={Paper}
                sx={{
                  minHeight: "300px",
                  marginTop: "10px",
                  boxShadow: "none",
                }}
              >
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <StyledTableCell
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                      }}
                    >
                      Product Name
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                      }}
                    >
                      Quantity
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                      }}
                    >
                      Price
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                      }}
                    >
                      Supplier Price
                    </StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableHead>
                  <TableBody>
                    {orderProductData.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell>
                          <Stack flexDirection="row" alignItems="center">
                            <Avatar
                              component="span"
                              variant="rounded"
                              alt="profile_image"
                              src={row.images[0].imageUrl}
                            />

                            <Typography sx={{ ml: 2, fontSize: "12px" }}>
                              {row.name}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell>{row.quantity}</TableCell>
                        <TableCell> $ {row.sellPrice}</TableCell>

                        {isStatusEditing === row.id ? (
                          <Box sx={{ marginTop: "30px" }}>
                            <TextField
                              // type="number"
                              size="small"
                              variant="outlined"
                              fullWidth
                              id="status"
                              name="supplierPrice"
                              value={row?.supplierPrice}
                              open={statusMenu}
                              onOpen={openStatusMenu}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  stopStatusEditing();
                                  closeStatusMenu();
                                }
                              }}
                              onChange={(event) =>
                                startStatusUpdate(event, index)
                              }
                              sx={{ width: "200px" }}
                              onBlur={() => stopStatusEditing()}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    sx={{ color: "black" }}
                                  >
                                    <EditIcon />
                                  </InputAdornment>
                                ),
                              }}
                            ></TextField>
                          </Box>
                        ) : (
                          <IconButton
                            onClick={() => {
                              startStatusEditing(row.id);
                              openStatusMenu();
                            }}
                            size="large"
                            style={{ color: "black", margin: "17px" }}
                          >
                            <TableCell>
                              {row.supplierPrice ? row.supplierPrice : 0.0}
                            </TableCell>
                            <EditIcon />
                          </IconButton>
                        )}

                        <TableCell>
                          <select
                            id={row.id}
                            name="supplierName"
                            value={supplierName}
                            onChange={(event) => handleChanges(event, index)}
                            style={{
                              width: "100%",
                              height: "45px",
                              border: "1px solid #ccc",
                              padding: "5px",
                              marginTop: "10px",
                              fontSize: "16px",
                              backgroundColor: "#fff",
                            }}
                          >
                            <option
                              value={row.id}
                              sx={{
                                width: "200px",
                                padding: "20px",
                                height: "150px",
                              }}
                            >
                              Select Supplier
                            </option>
                            {supplierList.map((supplier) => (
                              <option
                                value={supplier.id}
                                key={supplier?.key}
                                sx={{
                                  width: "200px",
                                  padding: "20px",
                                  height: "150px",
                                }}
                              >
                                {supplier.name}
                              </option>
                            ))}
                          </select>
                        </TableCell>

                        <TableCell>
                          <IconButton
                            variant="contained"
                            onClick={onSubmit}
                            sx={{
                              color: "black",
                            }}
                          >
                            <SendIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}{" "}
                    {orderProductData?.length === 0 ? (
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "silver",
                            textAlign: "center",
                            paddingTop: "90px",
                            borderBottom: "none",
                            fontSize: "30px",
                          }}
                          colSpan="7"
                        >
                          No records to display
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          </TabContext>
        </Box>
      </Card>
    </>
  );
}
