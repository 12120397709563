import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Stack,
  Grid,
  Paper,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import camera from "../../../assets/camera.svg";
import { TextField, FormLabel, MenuItem, Input } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "rgba(15, 15, 15, 0.5)",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiInput-root": {
    paddingBottom: "20px",
  },
}));

const RejectRequest = (props) => {
  const { user, open, handleClose } = props;
  console.log("user delete", user);
  return (
    <div>
      <Dialog
        id="rejectRequest"
        sx={{
          "&#rejectRequest .MuiPaper-root": {
            width: "500px",
          },
        }}
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Reject Request
        </DialogTitle>

        <DialogContent>
          <div>Are you sure you want to reject this customer ?</div>

          <Typography sx={{ mt: 3, fontWeight: "bold", mb: 1 }}>
            Reject Reason
          </Typography>
          <TextField
            multiline
            fullWidth
            minRows={4}
            maxRows={4}
            placeholder="Enter a description..."
          />
        </DialogContent>
        <DialogActions sx={{ margin: "15px", marginLeft: "auto" }}>
          <Button
            sx={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid #0F0F0F80",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                // backgroundColor: "#60579A",
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: "#60579A",
              color: "white",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                backgroundColor: "#60579A",
              },
            }}
            onClick={handleClose}
          >
            Reject
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RejectRequest;
