import { useState } from "react";

import { Grid, Button, Stack, Typography, IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
import { Box, Tabs, Tab, Badge } from "@mui/material";
import { styled } from "@mui/material/styles";

import BuyersList from "./BuyersList/BuyerList";
import BuyersRequest from "./BuyerRequest/BuyerRequest";

import AddUserModal from "./AddBuyer";

const StyledTab = styled(Box)(() => ({
  marginTop: "32px",
  width: "100%",
  "div > div > div > .MuiButtonBase-root": {
    color: "black",
    fontWeight: "600",
  },
  "div > div > div > .Mui-selected": {
    color: "black !important",
    backgroundColor: "white",
    borderRadius: " 10px 10px 0px 0px",
  },
  "div > div > div > .MuiTabs-indicator": {
    display: "none",
  },
  "div > div > div > .MuiTabs-flexContainer": {
    height: "0px",
  },
}));
const Buyers = () => {
  const [refresh, setRefresh] = useState(false);
  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const TabPanel = ({ value, index, children }) => {
    if (value === index) {
      return (
        <Box
          sx={{
            mt: 2,
          }}
        >
          {children}
        </Box>
      );
    }
  };

  const [addUser, setAddUser] = useState(false);
  const openUserModal = () => {
    setAddUser(true);
  };
  const closeUserModal = () => {
    setAddUser(false);
  };
  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "32px",
            color: "#0F0F0F",
          }}
        >
          Customer
        </Typography>
        <Button
          sx={{
            backgroundColor: "#60579A",
            color: "white",
            borderRadius: "10px",
            padding: "5px 27px",
            "&:hover": {
              backgroundColor: "#60579A",
            },
            height: "3em",
          }}
          onClick={openUserModal}
          startIcon={<Add />}
          color="primary"
        >
          Add Customer
        </Button>
      </Stack>
      <AddUserModal
        open={addUser}
        setOpen={setAddUser}
        handleClickOpen={openUserModal}
        handleClose={closeUserModal}
        refreshScreen={refreshScreen}
      />
      <StyledTab>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              fontFamily: "Work Sans",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            <Tab label="All Customer" />
            <Tab
              label={
                <Box>
                  New Request
                  {/* <Typography
                    component="span"
                    sx={{
                      ml: 1,
                      backgroundColor: "#60579A",
                      fontSize: "12px",
                      color: "white",
                      padding: "3px 8px",
                      borderRadius: "10px",
                    }}
                  >
                    8
                  </Typography> */}
                </Box>
              }
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <BuyersList refresh={refresh} refreshScreen={refreshScreen} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <BuyersRequest refresh={refresh} refreshScreen={refreshScreen} />
        </TabPanel>
      </StyledTab>
    </>
  );
};
export default Buyers;
