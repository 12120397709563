import * as React from "react";
import Box from "@mui/material/Box";
import {
  Card,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SwipeableDrawer,
  Tab,
  TableContainer,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Grid, Button, Stack, Typography, IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import { Table, TableHead, TableBody, TableRow, Chip } from "@mui/material";
import "./orderDetails.css";
import {
  getOrderDetails,
  GetOrderStatusList,
  GetOrderSuppliersList,
  getSupplierOrderDetails,
  supplierQuatation,
  supplierQuotationsAccepted,
  supplierStatusChange,
} from "../../state/actions/orderAction";
import moment from "moment";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import { useForm } from "../../utils/useForm";
import { useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import { useSnackbar } from "notistack";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "14px",
    paddingTop: "10px",
    paddingBottom: "10px",
    position: "sticky",
    fontWeight: "bold",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#0957dd0a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledLabel = styled("label")(() => ({
  display: "inline-block",
  fontWeight: "500",
  fontSize: "14px",
  mt: 1,
  borderRadius: "8px",
}));

const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const initialValues = {
  name: "",
};

export default function FormPropsTextFields() {
  const [orderProductData, setorderProductData] = useState([]);
  console.log(
    "🚀 ~ file: SupplierOrderDetails.jsx:95 ~ FormPropsTextFields ~ orderProductData:",
    orderProductData
  );

  const [orderData, setorderData] = useState([]);
  const location = useLocation();
  const [supplshippingAddress, setshippingAddress] = useState({});

  const [quotationId, setQuotationId] = useState(0);
  const [value, setValue] = useState("1");

  const [supplierList, setSupplierList] = useState([]);
  const [supplierName, setSupplierName] = useState();

  const [statusEditing, setStatusEditing] = useState(0);
  const [statusMenu, setStatusMenu] = useState(true);
  const [orderStatusList, setOrderStatusList] = useState([]);
  const [hideButton, setHideButton] = useState(false);
  const [city, setCity] = useState([]);

  const [states, setStates] = useState([]);

  const [country, setCountry] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const openStatusMenu = () => {
    setStatusMenu(true);
  };
  const closeStatusMenu = () => {
    setStatusMenu(false);
  };

  const startStatusEditing1 = (id) => {
    setStatusEditing(id);
    setQuotationId(id);
  };

  const startStatusUpdate = (e, index, i) => {
    let newArr = [...orderData];
    newArr[index][i][e.target.name] = e.target.value;
    setorderData(newArr);
    // setorderProductData({ ...orderProductData, [e.target.name]: e.target.value });
  };

  const approveQuotation = async (quotationid, status) => {
    const body = {
      status: status,
    };
    const res = await supplierQuotationsAccepted(quotationid, body);
    setHideButton(true);
    if (res && res.status === true) {
      enqueueSnackbar(res.message, { variant: "success" });
    }
    if (res && res.status === false) {
      enqueueSnackbar(
        res.message || "Unexpected error occured please try again later.",
        { variant: "error" }
      );
    }
  };

  const stopStatusEditing = () => {
    setStatusEditing();
  };

  const handleChanges = (e, index) => {
    // setSupplierName({ ...supplierName, [e.target.id]: e.target.value });
    let newArr = [...supplierName]; // copying the old datas array
    newArr[index][e.target.name] = e.target.value;
    setSupplierName(newArr);
  };

  const getAllSupplierList = async () => {
    const res = await GetOrderSuppliersList();
    if (res && res.status) {
      setSupplierList(res.data);
    }
  };

  useEffect(() => {
    getAllSupplierList();
  }, [statusEditing]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const { state } = useLocation();
  const id = state;

  const OrderDetails = async (id) => {
    const res = await getSupplierOrderDetails(id);

    if (res && res.status === true) {
      setorderProductData(res.orderData);
      setorderData(res.data);
      // setcustomerData(res.data.customer);
      setshippingAddress(res.data.shippingAddressId);
      // setbillingAddress(res.data.billingAddress);
      setCity(res.orderData.shippingAddressId?.city);
      setStates(res.orderData.shippingAddressId?.state);
      setCountry(res.orderData.shippingAddressId?.country);
    }
  };

  useEffect(() => {
    OrderDetails(id);
  }, [id]);

  const productStatus = (productstatus) => {
    const statusColors = {
      1: {
        key: "Request for quote",
        backgroundColor: "#ede7dd",
        color: "#0e9f6e",
      },

      2: {
        key: "Quote Provided",
        backgroundColor: "#fdf6b2",
        color: "#c27803",
      },

      3: {
        key: "Assigned Order",
        backgroundColor: "#def7ec",
        color: "gray",
      },
      4: {
        key: "Order Accepted",
        backgroundColor: "#def7ec",
        color: "gray",
      },
      5: { key: "Rejected", backgroundColor: "#d1d1d1", color: "#f00e0e" },
    };

    return (
      <StyledChip
        style={{
          height: "32px",
          fontSize: "13px",
        }}
        sx={statusColors[productstatus]}
        label={
          statusColors[productstatus] ? statusColors[productstatus].key : ""
        }
      />
    );
  };

  const orderStatus = (orderstatus) => {
    const statusColors = {
      1: {
        key: "Pending",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      2: {
        key: "InProgress",
        backgroundColor: "#fdf6b2",
        color: "#c27803",
      },
      3: {
        key: " Ready to dispatch ",
        backgroundColor: "#def7ec",
        color: "#0e9f6e",
      },
      4: {
        key: "Shipped ",
        backgroundColor: "#def7ec",
        color: "blue",
      },
      5: {
        key: "Delivered",
        backgroundColor: "#def7ec",
        color: "green",
      },
      6: {
        key: "Cancel",
        backgroundColor: "#def7ec",
        color: "black",
      },
      7: {
        key: "Failed",
        backgroundColor: "#def7ec",
        color: "red",
      },
    };

    return (
      <StyledChip
        style={{
          height: "32px",
          fontSize: "17px",
        }}
        sx={statusColors[orderstatus]}
        label={statusColors[orderstatus] ? statusColors[orderstatus].key : ""}
      />
    );
  };

  const orderstatus = orderData?.map((x) => x.status);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true);

  let statusValue = localStorage.getItem("status");

  const onSubmit = async (datas) => {
    const body = {
      orderId: datas?.orderId,
      supplierId: orderProductData?.userId,
      productId: datas.itemId,
      itemQty: datas.itemQty,
      supplierPrice: datas.supplierPrice,
      totalAmount: datas.itemPrice,
      status: 2,
      type: 1,
      supplierOrderId: datas.supplierOrderId,
    };

    const res = await supplierQuatation(body);
    if (res && res.status === true) {
      enqueueSnackbar(res.message, { variant: "success" });
    }
    if (res && res.status === false) {
      enqueueSnackbar(
        res.message || "Unexpected error occured please try again later.",
        { variant: "error" }
      );
    }
  };

  const getorderStatus = async () => {
    const res = await GetOrderStatusList();
    if (res && res.status) {
      setOrderStatusList(res.data);
    }
  };

  useEffect(() => {
    getorderStatus();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h1
            style={{
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "26px",
              marginBottom: "8px",
            }}
          >
            {" "}
          </h1>
        </div>
        <Box
          borderRadius="12px"
          style={{
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#60579A",
              borderRadius: "10px",
            }}
            onClick={() => {
              navigate(`/orders`);
            }}
          >
            <strong
              style={{ color: "white", fontWeight: "500", fontSize: "13px" }}
            >
              Back
            </strong>
          </Button>
        </Box>
      </div>
      <Card
        style={{
          padding: "20px",
          borderRadius: "12px",
          marginTop: "20px",
        }}
      >
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Order" value="1" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Grid
                container
                spacing={2}
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{ marginTop: "25px", marginLeft: "10px" }}
              >
                <Grid xs={2} sm={4} md={6}>
                  <Typography variant="h2" id="purchase-order-details-number">
                    Order Details
                  </Typography>
                  <Typography sx={{ marginTop: "10px" }}>
                    Order No. {orderProductData.orderNumber}
                  </Typography>

                  <Typography
                    sx={{
                      color: "rebeccapurple",

                      marginTop: "20px",
                      fontSize: "20px",
                    }}
                  >
                    Shipping Address
                  </Typography>
                  <Typography>
                    {supplshippingAddress?.address} {city?.cityName}{" "}
                    {states?.stateName}, {country?.name}{" "}
                    {supplshippingAddress?.zipcode}
                  </Typography>
                </Grid>
                <Grid xs={2} sm={4} md={2}>
                  <Typography sx={{ fontSize: "14px", marginLeft: "6px" }}>
                    Order Date
                  </Typography>
                  <Typography
                    variant="h6"
                    id="purchase-order-details-status-value2"
                    sx={{ fontSize: "20px" }}
                  >
                    {moment(orderData.createdAt).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item sx={{ paddingLeft: "0px" }}>
                  {orderData.map((row, i) => {
                    return (
                      <TableContainer
                        component={Paper}
                        sx={{
                          minHeight: "300px",
                          marginTop: "50px",
                          boxShadow: "none",
                          width: "108%",
                          overflowX: "auto",
                        }}
                      >
                        <Table stickyHeader aria-label="simple table">
                          <TableHead>
                            <StyledTableCell>Product Name</StyledTableCell>
                            <StyledTableCell>Quantity</StyledTableCell>
                            <StyledTableCell>Date</StyledTableCell>
                            <StyledTableCell>Supplier Price</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                          </TableHead>
                          <TableBody>
                            {row.map((data, index) =>
                              row.length - 1 === index ? (
                                <TableRow
                                  key={index}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <TableCell>
                                    <Stack
                                      flexDirection="row"
                                      alignItems="center"
                                    >
                                      <Avatar
                                        component="span"
                                        variant="rounded"
                                        alt="profile_image"
                                        src={data?.productImage[0]?.imageUrl}
                                      />
                                      <Typography
                                        sx={{
                                          fontFamily: "Work Sans",
                                          fontStyle: "normal",
                                          fontWeight: 400,
                                          fontSize: "14px",
                                          lineHeight: "18px",
                                          ml: 2,
                                        }}
                                      >
                                        {data.productName}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell>{data.itemQty}</TableCell>
                                  <TableCell
                                    sx={{
                                      fontFamily: "Work Sans",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      lineHeight: "18px",
                                    }}
                                  >
                                    {moment(data.createdAt).format(
                                      "MMM DD, YYYY, h:mm:ss a"
                                    )}
                                  </TableCell>
                                  {statusEditing === data.id &&
                                  data.status != "4" &&
                                  data.status != 5 ? (
                                    <Box sx={{ marginTop: "15px" }}>
                                      <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        name="supplierPrice"
                                        value={data?.supplierPrice}
                                        open={statusMenu}
                                        onOpen={openStatusMenu}
                                        onKeyPress={(e) => {
                                          if (e.key === "Enter") {
                                            stopStatusEditing();
                                            closeStatusMenu();
                                          }
                                        }}
                                        onChange={(event) =>
                                          startStatusUpdate(event, i, index)
                                        }
                                        onBlur={() => stopStatusEditing()}
                                        sx={{ width: "100px" }}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment
                                              position="end"
                                              sx={{
                                                color: " rgba(0, 0, 0, 0.87)",
                                              }}
                                            >
                                              <EditIcon />
                                            </InputAdornment>
                                          ),
                                        }}
                                      >
                                        {data?.supplierPrice}
                                      </TextField>
                                    </Box>
                                  ) : (
                                    <Button
                                      onClick={() => {
                                        startStatusEditing1(data.id);
                                        openStatusMenu();
                                      }}
                                      size="large"
                                      style={{
                                        color: " rgba(0, 0, 0, 0.87)",
                                        margin: "17px",
                                      }}
                                      endIcon={<EditIcon />}
                                    >
                                      {data?.supplierPrice
                                        ? data?.supplierPrice
                                        : 0.0}
                                    </Button>
                                  )}
                                  <TableCell>
                                    {productStatus(data?.status)}
                                  </TableCell>
                                  <TableCell>
                                    {data.status != "4" && data.status != 5 ? (
                                      <Button
                                        variant="contained"
                                        onClick={() => onSubmit(data)}
                                        sx={{
                                          fontFamily: "Work Sans",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          lineHeight: "20px",
                                          background: "#60579A",

                                          boxShadow:
                                            "0px 1px 2px rgba(16, 24, 40, 0.05)",
                                          borderRadius: "10px",
                                          width: "0px",
                                          marginTop: "0px",
                                        }}
                                      >
                                        Send
                                      </Button>
                                    ) : null}
                                    {!hideButton &&
                                    data?.status != 4 &&
                                    data.status != 5 ? (
                                      <Button
                                        variant="contained"
                                        sx={{
                                          fontFamily: "Work Sans",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          lineHeight: "20px",
                                          background: "#60579A",
                                          boxShadow:
                                            "0px 1px 2px rgba(16, 24, 40, 0.05)",
                                          borderRadius: "10px",
                                          width: "26%",
                                          marginLeft: "10px",
                                          marginTop: "0px",
                                        }}
                                        onClick={() =>
                                          approveQuotation(data.id, 4)
                                        }
                                      >
                                        Accepted
                                      </Button>
                                    ) : null}
                                    {!hideButton &&
                                    data?.status != 4 &&
                                    data.status != 5 ? (
                                      <Button
                                        variant="contained"
                                        sx={{
                                          fontFamily: "Work Sans",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          lineHeight: "20px",
                                          background: "#60579A",
                                          boxShadow:
                                            "0px 1px 2px rgba(16, 24, 40, 0.05)",
                                          borderRadius: "10px",
                                          width: "25%",
                                          marginLeft: "10px",
                                          marginTop: "0px",
                                        }}
                                        onClick={() =>
                                          approveQuotation(data.id, 5)
                                        }
                                      >
                                        Rejected
                                      </Button>
                                    ) : null}
                                  </TableCell>
                                </TableRow>
                              ) : (
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <TableCell>
                                    <Stack
                                      flexDirection="row"
                                      alignItems="center"
                                    >
                                      <Avatar
                                        component="span"
                                        variant="rounded"
                                        alt="profile_image"
                                        src={data?.productImage[0]?.imageUrl}
                                      />
                                      <Typography
                                        sx={{
                                          fontFamily: "Work Sans",
                                          fontStyle: "normal",
                                          fontWeight: 400,
                                          fontSize: "14px",
                                          lineHeight: "18px",
                                          ml: 2,
                                        }}
                                      >
                                        {data.productName}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell>{data.itemQty}</TableCell>
                                  <TableCell
                                    sx={{
                                      fontFamily: "Work Sans",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      lineHeight: "18px",
                                    }}
                                  >
                                    {moment(data.createdAt).format(
                                      "MMM DD, YYYY, h:mm:ss a"
                                    )}
                                  </TableCell>

                                  <TableCell>{data.supplierPrice}</TableCell>

                                  <TableCell sx={{ width: "auto" }}>
                                    {productStatus(data?.status)}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    );
                  })}
                </Grid>
              </Grid>
            </TabPanel>
          </TabContext>
        </Box>
      </Card>
    </>
  );
}
