import { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { Box } from "@mui/material";
import SubCategoryList from "./SubCategoryList";
import AddSubCategoryModal from "./SubCategoryAdd";
import "./SubCategory.css";

const SubCategory = () => {
  const [refresh, setRefresh] = useState(false);
  const refreshScreen = () => {
    setRefresh(!refresh);
  };
  const [addSubCategory, setAddSubCategory] = useState(false);
  const openSubCategoryModel = () => {
    setAddSubCategory(true);
  };
  const closeCategoryModel = () => {
    setAddSubCategory(false);
  };
  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "32px",
            color: "#0F0F0F",
          }}
        >
          Sub Category
        </Typography>
        <Button
          className="button"
          onClick={openSubCategoryModel}
          startIcon={<Add />}
          color="primary"
        >
          Sub Category
        </Button>
      </Stack>
      <AddSubCategoryModal
        open={addSubCategory}
        setOpen={setAddSubCategory}
        handleClickOpen={openSubCategoryModel}
        handleClose={closeCategoryModel}
        refreshScreen={refreshScreen}
      />
      <Box sx={{ mt: 4 }}>
        <SubCategoryList refresh={refresh} refreshScreen={refreshScreen} />
      </Box>
    </>
  );
};
export default SubCategory;
