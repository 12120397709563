import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  IconButton,
  Box,
  TextField,
  FormLabel,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useForm } from "../../utils/useForm";
import { Close } from "@mui/icons-material";
import {
  AddCategory,
  UpdateCategory,
} from "../../state/actions/categoryAction";
import { useSnackbar } from "notistack";
import BackdropComponent from "../backdrop/backdrop";
import camera from "../../assets/camera.svg";

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontFamily: "Work Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "30px",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "50px",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "30px",
  },
  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "14px",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "30px",
  },
}));

const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});

const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "220px",
  borderRadius: "10px",
  textAlign: "center",
}));
const StyledInput = styled("input")({
  display: "none",
});

const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

const CategoryAdd = (props) => {
  const { open, refreshScreen, handleClose, category } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [backdropState, setBackdropState] = useState(false);
  const [image, setImage] = useState("");
  const stopBackdrop = () => {
    setBackdropState(false);
  };
  const startBackdrop = () => {
    setBackdropState(true);
  };

  const initialValues = {
    name: "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [checkCategory, setCheckCategory] = useState(false);

  const openConfirmDialog = () => {
    setConfirmDialog(true);
  };
  const closeConfirmDialog = () => {
    setConfirmDialog(false);
    // resetForm();
  };
  const reset = () => {
    resetImage();
    resetForm();
  };
  const close = () => {
    reset();
    handleClose();
    // resetForm();
  };

  const resetImage = () => {
    setImage("");
  };
  const removeImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["image"];
      return newValues;
    });
  };

  async function handleImageItem(e) {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      const base64String = await getBase64(selected);
      setImage(base64String);
      setValues({ ...values, image: selected });
    } else {
    }
  }
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  useEffect(() => {
    if (category) {
      setValues({ name: category.name });
      setImage(category.image);
      setCheckCategory(true);
    }
  }, [props]);

  const addNewRole = async (e) => {
    e.preventDefault();
    if (validate()) {
      startBackdrop();

      if (checkCategory) {
        const formData = new FormData();
        formData.append("name", values.name);
        if (values.image) {
          formData.append("categoryImage", values.image);
        }

        startBackdrop();
        const res = await UpdateCategory(formData, category.id);
        if (res && res.status === true) {
          close();
          refreshScreen();
          enqueueSnackbar(res.message, { variant: "success" });
        }
        if (res && res.status === false) {
          enqueueSnackbar(res.message, { variant: "error" });
        }
        stopBackdrop();
      } else {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("categoryImage", values.image);
        const res = await AddCategory(formData);
        if (res && res.status === true) {
          close();
          refreshScreen();
          enqueueSnackbar(res.message, { variant: "success" });
        }
        if (res && res.status === false) {
          enqueueSnackbar(res.message, { variant: "error" });
        }
      }
      stopBackdrop();
    }
  };

  return (
    <div>
      <BackdropComponent open={backdropState} />
      {/* add new user modal */}
      <Dialog
        open={open}
        onClose={openConfirmDialog}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {checkCategory ? "Edit Category" : "Add New Category"}
          <Tooltip title="Close">
            <IconButton onClick={openConfirmDialog}>
              <Close />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <form onSubmit={addNewRole} id="addNewRole">
            <Grid item md={12} sm={12} xs={12} container spacing={1}>
              <Grid item sx={{ m: 1 }} xs={12}>
                <StyledFormLabel htmlFor="name">
                  Name
                  {Boolean(errors.name) && <StyledRequired> * </StyledRequired>}
                </StyledFormLabel>
                <StyledTextField
                  fullWidth
                  autoFocus={true}
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                  error={Boolean(errors.name)}
                  helperText={errors.name}
                  inputProps={{ maxLength: 80 }}
                />
              </Grid>

              <Grid item={true} sx={{ m: 1 }} md={12} xs={12}>
                <StyledImageUploadWrapper>
                  <label htmlFor="image-upload">
                    <StyledInput
                      accept="image/*"
                      id="image-upload"
                      type="file"
                      onChange={handleImageItem}
                      onClick={(event) => {
                        event.currentTarget.value = null;
                      }}
                    />
                    <StyledIconWrapper
                      sx={
                        image == ""
                          ? {}
                          : {
                              backgroundImage: `url(${image})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                            }
                      }
                    >
                      {image === "" && <img src={camera} alt="Camera" />}
                    </StyledIconWrapper>
                  </label>
                  <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                    {"Add Photo"}
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(15, 15, 15, 0.5)",
                      mx: 2,
                      fontSize: "12px",
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, max size of 3.1 MB
                  </Typography>
                  {image != "" && (
                    <StyledRemoveButton
                      onClick={(e) => {
                        e.preventDefault();
                        removeImage();
                      }}
                    >
                      Remove
                    </StyledRemoveButton>
                  )}
                </StyledImageUploadWrapper>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions sx={{ margin: "15px", marginLeft: "auto" }}>
          <Button className="cancel-button" onClick={close}>
            Cancel
          </Button>
          <Button className="button" form="addNewRole" type="submit">
            {checkCategory ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* confirmation dialog */}
      <Dialog
        id="confirm-dialog"
        open={confirmDialog}
        onClose={closeConfirmDialog}
        sx={{
          "&#confirm-dialog .MuiPaper-root": {
            borderRadius: "15px",
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "15px",
            lineHeight: "30px",
            color: "black",
          },
        }}
      >
        <DialogContent>
          Are you sure you want to Close this form ?
        </DialogContent>
        <DialogActions
          sx={{
            margin: "15px",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <Button className="cancel-button" onClick={closeConfirmDialog}>
            Cancel
          </Button>
          <Button
            className="button"
            onClick={() => {
              closeConfirmDialog();
              close();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CategoryAdd;
