import axiosRequest from "../axios/axios";

export const getCategory = async (queryString) => {
  try {
    const res = await axiosRequest.get(`/api/v1/categorylist${queryString}`);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

export const getOnlyCategoryList = async () => {
  try {
    const res = await axiosRequest.get(`/api/v1/categoryDropdown`);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};


export const AddCategory = async (data) => {
  try {
    const res = await axiosRequest.post(`/api/v1/addCategory`, data);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

export const deleteCategory = async (id) => {
  try {
    const res = await axiosRequest.delete(`/api/v1/deleteCategory/${id}`);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

export const UpdateCategory = async (data, id) => {
  try {
    const res = await axiosRequest.post(`/api/v1/updateCategory/${id}`, data);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

export const categortStatusChange = async (id) => {
  try {
    const res = await axiosRequest.post(`/api/v1/categoryStatus/${id}`);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

export const categoryDropdown = async () => {
  try {
    const res = await axiosRequest.get(`/api/v1/categoryDropdown`);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};
