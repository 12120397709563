import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Stack,
  Grid,
  Paper,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import Carousel from "react-material-ui-carousel";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Divider from "@mui/material/Divider";
import "./style/productDetails.css";
import rect from "../../assets/user1.png";

import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteProductModel from "./DeleteProduct";
import moment from "moment";
import "@fontsource/work-sans";
import "@fontsource/rubik";
import { useEffect } from "react";
import {
  getProductDetails,
  deleteProduct,
} from "../../state/actions/productAction";
import { useSnackbar } from "notistack";
import BackdropComponent from "../backdrop/backdrop";

const StyledParagraph = styled("p")({
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "34px",
  marginTop: "0px",
  fontFamily: "Work Sans",
});
const StyledHeading = styled("h1")({
  fontFamily: "Work Sans",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "34px",
  display: "flex",
  justifyContent: "space-between",
});
const StyledDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  background: "#FFFFFF",
  boxShadow:
    "  0px 20px 94px rgba(16, 24, 40, 0.01), 0px 8px 30px rgba(16, 24, 40, 0.01)",
  borderRadius: "12px !important",
  padding: "22px",
  width: "100%",
  height: "100%",
});
const StyledImg = styled("img")({
  borderRadius: "12px !important",
  height: "350px",
  width: "450px",
});

export default function ProductDetails() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [backdropState, setBackdropState] = useState(false);
  const stopBackdrop = () => {
    setBackdropState(false);
  };
  const startBackdrop = () => {
    setBackdropState(true);
  };

  // id represents proudct id
  const id = state;

  const [product, setProduct] = useState([]);
  const [specifications, setSpecifications] = useState([]);
  const [images, setImages] = useState([]);

  const getDetails = async (id) => {
    startBackdrop();
    const res = await getProductDetails(id);
    if (res && res.status) {
      setProduct(res?.data?.productData);
      setSpecifications(res?.data?.productSpecificationData);
      setImages(res?.data?.productImages);
    }
    stopBackdrop();
  };
  useEffect(() => {
    getDetails(id);
  }, [id]);

  //  for confirmation modal
  const [confirmationModal, setConfirmationModal] = useState(false);
  const handleConfirmationModal = () => {
    setConfirmationModal(true);
  };
  const closeConfirmationModal = () => {
    setConfirmationModal(false);
  };

  //  close confirmation modal when click on cancel button
  const close = () => {
    closeConfirmationModal();
  };
  //  this function will be executed when we click confirm button on confirmation modal while deleting
  //  prouduct
  const onSuccess = async () => {
    const productId = id;
    startBackdrop();
    const res = await deleteProduct(productId);

    if (res && res.status) {
      close();
      enqueueSnackbar(res.message, { variant: "success" });
      setTimeout(() => {
        navigate("/products");
      }, 2000);
    }
    if (res && res.status === false) {
      close();
      enqueueSnackbar(res.message, { variant: "error" });
    }
    stopBackdrop();
  };

  return (
    <>
      <BackdropComponent open={backdropState} />
      <DeleteProductModel
        open={confirmationModal}
        handleClose={close}
        handleSuccess={onSuccess}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid container item xs={12} className="pd_heading_grid">
            <Grid>
              <StyledHeading>Product Details</StyledHeading>
              <StyledParagraph>
                <span onClick={() => navigate("/products")}>Products | </span>
                <span style={{ color: "rgb(166 166 166)" }}>
                  {" "}
                  Product Details
                </span>
              </StyledParagraph>
            </Grid>
            <Grid>
              <Button
                type="button"
                variant="contained"
                onClick={handleConfirmationModal}
                sx={{
                  mb: 2,
                  px: 6,
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                  },
                }}
              >
                Delete
              </Button>

              <Link
                style={{
                  textDecoration: "none",
                }}
                to="/products/edit"
                state={{ id: product.id }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mb: 2,
                    px: 6,
                    ml: 2,
                    backgroundColor: "#60579A",
                    color: "white",
                    height: "48px",
                    borderRadius: "10px",
                    padding: "10px 25px",
                    "&:hover": {
                      backgroundColor: "#60579A",
                    },
                  }}
                >
                  Edit
                </Button>
              </Link>
            </Grid>
          </Grid>
          <StyledDiv>
            <Grid className="pd_slider_and_details_grid" container xs={12}>
              <Grid item xs={12} lg={6} spacing={2}>
                <Grid>
                  <Carousel
                    className="pd_img_slider"
                    fullHeightHover={false}
                    navButtonsProps={{
                      style: {
                        background: "rgba(15, 15, 15, 0.5)",
                        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                        transform: " matrix(-1, 0, 0, 1, 0, 0)",
                        borderRadius: "10px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "8px",
                        gap: "8px",
                        width: "36px",
                        height: "36px",
                      },
                    }}
                    indicatorIconButtonProps={{
                      style: {
                        display: "none",
                      },
                    }}
                    NextIcon={<ChevronLeftIcon />}
                    PrevIcon={<ChevronRightIcon />}
                    animation="slide"
                    duration={700}
                    autoPlay={true}
                    navButtonsAlwaysVisible={true}
                  >
                    {images.map((value, i) => (
                      <StyledImg key={i} src={value.imageUrl} alt={value.id} />
                    ))}
                  </Carousel>
                </Grid>

                <Grid
                  container
                  item
                  sx={{ my: 1.5 }}
                  className="pd_parentspecialborderGrid"
                  style={{ width: "450px" }}
                >
                  {images.map((item, i) => {
                    return (
                      <Grid item>
                        <img
                          className="pd_product_img"
                          key={i}
                          src={item.imageUrl}
                          alt={item.id}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              {/* details and description */}
              <Grid item xs={12} lg={6}>
                <Grid className="pd_details_desc_grid">
                  <Grid>
                    <h1 className="pd_h1">{product?.name}</h1>
                    {product?.categoryId !== 0 && (
                      <p className="pd_category_text">
                        Category name : {product?.categoryId}
                      </p>
                    )}
                    <p className="pd_prod_prop_value_p">
                      {/* <span className="pd_prod_prop"> Product Id </span>
                      <span className="pd_prop_value"> : {product?.id} | </span>

                      <span className="pd_prod_prop" 
                       style={{
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "34px",
                      color: "#0F0F0F",
                    }}> Supplier </span>
                      <span className="pd_prop_value"  sx={{
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "34px",
                      color: "#0F0F0F",
                    }}>
                        :{product?.supplierId} |
                      </span> */}
                      <span
                        className="pd_prod_prop"
                        sx={{
                          fontFamily: "Work Sans",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "34px",
                          color: "#0F0F0F",
                        }}
                      >
                        {" "}
                        Created Date{" "}
                      </span>
                      <span
                        className="pd_prop_value"
                        sx={{
                          fontFamily: "Work Sans",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "34px",
                          color: "#0F0F0F",
                        }}
                      >
                        :{moment(product?.createdAt).format("MMM DD, YY")} |{" "}
                      </span>

                      {specifications.map((specification) => (
                        <>
                          <span
                            className="pd_prod_prop"
                            sx={{
                              fontFamily: "Work Sans",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "14px",
                              lineHeight: "34px",
                              color: "#0F0F0F",
                            }}
                          >
                            {specification.name}{" "}
                          </span>
                          <span
                            className="pd_prop_value"
                            sx={{
                              fontFamily: "Work Sans",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "14px",
                              lineHeight: "34px",
                              color: "#0F0F0F",
                            }}
                          >
                            : {specification.value} |{" "}
                          </span>
                        </>
                      ))}
                    </p>
                    <p>
                      <span
                        className="pd_prod_prop"
                        sx={{
                          fontFamily: "Work Sans",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "34px",
                          color: "#0F0F0F",
                        }}
                      >
                        {" "}
                        Quantity{" "}
                      </span>
                      <span
                        className="pd_prop_value"
                        sx={{
                          fontFamily: "Work Sans",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "34px",
                          color: "#0F0F0F",
                        }}
                      >
                        {product?.quantity}
                      </span>
                    </p>
                  </Grid>
                  <Grid container className="pd_parentspecialborderGrid">
                    <Grid item xs={3.7} className="pd_speicalborderGrid">
                      <p
                        className="pd_p"
                        sx={{
                          fontFamily: "Work Sans",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "34px",
                          color: "#0F0F0F",
                        }}
                      >
                        MRP Price
                      </p>
                      <h1
                        className="pd_h1"
                        sx={{
                          fontFamily: "Work Sans",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "34px",
                          color: "#0F0F0F",
                        }}
                      >
                        $ {product?.mrpPrice}
                      </h1>
                    </Grid>
                    <Grid
                      item
                      xs={3.7}
                      className="pd_speicalborderGrid"
                      sx={{
                        fontFamily: "Work Sans",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "34px",
                        color: "#0F0F0F",
                      }}
                    >
                      <p className="pd_p">Sell Price</p>
                      <h1 className="pd_h1">$ {product?.sellPrice}</h1>
                    </Grid>
                    <Grid
                      item
                      xs={3.7}
                      className="pd_speicalborderGrid"
                      sx={{
                        fontFamily: "Work Sans",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "34px",
                        color: "#0F0F0F",
                      }}
                    >
                      <p className="pd_p">Discount Price</p>
                      <h1 className="pd_h1">$ {product?.discountedPrice}</h1>
                    </Grid>
                    {/* <Grid item xs={3.7} className="pd_speicalborderGrid">
                      <p className="pd_p">Admin Price</p>
                      <h1 className="pd_h1">Rs {product?.adminPrice}</h1>
                    </Grid>
                    <Grid item xs={3.7} className="pd_speicalborderGrid">
                      <p className="pd_p">Admin Discounted Price</p>
                      <h1 className="pd_h1">
                        Rs {product?.adminDiscountedPrice}
                      </h1>
                    </Grid> */}
                  </Grid>
                </Grid>
                <Grid>
                  <p
                    className="pd_p"
                    sx={{
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "34px",
                      color: "#0F0F0F",
                    }}
                  >
                    Description
                  </p>
                  <p
                    className="pd_p"
                    sx={{
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "34px",
                      color: "#0F0F0F",
                    }}
                  >
                    {product?.description}
                  </p>
                </Grid>
              </Grid>
            </Grid>
            {/*     <Grid xs={12}>
              <Grid>
                <p className="pd_review_text_heading"> Reviews</p>
              </Grid>
              {/* review comp */}
            {/*  <Grid className="pd_review_grid">
                <Grid container style={{ margin: "0" }}>
                  <Grid className="pd_reviewimage">
                    <img className="pb_reviewUser_img" src={rect} alt="" />
                  </Grid>
                  <Grid style={{ marginLeft: "10px" }}>
                    <p className="pd_review_title">Courtney Henry</p>
                    <Stack spacing={0}>
                      <Rating
                        name="half-rating-read"
                        defaultValue={4}
                        precision={0.5}
                        readOnly
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <Grid>
                  <p className="pd_p">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s.
                  </p>
                </Grid>
              </Grid>
              <Divider />
              <Grid className="pd_review_grid">
                <Grid container style={{ margin: "0" }}>
                  <Grid className="pd_reviewimage">
                    <img className="pb_reviewUser_img" src={rect} alt="" />
                  </Grid>
                  <Grid style={{ marginLeft: "10px" }}>
                    <p className="pd_review_title">Courtney Henry</p>
                    <Stack spacing={0}>
                      <Rating
                        name="half-rating-read"
                        defaultValue={4}
                        precision={0.5}
                        readOnly
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <Grid>
                  <p className="pd_p">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s.
                  </p>
                </Grid>
              </Grid>
              <Divider />
              <Grid className="pd_review_grid">
                <Grid container style={{ margin: "0" }}>
                  <Grid className="pd_reviewimage">
                    <img className="pb_reviewUser_img" src={rect} alt="" />
                  </Grid>
                  <Grid style={{ marginLeft: "10px" }}>
                    <p className="pd_review_title">Courtney Henry</p>
                    <Stack spacing={0}>
                      <Rating
                        name="half-rating-read"
                        defaultValue={4}
                        precision={0.5}
                        readOnly
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <Grid>
                  <p className="pd_p">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s.
                  </p>
                </Grid>
              </Grid>
              
            </Grid>
             */}
          </StyledDiv>
        </Grid>
      </Box>
    </>
  );
}
