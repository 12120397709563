import axiosRequest from "../axios/axios";


///////////////////////////////////////////////////////
export const GetUsers = async (queryString) => {
  try {
    const res = await axiosRequest.get(`/api/v1/userlist` + queryString);
    return res.data;
  } catch (err) {
    return { success: false, message: err };
  }
};


///////////////////////////////////////////////////////

// export const AddUsers = (data) => async (dispatch) => {
//   try {
//     const body = data;
//     const res = await axios.post(`/api/v1/addUser`, body);

//     if (res && res.data && res.data.status && res.data.data) {
//       dispatch({
//         type: ADD_USER,
//         payload: res.data.data,
//       });
//     }
//     return res.data;
//   } catch (err) {
//     return { success: false, message: err };
//   }
// };
///////////////////////////////////////////////////////

// export const deleteUsers = (id) => async (dispatch) => {
//   try {
//     const res = await axios.delete(`/api/v1/deleteRole/${id}`);
//     if (res && res.data && res.data.status) {
//       dispatch({
//         type: DELETE_ROLE,
//         payload: id,
//       });
//     }

//     return res.data;
//   } catch (err) {
//     return { success: false, message: err };
//   }
// };
// export const UpdateUsers = (data) => async (dispatch) => {
//   try {
//     const { body, id } = data;
//     const res = await axios.post(`/api/v1/updateRole/${id}`, body);

//     if (res && res.data && res.data.status && res.data.data) {
//       dispatch({
//         type: UPDATE_ROLE,
//         payload: res.data.data,
//       });
//     }
//     return res.data;
//   } catch (err) {
//     return { success: false, message: err };
//   }
// };
// ///////////////////////////////////////////////////////
