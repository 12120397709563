import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Card } from "@mui/material";
import { styled } from "@mui/material/styles";
import "./invoice.css";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  TableContainer,
} from "@mui/material";
import { useState, useEffect } from "react";
import { Grid, Button, Stack, Typography, IconButton } from "@mui/material";
// import axiosInstance from "../../utils/AxiosInstace";
import { useLocation } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { tableCellClasses } from "@mui/material/TableCell";
// import { PDFExport } from "@progress/kendo-react-pdf";
// import Notification from "../../utils/Notification";
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const strong = {
  color: "black",
};

const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));
const StyledRequired = styled("span")({
  color: "rgba(240, 98, 55, 1)",
  marginLeft: "2px",
  fontSize: "16px",
});
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(224, 224, 224, 1)",
    color: theme.palette.common.black,
    fontSize: "12px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    height: "30px",
  },
}));

const orderStatus = (status) => {
  const statusColors = {
    0: {
      key: "Draft",
      backgroundColor: "#ede7dd",
      color: "#fc7e00",
    },
    1: {
      key: "Confirmed",
      backgroundColor: "#def7ec",
      color: "#0e9f6e",
    },
    2: {
      key: "Closed",
      backgroundColor: "rgba(240, 68, 56, 0.05)",
      color: "#f05252;",
    },
  };

  return (
    <StyledChip
      style={{
        width: "81%",
        height: "23px",
        fontSize: "12px",
      }}
      sx={statusColors[status]}
      label={statusColors[status].key}
    />
  );
};
const productStatus = (productstatus) => {
  const statusColors = {
    0: {
      key: "Closed",
      backgroundColor: "rgba(240, 68, 56, 0.05)",
      color: "#f05252;",
    },
    1: {
      key: "Partial",
      backgroundColor: "#ede7dd",
      color: "#fc7e00",
    },
    2: {
      key: "Pending",
      backgroundColor: "#fdf6b2",
      color: "#c27803",
    },
    3: {
      key: "Paid",
      backgroundColor: "#def7ec",
      color: "#0e9f6e",
    },
  };

  return (
    <StyledChip
      style={{
        width: "73%",
        height: "23px",
        fontSize: "12px",
      }}
      sx={statusColors[productstatus]}
      label={statusColors[productstatus].key}
    />
  );
};

export default function FormPropsTextFields() {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  const pdfExportComponent = React.useRef(null);

  const [deleteUser, setDeleteUser] = useState(false);
  const [invoice, setInvoice] = useState({});
  const [invoiceId, setInvoiceId] = useState({});
  const [custdata, setCustData] = useState({});
  const [productdata, setProductData] = useState([]);
  const [productdetails, setProductDetails] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const [addressdata, setAddressData] = useState({});
  const [billingaddressdata, setBillingAddressData] = useState({});
  const [orderData, setorderData] = useState([]);
  const [orderDetails, setorderDetails] = useState([]);
  const [customerData, setcustomerData] = useState({});
  const [orderProductData, setorderProductData] = useState([]);
  const [supplshippingAddress, setshippingAddress] = useState({});
  const [billingAddress, setbillingAddress] = useState({});
  const [adjustment, setAdjustment] = useState(0);
  let { id } = useParams();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const paymentStatus = (paymentstatus) => {
    const statusColors = {
      // 0: {
      //     key: "Closed",
      //     backgroundColor: "rgba(240, 68, 56, 0.05)",
      //     color: "#f05252;",
      // },
      1: {
        key: "Partial",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      2: {
        key: "Pending",
        backgroundColor: "#fdf6b2",
        color: "#c27803",
      },
      3: {
        key: "Paid",
        backgroundColor: "#def7ec",
        color: "#0e9f6e",
      },
    };

    return (
      <StyledChip
        style={{
          width: "91%",
          height: "23px",
          fontSize: "12px",
        }}
        sx={statusColors[paymentstatus]}
        label={statusColors[paymentstatus].key}
      />
    );
  };
  //   const GetpurchaseOrder = async () => {
  //     await axiosInstance
  //       .get(`/api/v1/salesorderdetail/${state}`)
  //       .then((res) => {
  //         console.log("orderdate222222222222", res.data.data.paymentStatus);
  //         setorderDetails(res.data.data);
  //         let da = res.data.data.invoiceId;
  //         console.log("lll", da);

  //         setInvoiceId(da);
  //         setcustomerData(res.data.data.customer);

  //         console.log("oooo", res.data.data);
  //         setorderProductData(res.data.data.products);
  //         // setProductKey(res.data.data.products[0].id)
  //         // console.log("dddd",res.data.data.products[0].id)

  //         setshippingAddress(res.data.data.customer.address);
  //         setbillingAddress(res.data.data.customer.billingAddress);
  //         // // supplierNames = res.data.data.supplier.supplierName;
  //         // console.log("fff",orderData)
  //         setorderData(res.data.data);
  //         // console.log("orderdate",res.data.data)
  //       })
  //       .catch((e) => {
  //         console.log("error found");
  //       });
  //   };

  //   const GetCustomerInvoice = async () => {
  //     console.log("inside invoice", state);
  //     await axiosInstance

  //       .post(`api/v1/salesorderinvoice/${state}`)
  //       .then((res) => {
  //         console.log("invoice", res.data.data);
  //         setInvoice(res.data.data);
  //         let customer_data = res.data.data.customer;

  //         let product_data = res.data.data.products;
  //         console.log("222222.", product_data[0].productName);
  //         setProductData(product_data);
  //         console.log(".......", customer_data);
  //         setCustData(customer_data);
  //         let address_data = res.data.data.customer.address;
  //         setAddressData(address_data);
  //         let bil_address_data = res.data.data.customer.billingAddress;
  //         setBillingAddressData(bil_address_data);
  //       })
  //       .catch((e) => {
  //         console.log("error found");
  //       });
  //   };
  // const GetSaleOrder = async () => {
  //     await axiosInstance
  //         .get(`/api/v1/salesorderdetail/${state}`).then((res) => {
  //             console.log(res.data.data)
  //             let quant = res.data.data.products[0];
  //             setQuantity(quant)
  //             setorderData(res.data.data);
  //         }).catch((e) => {
  //             console.log("error found")
  //         })
  // }
  //   let productDetails = async (product) => {
  //     await axiosInstance
  //       .get(`/api/v1/salesorderdetail/${state}`)
  //       .then((res) => {
  //         // if (res.data.status == "success") {

  //         res.data.data.productQuantity = 1;
  //         // setProductdata([])
  //         // setProductdata((prev) => [...prev, res.data.data])
  //         const responseProductData = res.data.data;
  //         setProductDetails((prev) => [...prev, res.data.data]);
  //       })
  //       .catch((e) => {
  //         console.log("error found");
  //       });
  //   };

  useEffect(() => {
    // GetpurchaseOrder();
    // GetSaleOrder();
    // if(state != 0){
    // }
    // productDetails();
  }, []);
  const downloadPdf = async () => {
    if (pdfExportComponent.current) {
      // navigate(-1);
      //how to change download pdf file name
      await pdfExportComponent.current.save(
        "Invoice.pdf" + ".pdf" + "?download=true"
      );
    }
  };
  const handleDeleteUser = () => {
    setDeleteUser(true);
  };
  const handleSubmit = async () => {
    // e.preventDefault();
    console.log("state", state);

    // console.log('values.country ', countrydata.name);
    const payload = {
      adjustment: adjustment,
    };
    // console.log("values,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,", payload)

    // if (state) {
    //   console.log("hiiiiiii");
    //   await axiosInstance
    //     .post(`api/v1/salesorderinvoice/${state}`, payload)
    //     .then((res) => {
    //       console.log("-11------------add", res.data);
    //       if (res.data.status === true) {
    //         console.log("aaa", res.data.status);
    //         setNotify({
    //           isOpen: true,
    //           message: "Sales order Added Successfully",
    //           type: "success",
    //         });
    //         setTimeout(() => {
    //           navigate("/order");
    //         }, 500);
    //       }
    //     })
    //     .catch((e) => {
    //       // setState({errorMessage: e.message});
    //       console.log("error found", e.response.data.message);
    //       let msg = e.response.data.message;
    //       // setErrorMessage(msg)
    //       // setNotify({

    //       //   isOpen: true,
    //       //   message:e.response.data.message,
    //       //   type: "warning",

    //       // });
    //       // setTimeout(() => {
    //       //   navigate('/customer');
    //       // }, 3000);
    //     });
    // }
  };
  let total;

  const closeDeleteUser = () => {
    setDeleteUser(false);
  };
  return (
    <>
      <div id="invoice-container">
        <div>
          <h1 id="headder-left-side-first"> Invoice </h1>
          <p id="headder-left-side-second">
            Order |{" "}
            <span style={{ color: "black", fontSize: "16px" }}>
              View Invoice
            </span>
          </p>
        </div>
        {invoiceId != "0" && (
          <Box marginTop="20px" borderRadius="12px">
            <Button
              variant="contained"
              onClick={() => {
                downloadPdf();
              }}
              size="large"
              id="download-invoice-button"
            >
              <strong
                style={{ color: "white", fontSize: "13px", fontWeight: "500" }}
              >
                Download Invoice
              </strong>
            </Button>
          </Box>
        )}
      </div>

      {/* <PDFExport ref={pdfExportComponent} paperSize="A4" scale={0.7}> */}
      <Card
        style={{
          padding: "20px",
          borderRadius: "12px",
        }}
      >
        <Grid item md={12} sm={8} xs={12} container spacing={1}>
          <Grid item md={6} xs={12}>
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="h6" id="invoive-headder">
                Invoice
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                variant="h6"
                id="invoive-headder-second"
                style={{ width: "300px", display: "flex" }}
              >
                Status
                <StyledRequired id="invoive-headder-second-panding">
                  {" "}
                  pending
                </StyledRequired>
              </Typography>
              {/* <Typography variant="h6"
                                    id="invoive-headder-second"
                                    style={{width:"300px" ,display:"flex"}} >Status
                                    <StyledRequired id='invoive-headder-second-panding'>{paymentStatus(invoice.paymentStatus)}</StyledRequired>
                                </Typography> */}
            </Grid>
          </Grid>

          <Grid
            item
            md={6}
            xs={12}
            style={{
              textAlign: "right",
            }}
          >
            <Grid item md={12} sm={12} xs={12}>
              <img
                src="https://peadmin.mobiginie.com/static/media/logo.63b9e1a57583123ddfd8778f3428ac00.svg"
                alt="logo"
                id="invoice-headder-image"
              />
            </Grid>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              style={{
                marginTop: "20px",
              }}
            >
              <Typography variant="h6" id="invoive-headder-second">
                1901 Thornridge Cir. Shiloh, Hawaii 81063
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container md={12} xs={12}>
          <Grid item md={8.8} xs={12}>
            <Grid item md={4.5} xs={12} style={{ display: "flex" }}>
              <Typography variant="h6" id="invoice-date">
                Date :
              </Typography>
              <Typography variant="h6" id="invoice-date-value">
                {orderDetails.orderDate}
              </Typography>
            </Grid>
            <Grid item md={4.5} xs={12} style={{ display: "flex" }}>
              <Typography
                variant="h6"
                id="invoice-number"
                Style={{ display: "flex" }}
              >
                Order Number :{" "}
              </Typography>
              <Typography variant="h6" id="invoice-number-value">
                {orderDetails.orderNumber}
              </Typography>
            </Grid>
            <Grid item md={4.5} xs={12} style={{ display: "flex" }}>
              <Typography variant="h6" id="invoice-date">
                Customer Name :{" "}
              </Typography>
              <Typography variant="h6" id="invoice-date-value">
                {customerData.customerName}
              </Typography>
            </Grid>
            <Grid item md={4.5} xs={12} style={{ display: "flex" }}>
              <Typography variant="h6" id="invoice-date">
                Company Name :{" "}
              </Typography>
              <Typography variant="h6" id="invoice-date-value">
                {customerData.companyName}
              </Typography>
            </Grid>
            <Grid item md={4.5} xs={12} style={{ display: "flex" }}>
              <Typography variant="h6" id="invoice-date">
                Customer Email :{" "}
              </Typography>
              <Typography variant="h6" id="invoice-date-value">
                {customerData.customerEmail}
              </Typography>
            </Grid>
            <Grid item md={4.5} xs={12} style={{ display: "flex" }}>
              <Typography variant="h6" id="invoice-date">
                Company Email :{" "}
              </Typography>
              <Typography variant="h6" id="invoice-date-value">
                {customerData.companyEmail}
              </Typography>
            </Grid>
          </Grid>

          <Grid item md={3.2} xs={12} style={{ marginTop: "10px" }}>
            <Typography variant="h6" id="invoice-to">
              Invoice TO{" "}
            </Typography>
            <Typography
              variant="h6"
              id="invoice-to-name"
              style={{ color: "black" }}
            >
              Shipping Address:{" "}
              {supplshippingAddress.address +
                ", " +
                supplshippingAddress.countryName +
                ", " +
                supplshippingAddress.stateName +
                ", " +
                supplshippingAddress.city +
                ", " +
                supplshippingAddress.zipCode}{" "}
            </Typography>
            <Typography
              variant="h6"
              id="invoice-to-address"
              style={{ color: "black" }}
            >
              Billing Address:{" "}
              {billingAddress.address +
                ", " +
                billingAddress.countryName +
                ", " +
                billingAddress.stateName +
                ", " +
                billingAddress.city +
                ", " +
                billingAddress.zipCode}
            </Typography>
          </Grid>
        </Grid>

        <TableContainer id="table-container">
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ textAlign: "center", fontSize: "12px" }}
                >
                  {" "}
                  PRODUCT NAME
                </StyledTableCell>

                <StyledTableCell>QUANTITY</StyledTableCell>
                <StyledTableCell>RATE</StyledTableCell>
                {/* <StyledTableCell>ORDER STATUS</StyledTableCell> */}
                {/* <StyledTableCell>PAYMENT STATUS</StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody style={{ color: "black" }}>
              {orderProductData.map((row, index) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ justifyItems: "center" }}
                  >
                    {row.productName}
                  </StyledTableCell>

                  <StyledTableCell style={{ justifyItems: "center" }}>
                    {row.quantity}
                  </StyledTableCell>
                  <StyledTableCell style={{ justifyItems: "center" }}>
                    {row.price}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <div style={{ borderTop: "1px solid rgba(15, 15, 15, 0.15)" }}></div>
          <FlexBox px={2} id="purchase-order-invoice-footer">
            <Box display="flex">
              <Box pr={6}>
                <Typography
                  id="purchase-order-details-footer-subtotal"
                  sx={{ my: 2 }}
                >
                  Subtotal:
                </Typography>
                <Typography
                  id="purchase-order-details-footer-tax"
                  sx={{ mb: 2 }}
                  style={{ marginTop: "14px" }}
                >
                  Other Charges
                </Typography>

                {invoiceId != "0" && (
                  <Typography
                    id="purchase-order-details-footer-shipping"
                    sx={{ mb: 2 }}
                    style={{ marginTop: "17px" }}
                  >
                    {" "}
                    Adjustment
                  </Typography>
                )}
                {invoiceId == "0" && (
                  <Typography
                    id="purchase-order-details-footer-shipping"
                    sx={{ mb: 2 }}
                  >
                    <TextField
                      id="standard-basic"
                      style={{
                        width: "70px",
                        marginLeft: "0px",
                        color: "black",
                      }}
                      InputProps={{
                        style: {
                          height: "23px",
                          fontSize: "12px",
                          width: "100px",
                          marginTop: "9px",
                        },
                      }}
                      placeholder="Adjustment"
                      size="small"
                    />
                  </Typography>
                )}
              </Box>
              <div
                style={{
                  marginLeft: "21px",
                }}
              >
                <Typography
                  id="purchase-order-details-footer-subtotal-value"
                  sx={{ my: 2 }}
                >
                  <b>{orderDetails.subtotal}</b>
                </Typography>
                <Typography
                  id="purchase-order-details-footer-tax-value"
                  sx={{ mb: 2 }}
                  style={{ marginTop: "13px" }}
                >
                  {orderDetails.otherCharges}
                </Typography>

                {/* {orderDetails.invoiceId =!0 && (
                                    <Typography id="purchase-order-details-footer-shipping-value" sx={{ mb: 2 }} style={{ marginTop: "10px" }}> {orderDetails.adjustment}</Typography>
                                    )} */}

                <Typography
                  id="purchase-order-details-footer-shipping-value"
                  sx={{ mb: 2 }}
                >
                  {invoiceId == "0" && (
                    <TextField
                      id="standard-basic"
                      style={{
                        width: "71px",

                        color: "black",
                      }}
                      InputProps={{
                        style: {
                          height: "23px",
                          fontSize: "12px",
                          width: "70px",
                          marginTop: "24px",
                        },
                      }}
                      defaultValue={0}
                      onChange={(e) => {
                        setAdjustment(e.target.value);
                      }}
                      value={adjustment}
                      size="small"
                    />
                  )}
                </Typography>
                {invoiceId != "0" && (
                  <Typography
                    id="purchase-order-details-footer-shipping-value"
                    sx={{ mb: 2 }}
                  >
                    {" "}
                    {orderDetails.adjustment}
                  </Typography>
                )}
              </div>
            </Box>
          </FlexBox>

          <div style={{ borderTop: "1px solid rgba(15, 15, 15, 0.15)" }}></div>

          <FlexBox px={2} id="purchase-order-invoice-footer">
            <Box display="flex" style={{ marginTop: "8px" }}>
              <Box pr={6}>
                <Typography sx={{ mb: 2 }}>Total</Typography>
              </Box>
              <div id="purchase-order-details-footer-total-value">
                <Typography sx={{ mb: 2 }}>
                  <b>
                    {orderDetails.payableAmount -
                      parseInt(adjustment) -
                      parseInt(orderDetails.adjustment)}
                  </b>
                </Typography>
              </div>
            </Box>
          </FlexBox>
          {invoiceId == "0" && (
            <Button
              variant="contained"
              size="large"
              style={{
                backgroundColor: "#f06237",
                borderRadius: "10px",
              }}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              <strong className="purchaseOrderheadder-box-button2-strong">
                Save
              </strong>
            </Button>
          )}
        </TableContainer>
      </Card>
      {/* </PDFExport> */}
      {/* <Notification notify={notify} setNotify={setNotify} /> */}
    </>
  );
}
