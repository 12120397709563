import { useState } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  InputAdornment,
} from "@mui/material";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { TextField, IconButton, Tooltip, Button, Stack } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { styled, useTheme, useMediaQuery } from "@mui/material";
import RectangleGlass from "../../assets/Rectangle_glass.png";
import { useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../state/actions/authActions";
import validator from "validator";
import { useSnackbar } from "notistack";
import BackdropComponent from "../backdrop/backdrop";

const StyledLeftSide = styled("div")(() => ({
  borderRadius: "10px",
  backgroundColor: "#60579A",
  height: "88vh",
  position: "relative",
}));
const StyledRightSide = styled("div")(() => ({
  borderRadius: "10px",
  height: "88vh",
}));
const StyledLabel = styled("label")(() => ({
  display: "inline-block",
  fontWeight: "500",
  fontSize: "14px",
  mt: 1,
  borderRadius: "8px",
}));
const StyledLogin = styled(Button)(() => ({
  backgroundColor: "#60579A",
  color: "white",
  fontSize: "16px",
  padding: "8px 20px",
  "&:hover": {
    backgroundColor: "#60579A",
    color: "white",
  },
}));

const Login = () => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((store) => store.auth.isAuthenticated);
  if (isAuthenticated) {
    navigate("/dashboard");
  }
  const dispatch = useDispatch();
  const lg = useMediaQuery("@media (max-width:900px)");
  const xs = useMediaQuery("@media (max-width:600px)");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  //  backdrop states
  const [backdropState, setBackdropState] = useState(false);

  const user = useSelector((state) => state?.auth?.user?.role === 2);

  const stopBackdrop = () => {
    setBackdropState(false);
  };
  const startBackdrop = () => {
    setBackdropState(true);
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.isEmail(email)) {
      enqueueSnackbar("Invalid Email !", { variant: "error" });
    }
    // else if (password && password.length < 8) {
    //   enqueueSnackbar("Password length should be 8 !", { variant: "error" });
    // } else
    else if (!password) {
      enqueueSnackbar("Password required !", { variant: "error" });
    } else {
      startBackdrop();
      const response = await dispatch(loginUser(email, password));
      if (response && response.status === true) {
        enqueueSnackbar(response.message, { variant: "success" });
      }
      if (response && response.status === false) {
        enqueueSnackbar(
          response.message ||
            "Unexpected error occured please try again later.",
          { variant: "error" }
        );
      }
      stopBackdrop();
    }
  };
  return (
    <div>
      <BackdropComponent open={backdropState} />

      <Grid
        container
        spacing={1}
        sx={{ backgroundColor: "#F8F8F8", height: "100vh" }}
      >
        <Grid item lg={5} md={5} sm={5} xs={12} sx={{ margin: "35px 20px" }}>
          <StyledLeftSide>
            <Box
              sx={{
                position: "absolute",
                borderRadius: "10px",
                height: "-webkit-fill-available",
                backgroundColor: "rgba(255, 255, 255, 0.2);",
                margin: lg ? "20px" : "40px",
                color: "white",
                padding: lg ? "0px 15px" : "0px 50px",
              }}
            >
              <h1>Welcome to Press Emporium</h1>
              <p>
                “Our approach to sourcing is simple… We offer the finest quality
                materials and a final product that delivers with impact !”
              </p>
            </Box>
            <img
              src={RectangleGlass}
              style={{ width: "100%", height: "100%" }}
              alt="Login Background"
            />
          </StyledLeftSide>
        </Grid>

        <Grid item lg={5} md={5} sm={5} xs={12} sx={{ margin: "35px 20px" }}>
          <StyledRightSide>
            <Container sx={xs ? {} : { pt: "20%" }}>
              <Box sx={{ mb: 3 }}>
                <Typography
                  component={"h4"}
                  sx={{
                    display: "inline-block",
                    fontSize: "36px",
                    fontWeight: "600",
                  }}
                >
                  Login
                </Typography>
                <br />
                <Typography
                  sx={{
                    display: "inline-block",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Login to manage your account
                </Typography>
              </Box>
              <form onSubmit={handleSubmit} autoComplete="off">
                <Box sx={{ mt: 2 }}>
                  <StyledLabel>Email</StyledLabel>
                  <TextField
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                    sx={{ mt: 1, borderRadius: "8px" }}
                    size="small"
                    fullWidth
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <StyledLabel>Password</StyledLabel>
                  <TextField
                    value={password}
                    onChange={handlePasswordChange}
                    type={showPassword ? "text" : "password"}
                    sx={{ mt: 1, borderRadius: "8px" }}
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start" sx={{ m: 0 }}>
                          <Tooltip
                            title={
                              showPassword ? "Hide Password" : "Show Password"
                            }
                          >
                            <IconButton onClick={handleClickShowPassword}>
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  sx={{ mt: 2 }}
                >
                  <FormGroup>
                    <FormControlLabel
                      label="Remember me"
                      control={
                        <Checkbox
                          checked={rememberMe}
                          onChange={handleRememberMeChange}
                        />
                      }
                    />
                  </FormGroup>
                  <Button
                    onClick={() => {
                      navigate("/forgetpassword");
                    }}
                    sx={{ textTransform: "capitalize" }}
                  >
                    Forgot Password ?
                  </Button>
                </Stack>
                <StyledLogin
                  // onClick={() => {
                  //   navigate("/dashboard");
                  // }}

                  sx={{ mt: 3 }}
                  type="submit"
                >
                  Login
                </StyledLogin>
              </form>
            </Container>
          </StyledRightSide>
        </Grid>
      </Grid>
    </div>
  );
};
export default Login;
