import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { changeStatus } from "../../../state/actions/supplierActions";
import { useSnackbar } from "notistack";
import BackdropComponent from "../../backdrop/backdrop";

const ApproveRequest = (props) => {
  const { user, open, handleClose, refreshScreen } = props;
  const { enqueueSnackbar } = useSnackbar();
  //  backdrop states
  const [backdropState, setBackdropState] = useState(false);
  const stopBackdrop = () => {
    setBackdropState(false);
  };
  const startBackdrop = () => {
    setBackdropState(true);
  };

  const onSubmit = async () => {
    const body = {
      status: "approved",
      userId: user.id,
      type: "supplier",
    };
    startBackdrop();
    const res = await changeStatus(body);
    if (res && res.status === true) {
      handleClose();
      refreshScreen();
      enqueueSnackbar(res.message, { variant: "success" });
    }
    if (res && res.status === false) {
      handleClose();
      refreshScreen();
      enqueueSnackbar(res.message, { variant: "error" });
    }
    stopBackdrop();
  };
  return (
    <div>
      <BackdropComponent open={backdropState} />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Approve Request
        </DialogTitle>

        <DialogContent>
          Are you sure you want to approve this customer ?
        </DialogContent>
        <DialogActions sx={{ margin: "15px", mx: "auto" }}>
          <Button
            sx={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid #0F0F0F80",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                // backgroundColor: "#60579A",
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: "#60579A",
              color: "white",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                backgroundColor: "#60579A",
              },
            }}
            onClick={onSubmit}
          >
            Approve
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ApproveRequest;
