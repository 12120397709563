import * as React from "react";
import Box from "@mui/material/Box";
import {
  Card,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SwipeableDrawer,
  Tab,
  TableContainer,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Grid, Button, Stack, Typography, IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import { Table, TableHead, TableBody, TableRow, Chip } from "@mui/material";
import "./orderDetails.css";
import {
  adminStatusChange,
  getOrderDetails,
  GetOrderStatusList,
  GetOrderSuppliersList,
  supplierOrderCreates,
  supplierQuatation,
} from "../../state/actions/orderAction";
import moment from "moment";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import BackdropComponent from "../backdrop/backdrop";
import { useForm } from "../../utils/useForm";
import AdminSupplierOrdersDetails from "./AdminSupplierOrdersDetails";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";

import { useSnackbar } from "notistack";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    paddingTop: "10px",
    paddingBottom: "10px",
    position: "sticky",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13.5px",
    lineHeight: "34px",
    color: "rgba(15, 15, 15, 0.5)",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#0957dd0a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledLabel = styled("label")(() => ({
  display: "inline-block",
  fontWeight: "500",
  fontSize: "14px",
  mt: 1,
  borderRadius: "8px",
}));

const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const initialValues = {
  status: "",
};

export default function FormPropsTextFields(props) {
  const { refreshScreen } = props;

  const [orderProductData, setorderProductData] = useState([]);

  const [orderData, setorderData] = useState([]);
  const location = useLocation();
  const [shippingAddress, setshippingAddress] = useState({});
  const [orderStatusList, setOrderStatusList] = useState([]);
  const [city, setCity] = useState([]);
  const [states, setStates] = useState([]);
  const [country, setCountry] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [isStatusEditing, setStatusEditing] = useState();
  const [statusMenu, setStatusMenu] = useState(true);
  const [quotationId, setQuotationId] = useState(0);
  const [supplierName, setSupplierName] = useState([]);

  const [status, setStatus] = useState();

  const [backdropState, setBackdropState] = useState(false);
  const stopBackdrop = () => {
    setBackdropState(false);
  };
  const startBackdrop = () => {
    setBackdropState(true);
  };

  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState("1");
  const [age, setAge] = useState("");

  const openStatusMenu = () => {
    setStatusMenu(true);
  };
  const closeStatusMenu = () => {
    setStatusMenu(false);
  };

  const startStatusEditing = (id, e) => {
    setStatusEditing(id);
    setQuotationId(id);
  };

  const startStatusUpdate = (e, index) => {
    let newArr = [...orderProductData]; // copying the old datas array
    newArr[index][e.target.name] = e.target.value;
    setorderProductData(newArr);
    // setorderProductData({ ...orderProductData, [e.target.name]: e.target.value });
  };

  const stopStatusEditing = (id) => {
    setStatusEditing(id);
  };

  const handleChangess = (e, index) => {
    // setSupplierName({ ...supplierName, [e.target.id]: e.target.value });
    // let newArr = [...supplierName]; // copying the old datas array
    // newArr[index][e.target.name] = e.target.value;
    orderProductData[index].statusss = e.target.value;
  };

  const handleStatusChanges = (e) => {
    setStatus(e.target.value);
  };

  const getAllSupplierList = async (quotationId) => {
    const res = await GetOrderSuppliersList();
    if (res && res.status) {
      setSupplierList(res.data);
    }
  };

  useEffect(() => {
    getAllSupplierList();
  }, []);

  const handleChanges = (event) => {
    setAge(event.target.value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const { state } = useLocation();

  const id = state;

  const getorderStatus = async () => {
    const res = await GetOrderStatusList();
    if (res && res.status) {
      setOrderStatusList(res.data);
    }
  };

  useEffect(() => {
    getorderStatus();
  }, []);

  const OrderDetails = async (id) => {
    startBackdrop();
    const res = await getOrderDetails(id);

    if (res && res.status === true) {
      setorderProductData(res.productDetail);
      setorderData(res.data);
      // setcustomerData(res.data.customer);
      setshippingAddress(res.data.shippingAddressId);
      setCity(res.data.shippingAddressId?.city);
      setStates(res.data.shippingAddressId?.state);
      setCountry(res.data.shippingAddressId?.country);

      // setbillingAddress(res.data.billingAddress);
    }
    stopBackdrop();
  };

  useEffect(() => {
    OrderDetails(id);
  }, [id]);

  const productStatus = (productstatus) => {
    const statusColors = {
      0: {
        key: "Closed",
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#f05252;",
      },
      1: {
        key: "Paid",
        backgroundColor: "#ede7dd",
        color: "#0e9f6e",
      },
      2: {
        key: "Pending",
        backgroundColor: "#fdf6b2",
        color: "#c27803",
      },
      3: {
        key: "Partial",
        backgroundColor: "#def7ec",
        color: "gray",
      },
    };

    return (
      <StyledChip
        style={{
          width: "30%",
          height: "23px",
          fontSize: "10px",
        }}
        sx={statusColors[productstatus]}
        label={
          statusColors[productstatus] ? statusColors[productstatus].key : ""
        }
      />
    );
  };

  const orderStatus = (orderstatus) => {
    const statusColors = {
      1: {
        key: "Pending",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      2: {
        key: "InProgress",
        backgroundColor: "#fdf6b2",
        color: "#c27803",
      },
      3: {
        key: " Ready to dispatch ",
        backgroundColor: "#def7ec",
        color: "#0e9f6e",
      },
      4: {
        key: "Shipped ",
        backgroundColor: "#def7ec",
        color: "blue",
      },
      5: {
        key: "Delivered",
        backgroundColor: "#def7ec",
        color: "green",
      },
      6: {
        key: "Cancel",
        backgroundColor: "#def7ec",
        color: "black",
      },
      7: {
        key: "Failed",
        backgroundColor: "#def7ec",
        color: "red",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[orderstatus]}
        label={statusColors[orderstatus] ? statusColors[orderstatus].key : ""}
      />
    );
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true);

  const onSubmit = async (data) => {
    let datas = supplierList?.filter((x) => {
      if (x.id == data.statusss) {
        return x;
      }
    });
    console.log("🚀 ~ file: OrderDetails.jsx:311 ~ onSubmit ~ data", datas);

    if (datas.length > 0) {
      // datas = datas[0];

      const body = {
        orderId: id,
        supplierId: data?.statusss,
        productId: data?.id,
        itemQty: data?.quantity,
        totalAmount: data?.sellPrice,
        status: 1,
        type: 2,
        supplierPrice: data?.supplierPrice,
        // supplierName: datas[0]?.name,
      };

      const res = await supplierOrderCreates(body);
      if (res) {
        const body2 = {
          orderId: id,
          supplierId: data?.statusss,
          productId: data?.id,
          itemQty: data?.quantity,
          totalAmount: data?.sellPrice,
          status: 1,
          type: 2,
          supplierPrice: data?.supplierPrice,
          supplierOrderId: res.data.id,
          // supplierName: datas[0]?.name,
        };
        const ress = await supplierQuatation(body2);

        if (ress && ress.status === true) {
          enqueueSnackbar(ress.message, { variant: "success" });
        }
        if (ress && ress.status === false) {
          enqueueSnackbar(
            ress.message || "Unexpected error occured please try again later.",
            { variant: "error" }
          );
        }
      }
    }
  };

  const changeStatus = async (event, data) => {
    const body = {
      orderId: id,
      itemId: data?.id,
      status: event.target.value,
    };
    const res = await adminStatusChange(body);
    if (res && res.status === true) {
      enqueueSnackbar(res.message, { variant: "success" });
    } else {
      console.log("🚀 ~ file: OrderDetails.jsx:373 ~ changeStatus ~ res:", res);
      enqueueSnackbar("Failed", { variant: "error" });
    }
  };

  return (
    <>
      <BackdropComponent open={backdropState} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h1
            style={{
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "26px",
              marginBottom: "8px",
            }}
          >
            {" "}
          </h1>
        </div>
        <Box
          borderRadius="12px"
          style={{
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#60579A",
              borderRadius: "10px",
            }}
            onClick={() => {
              navigate(`/orders`);
            }}
          >
            <strong
              style={{ color: "white", fontWeight: "500", fontSize: "13px" }}
            >
              Back
            </strong>
          </Button>
        </Box>
      </div>
      <Card
        style={{
          padding: "20px",
          borderRadius: "12px",
          marginTop: "20px",
        }}
      >
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label=" User Order Details" value="1" />
                <Tab label=" Supplier Order Details" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Grid
                container
                spacing={2}
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{ marginTop: "10px" }}
              >
                <Grid xs={2} sm={4} md={8}>
                  <Typography variant="h2" id="purchase-order-details-number">
                    Order Details
                  </Typography>
                  <Typography sx={{ marginTop: "10px" }}>
                    Order No. {orderData.orderNumber}
                  </Typography>

                  <Typography
                    sx={{ marginTop: "18px", color: "rebeccapurple" }}
                  >
                    Customer Details
                  </Typography>

                  <Typography sx={{ fontWeight: "bold" }}>
                    {orderData?.userId?.fname} {orderData?.userId?.lname}
                  </Typography>
                  <Typography>
                    {" "}
                    {orderData?.userId?.mobile} {orderData?.userId?.email}
                  </Typography>
                  <Typography>
                    {shippingAddress?.address} {city?.cityName} ,
                    {states?.stateName}, {country?.name}{" "}
                    {shippingAddress?.zipcode}
                  </Typography>
                </Grid>
                <Grid xs={2} sm={4} md={2}>
                  <Typography sx={{ fontSize: "14px", marginLeft: "6px" }}>
                    Order Date
                  </Typography>
                  <Typography
                    variant="h6"
                    id="purchase-order-details-status-value2"
                    sx={{ fontSize: "20px" }}
                  >
                    {moment(orderData.createdAt).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>

                <Grid xs={2} sm={4} md={2}>
                  <Typography sx={{ fontSize: "14px", marginLeft: "6px" }}>
                    Order Status
                  </Typography>

                  <Typography sx={{ marginTop: "3px" }}>
                    {orderStatus(orderData?.status)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ paddingLeft: "-4px" }}>
                  <TableContainer
                    component={Paper}
                    sx={{
                      minHeight: "300px",
                      marginTop: "45px",
                      boxShadow: "none",
                    }}
                  >
                    <Table stickyHeader aria-label="simple table">
                      <TableHead>
                        <StyledTableCell>Product Name</StyledTableCell>
                        <StyledTableCell>Quantity</StyledTableCell>
                        <StyledTableCell>Price</StyledTableCell>
                        <StyledTableCell>Amount</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell>Supplier Price</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>Action</StyledTableCell>
                      </TableHead>
                      <TableBody>
                        {orderProductData.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            style={{ cursor: "pointer" }}
                            hover
                          >
                            <TableCell sx={{ padding: "0px" }}>
                              <Stack flexDirection="row" alignItems="center">
                                <Avatar
                                  component="span"
                                  variant="rounded"
                                  alt="profile_image"
                                  src={row.images[0].imageUrl}
                                />

                                <Typography sx={{ ml: 2, fontSize: "12px" }}>
                                  {row.name}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell sx={{ padding: "24px" }}>
                              {row.quantity}
                            </TableCell>

                            <TableCell sx={{ padding: "0px" }}>
                              {" "}
                              $ {row.sellPrice}
                            </TableCell>

                            <TableCell sx={{ padding: "0px" }}>
                              $ {row.quantity * row.sellPrice}
                            </TableCell>

                            <TableCell sx={{ padding: "0px" }}>
                              <select
                                id={row.id}
                                name="status"
                                value={row?.status}
                                onChange={(event) => changeStatus(event, row)}
                                style={{
                                  width: "100%",
                                  height: "45px",
                                  border: "1px solid #ccc",
                                  padding: "5px",
                                  marginTop: "10px",
                                  fontSize: "16px",
                                  backgroundColor: "#fff",
                                }}
                              >
                                {orderStatusList?.map((status) => (
                                  <option value={status.id} key={status?.key}>
                                    {status?.name}
                                  </option>
                                ))}
                              </select>
                            </TableCell>

                            {row.status != "6" ? (
                              <TableCell sx={{ padding: "0px" }}>
                                {isStatusEditing === row.id ? (
                                  <Box sx={{ marginTop: "30px" }}>
                                    <TextField
                                      // type="number"
                                      size="small"
                                      variant="outlined"
                                      fullWidth
                                      id="status"
                                      name="supplierPrice"
                                      value={row?.supplierPrice}
                                      open={statusMenu}
                                      onOpen={openStatusMenu}
                                      onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                          stopStatusEditing();
                                          closeStatusMenu();
                                        }
                                      }}
                                      onChange={(event) =>
                                        startStatusUpdate(event, index)
                                      }
                                      sx={{
                                        width: "63%",
                                        marginLeft: "25px",
                                        marginTop: "-22px",
                                      }}
                                      onBlur={() => stopStatusEditing()}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment
                                            position="end"
                                            sx={{ color: "black" }}
                                          >
                                            <EditIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                    ></TextField>
                                  </Box>
                                ) : (
                                  <IconButton
                                    onClick={() => {
                                      startStatusEditing(row.id);
                                      openStatusMenu();
                                    }}
                                    size="large"
                                    style={{ color: "black", margin: "17px" }}
                                  >
                                    <TableCell>
                                      {row.supplierPrice
                                        ? row.supplierPrice
                                        : 0.0}
                                    </TableCell>
                                    <EditIcon />
                                  </IconButton>
                                )}
                              </TableCell>
                            ) : null}

                            {row.status != "6" ? (
                              <TableCell sx={{ padding: "0px" }}>
                                <select
                                  id={row.id}
                                  name="supplierName"
                                  value={row.statusss}
                                  onChange={(event) =>
                                    handleChangess(event, index)
                                  }
                                  style={{
                                    width: "103%",
                                    height: "45px",
                                    border: "1px solid #ccc",
                                    padding: "5px",
                                    marginTop: "10px",
                                    fontSize: "16px",
                                    backgroundColor: "#fff",
                                  }}
                                >
                                  <option
                                    value={row.id}
                                    sx={{
                                      width: "200px",
                                      padding: "20px",
                                      height: "150px",
                                    }}
                                  >
                                    Select Supplier
                                  </option>
                                  {supplierList.map((supplier) => (
                                    <option
                                      value={supplier.id}
                                      key={supplier?.key}
                                      sx={{
                                        width: "200px",
                                        padding: "20px",
                                        height: "150px",
                                      }}
                                    >
                                      {supplier.name}
                                    </option>
                                  ))}
                                </select>
                              </TableCell>
                            ) : null}
                            {row.status != "6" ? (
                              <TableCell sx={{ padding: "0px" }}>
                                <Button
                                  variant="contained"
                                  onClick={() => onSubmit(row)}
                                  sx={{
                                    fontFamily: "Work Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                    background: "#60579A",

                                    boxShadow:
                                      "0px 1px 2px rgba(16, 24, 40, 0.05)",
                                    borderRadius: "10px",
                                    width: "0px",
                                    marginTop: "5px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  Send
                                </Button>
                              </TableCell>
                            ) : null}
                          </TableRow>
                        ))}{" "}
                        {orderProductData?.length === 0 ? (
                          <TableRow>
                            <TableCell
                              sx={{
                                color: "silver",
                                textAlign: "center",
                                paddingTop: "90px",
                                borderBottom: "none",
                                fontSize: "30px",
                              }}
                              colSpan="7"
                            >
                              No records to display
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>

              <FlexBox id="purchase-order-details-footer">
                <Box display="flex">
                  <Box>
                    <Typography sx={{ mb: 2, fontWeight: "bold" }}>
                      Total
                      <span style={{ color: "rebeccapurple" }}>
                        :$ {orderData.amount}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </FlexBox>
            </TabPanel>

            <TabPanel value="2">
              <AdminSupplierOrdersDetails />
            </TabPanel>
          </TabContext>
        </Box>
      </Card>
    </>
  );
}
