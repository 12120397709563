import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
  Sort as SortIcon,
} from "@mui/icons-material";
import {
  TableContainer,
  Paper,
  Table,
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  Modal,
  Pagination,
  tableCellClasses,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Drawer,
  Box,
  TextField,
  FormLabel,
  Switch,
  Avatar,
} from "@mui/material";
import eye from "../../../assets/eye.svg";
import edit from "../../../assets/edit.svg";
import deleteIcon from "../../../assets/delete.svg";
import filterIcon from "../../../assets/filter.svg";
import {
  GetBuyersList,
  changeBuyerStatus,
  exportBuyerData,
} from "../../../state/actions/buyerActions";
import { useForm } from "../../../utils/useForm";
import { useNavigate } from "react-router-dom";
import BackdropComponent from "../../../components/backdrop/backdrop";
import UpdateBuyer from "../AddBuyer";
import BuyerDetail from "../BuyerDetails";
import BuyerDelete from "./BuyerDelete";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import DefaultImage from "../../../assets/user1.png";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontFamily: "Work Sans",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "34px",
  color: "#0F0F0F",
}));

const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "45px",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "34px",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgb(241 241 241)",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "34px",
    color: "rgba(15, 15, 15, 0.5)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    // hover color add
    "&:hover": {
      color: "#60579A",
    },
  },
}));

const initialValues = {
  name: "",
  email: "",
  mobile: "",
  status: "",
};

const BuyersList = (props) => {
  const [backdropState, setBackdropState] = useState(false);
  const stopBackdrop = () => {
    setBackdropState(false);
  };
  const startBackdrop = () => {
    setBackdropState(true);
  };
  const { refresh, refreshScreen } = props;
  const [buyerList, setBuyerList] = useState([]);
  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [priceRange, newPriceRange] = useState([20, 40]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentUser, setCurrentUser] = useState(-1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [search, setSearch] = useState("");
  const [switchId, setSwitchId] = useState(null);
  const [status, setStatus] = useState();
  const [checked, setChecked] = useState(true);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [buyerId, setBuyerId] = useState(null);

  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);

  const handleChangePage = (e, value) => {
    setPage(value);
    getBuyerApiCall(value);
  };
  const [addUser, setAddUser] = useState(false);
  const [openBuyerDetail, setOpenBuyerDetail] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const openUserModal = () => {
    setAddUser(true);
  };
  const closeUserModal = () => {
    setAddUser(false);
  };

  const handelopenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleCloseBuyerDetail = () => {
    setOpenBuyerDetail(false);
  };

  const openBuyerDetailModal = () => {
    setOpenBuyerDetail(true);
  };

  const handleChangeStatus = async (id) => {
    const payload = {
      status: checked ? "approved" : "inactive",
      userId: id,
    };

    const res = await changeBuyerStatus(payload);
    if (res && res.status === true) {
      getBuyerApiCall(page);
      handleCloseSwitch();
    }
  };

  const getBuyerApiCall = async (page) => {
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }

    let queryString = `?page=${page}&pagePerSize=${pagePerSize}&search=${search}${string}`;
    startBackdrop();
    const res = await GetBuyersList(queryString);
    if (res && res.status === true) {
      setBuyerList(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setBuyerList([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
    stopBackdrop();
    setFilter(false);
  };

  useEffect(() => {
    getBuyerApiCall(page);
  }, [refresh, search, pagePerSize, page, filterData]);

  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCurrentUser(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentUser(-1);
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const onFilterSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  useEffect(() => {
    handleClose();
  }, [buyerList]);

  const close = () => {
    handleClose();
  };

  const exportData = async () => {
    window.location.href = "https://pressemporium.mobiginie.com/api/v1/userCSV";
  };

  return (
    <>
      <BackdropComponent open={backdropState} />
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        {/* search bar and filter button */}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "40px",
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                setSearch(e.target.value);
                setPage(1);
              }}
            />
          </Search>
          <div>
            <Button
              sx={{
                border: "1px solid rgba(15, 15, 15, 0.15)",
                borderRadius: "10px",
                mr: 2,
                padding: "2px 20px",
                color: "black",
                fontFamily: "Work Sans",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "34px",
              }}
              endIcon={
                <IconButton sx={{ ml: 1 }}>
                  <ImportExportIcon />
                </IconButton>
              }
              onClick={exportData}
            >
              Export CSV
            </Button>
            <Button
              sx={{
                border: "1px solid rgba(15, 15, 15, 0.15)",
                borderRadius: "10px",
                mr: 2,
                padding: "5px 20px",
                color: "black",
                fontFamily: "Work Sans",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "34px",
              }}
              endIcon={
                <IconButton sx={{ ml: 3 }}>
                  <img src={filterIcon} alt="Filter Options" />
                </IconButton>
              }
              onClick={openFilterDrawer}
            >
              Filter
            </Button>
          </div>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form onSubmit={onFilterSubmit}>
            <Box
              sx={{
                width: 220,
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={{ fontWeight: "bold" }}>Filter</Typography>
                <IconButton
                  sx={{ color: "black" }}
                  onClick={closeFilterDrawer1}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="name">Customer Name </StyledFormLabel>
                <StyledTextField
                  placeholder="Customer Name"
                  fullWidth
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                />
              </Box>
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="email">Email </StyledFormLabel>
                <StyledTextField
                  placeholder="Customer Email"
                  fullWidth
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                />
              </Box>
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="mobile">
                  Mobile Number{" "}
                </StyledFormLabel>
                <StyledTextField
                  placeholder="Enter Mobile Number"
                  fullWidth
                  id="mobile"
                  name="mobile"
                  value={values.mobile}
                  onChange={handleInputChange}
                />
              </Box>
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="mobile">Status </StyledFormLabel>
                <select
                  id="status"
                  name="status"
                  value={values.status}
                  onChange={handleInputChange}
                  style={{
                    width: "100%",
                    height: "45px",
                    border: "1px solid #0F0F0F80",
                    borderRadius: "10px",
                    padding: "10px",
                    backgroundColor: "white",
                    fontSize: "14px",
                  }}
                >
                  <option>Select Status</option>
                  <option value="1">Active</option>
                  <option value="2">InActive</option>
                  <option value="3">Delete</option>
                </select>
              </Box>
            </Box>
            <Stack
              sx={{ margin: "80px 20px 10px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    // backgroundColor: "#60579A",
                  },
                  fontFamily: "Work Sans",
                  fontStyle: "normal",
                  fontWeight: 400,
                  top: "58px",
                }}
                onClick={closeFilterDrawer}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: "#60579A",
                  color: "white",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#60579A",
                  },
                  fontFamily: "Work Sans",
                  fontStyle: "normal",
                  fontWeight: 400,
                  top: "58px",
                }}
                type="submit"
              >
                Filter
              </Button>
            </Stack>
          </form>
        </Drawer>
        <TableContainer component={Paper} sx={{ minHeight: "300px" }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Mobile Number</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableHead>
            <TableBody>
              {buyerList &&
                buyerList?.map((buyer, index) => (
                  <TableRow
                    key={buyer.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "34px",
                    }}
                    onClick={() => {
                      setBuyerId(buyer.id);
                      openBuyerDetailModal();
                      // handleViewBuyer();
                    }}
                    hover
                  >
                    <TableCell
                      sx={{
                        fontFamily: "Work Sans",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "34px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Stack flexDirection="row" alignItems="center">
                        <Avatar
                          component="span"
                          variant="rounded"
                          alt="user1"
                          sx={{ borderRadius: "10px" }}
                          src={
                            buyer.profile_image
                              ? buyer.profile_image
                              : DefaultImage
                          }
                        />
                        <Typography
                          sx={{
                            fontFamily: "Work Sans",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "34px",
                            ml: 2,
                          }}
                        >
                          {buyer.fname} {buyer.lname}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Work Sans",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "34px",
                      }}
                    >
                      {buyer.email}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Work Sans",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "34px",
                      }}
                    >
                      {buyer.mobile}
                    </TableCell>

                    <TableCell>
                      {" "}
                      <Switch
                        checked={buyer.isActive == 1 ? true : false}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onChange={(e) => {
                          handleSwitchModel();
                          setStatus(
                            e.target.checked == true ? "Active" : "Inactive"
                          );
                          setSwitchId(buyer.id);
                          setChecked(e.target.checked);
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();

                          const currentState = buyerList.find(
                            (state) => state.id === buyer.id
                          );
                          handleClick(e, currentState);
                          setBuyerId(buyer.id);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}{" "}
              {buyerList?.length === 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="7"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
              <Menu
                sx={{ mt: 0.3 }}
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  sx={{ mt: 1 }}
                  onClick={() => {
                    openBuyerDetailModal();
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <img src={eye} alt="View Details" />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "30px",
                    }}
                  >
                    View Details
                  </ListItemText>
                </MenuItem>
                <MenuItem
                  sx={{ mt: 1 }}
                  onClick={() => {
                    // navigate(`/products/edit`, {
                    //   state: {
                    //     id: currentUser.id,
                    //   },
                    // });
                    openUserModal();
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <img src={edit} alt="Edit" />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "30px",
                    }}
                  >
                    Edit
                  </ListItemText>
                </MenuItem>
                <MenuItem
                  sx={{ mt: 1 }}
                  onClick={(e) => {
                    // openDeleteModal();
                    handleClose();
                    handelopenDeleteModal();
                  }}
                >
                  <ListItemIcon>
                    <img src={deleteIcon} alt="Delete" />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "30px",
                    }}
                  >
                    Delete
                  </ListItemText>
                </MenuItem>
              </Menu>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Modal
        open={openSwitchModel}
        // onClose={handleClose}
        disableAutoFocus={true}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            bgcolor: "#FFFFFF",
            padding: "20px",
            borderRadius: "10px",
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "15px",
            lineHeight: "30px",
          }}
        >
          <p className="modal-title"> Update Status </p>
          <p style={{ fontSize: "16px" }}>
            Are you sure you want to Update Status ?
          </p>

          <br />
          <div className="form-button-container">
            <button className="form-action-button" onClick={handleCloseSwitch}>
              Cancel
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              className="form-action-button-submit"
              onClick={() => handleChangeStatus(switchId)}
            >
              Yes
            </button>
          </div>
        </Box>
      </Modal>

      <UpdateBuyer
        open={addUser}
        setOpen={setAddUser}
        handleClickOpen={openUserModal}
        handleClose={closeUserModal}
        refreshScreen={refreshScreen}
        buyerId={buyerId}
      />

      <BuyerDetail
        open={openBuyerDetail}
        setOpen={setOpenBuyerDetail}
        buyerId={buyerId}
        handleClose={handleCloseBuyerDetail}
      />

      <BuyerDelete
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        buyerId={buyerId}
        handleClose={handleCloseDeleteModal}
        refreshScreen={refreshScreen}
      />

      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div
          sx={{
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
};

export default BuyersList;
