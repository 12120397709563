import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
  Visibility as VisibilityIcon,
  Sort as SortIcon,
} from "@mui/icons-material";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  InputBase,
  Grid,
  Button,
  Typography,
  Avatar,
  IconButton,
  Stack,
  Switch,
  Pagination,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
} from "@mui/material";
import edit from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import { tableCellClasses } from "@mui/material/TableCell";
import {
  getCategory,
  categortStatusChange,
} from "../../state/actions/categoryAction";

//  filter icons
import filterIcon from "../../assets/filter.svg";
//  modals with respect to menu
import CAtegoryEditModal from "./CategoryAdd";
import CategoryDeleteModal from "./CategoryDelete";

//  dipatch category actions
import { useForm } from "../../utils/useForm";
import BackdropComponent from "../backdrop/backdrop";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgb(241 241 241)",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "34px",
    color: "rgba(15, 15, 15, 0.5)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    // hover color add
    "&:hover": {
      color: "#60579A",
    },
  },
}));

const StyledTableColumns = styled(Box)(({ theme }) => ({
  //set height of table columns
  display: "inline-flex",
  "&:hover": {
    cursor: "pointer",
    ".header-icon": {
      visibility: "visible",
    },
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontFamily: "Work Sans",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "34px",
  color: "#0F0F0F",
}));

const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "45px",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "34px",
  },
}));

const initialValues = {
  name: "",
  status: "",
};

const CategoryList = (props) => {
  const { refreshScreen, refresh } = props;

  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [filterData, setFilterData] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [backdropState, setBackdropState] = useState(false);
  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentCategory, setcurrentCategory] = useState({});
  const [filter, setFilter] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [sortFlag, setSortFlag] = useState(false);
  const [flagName, setflagName] = useState(false);
  const [sortBy, setSortBy] = useState("");

  const stopBackdrop = () => {
    setBackdropState(false);
  };
  const startBackdrop = () => {
    setBackdropState(true);
  };

  const openConfirmDialog = () => {
    setConfirmDialog(true);
  };
  const closeConfirmDialog = () => {
    setConfirmDialog(false);
    // resetForm();
  };

  const handleChange = async (event, id) => {
    // openStatusModal();
    openConfirmDialog();
    setCategoryId(id);
  };

  const getCategoryList = async (page, name = "", flag) => {
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }

    let queryString = `?page=${page}&pagePerSize=${pagePerSize}&search=${search}${string}&sortby=${name}&sortFlag=${flag}`;

    startBackdrop();
    const res = await getCategory(queryString);
    if (res && res.status === true) {
      setSortBy(name);
      setCategoryList(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
      stopBackdrop();
      setSortFlag(!sortFlag);
    } else {
      setCategoryList([]);
      setTotalRecords(0);
      setTotalPages(0);
      setSortFlag(!sortFlag);
      stopBackdrop();
    }
    setFilter(false);
  };

  const handleChangePage = (event, value) => {
    getCategoryList(value);
    setPage(value);
  };

  const changeStatus = async () => {
    startBackdrop();
    const res = await categortStatusChange(categoryId);
    if (res && res.status === true) {
      closeConfirmDialog();
      refreshScreen();
    } else {
      closeConfirmDialog();
      refreshScreen();
    }
    stopBackdrop();
  };

  useEffect(() => {
    getCategoryList(page);
  }, [refresh, filterData, page, search]);

  const open = Boolean(anchorEl);
  const handleClick = (event, value) => {
    setAnchorEl(event.currentTarget);
    setcurrentCategory(value);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setcurrentCategory({});
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };
  const onFilterSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true);

  const handelEditCategory = () => {
    setEditCategory(true);
  };
  const closeEditCategory = () => {
    setEditCategory(false);
  };

  const handelDeletecategory = () => {
    setDeleteCategory(true);
  };
  const closeDeleteCategory = () => {
    setDeleteCategory(false);
  };

  useEffect(() => {
    handleClose();
  }, [categoryList]);

  const dataShorting = (name) => {
    if (name === "name") {
      setflagName(!flagName);
      getCategoryList(page, name, !flagName);
    } else {
      setflagName(false);
      setSortFlag(!sortFlag);
    }
  };

  return (
    <>
      <BackdropComponent open={backdropState} />
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        {/* search bar and filter button */}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "40px",
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                setSearch(e.target.value);
                setPage(1);
              }}
            />
          </Search>

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              color: "black",
              fontFamily: "Work Sans",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "34px",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            Filter
          </Button>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form onSubmit={onFilterSubmit}>
            <Box
              sx={{
                width: 220,
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={{ fontWeight: "bold" }}>Filter</Typography>
                <IconButton
                  sx={{ color: "black" }}
                  onClick={closeFilterDrawer1}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="name">Name </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Name"
                  fullWidth
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />

              <Grid item md={4} xs={12}>
                <StyledFormLabel htmlFor="status">Status</StyledFormLabel>
                <StyledTextField
                  id="status"
                  name="status"
                  fullWidth
                  select
                  value={values.status}
                  //how to add place holder
                  onChange={handleInputChange}
                  error={Boolean(errors.status)}
                  helperText={errors.status}
                >
                  <MenuItem key="1" value="2">
                    Inactive
                  </MenuItem>
                  <MenuItem key="2" value="1">
                    Active
                  </MenuItem>
                  <MenuItem key="2" value="3">
                    Delete
                  </MenuItem>
                </StyledTextField>
              </Grid>
            </Box>
            <Stack
              sx={{ margin: "410px 20px 10px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    // backgroundColor: "#60579A",
                  },
                  fontFamily: "Work Sans",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
                onClick={closeFilterDrawer}
              >
                Reset
              </Button>
              <Button
                sx={{
                  backgroundColor: "#60579A",
                  color: "white",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#60579A",
                  },
                  fontFamily: "Work Sans",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
                type="submit"
              >
                Filter
              </Button>
            </Stack>
          </form>
        </Drawer>
        <TableContainer component={Paper} sx={{ minHeight: "300px" }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell onClick={() => dataShorting("name")}>
                  Name
                  {flagName ? (
                    <ArrowUpwardIcon
                      style={{
                        fontSize: "1rem",
                        position: "relative ",
                        top: "2px ",
                        left: "3px ",
                        cursor: "pointer ",
                      }}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      style={{
                        fontSize: "1rem",
                        position: "relative ",
                        top: "2px ",
                        left: "3px ",
                        cursor: "pointer ",
                      }}
                    />
                  )}
                </StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categoryList.map((row, index) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  hover
                >
                  <TableCell component="th" scope="row">
                    <span
                      style={{
                        fontFamily: "Work Sans",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "34px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Avatar src={row.image} />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {row.name}
                    </span>
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={row.isActive == 1 ? true : false}
                      onChange={(e) => {
                        handleChange(e, row.id);
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      name="checkedB"
                      color="primary"
                      id={row.id}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={(e) => {
                        const currentCategory = categoryList.find(
                          (category) => category.id === row.id
                        );
                        handleClick(e, currentCategory);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}{" "}
              {categoryList?.length === 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="7"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          sx={{ mt: 0.3 }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              handelEditCategory();
              //set edit category name
            }}
          >
            <ListItemIcon>
              <img src={edit} alt="Edit" />
            </ListItemIcon>
            <ListItemText
              sx={{
                fontFamily: "Work Sans",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "30px",
              }}
            >
              Edit
            </ListItemText>
          </MenuItem>
          <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              handelDeletecategory();
            }}
          >
            <ListItemIcon>
              <img src={deleteIcon} alt="Delete" />
            </ListItemIcon>
            <ListItemText
              sx={{
                fontFamily: "Work Sans",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "30px",
              }}
            >
              Delete
            </ListItemText>
          </MenuItem>
        </Menu>

        <Dialog
          id="confirm-dialog"
          open={confirmDialog}
          onClose={closeConfirmDialog}
          sx={{
            "&#confirm-dialog .MuiPaper-root": {
              borderRadius: "15px",
            },
          }}
        >
          <DialogContent
            sx={{
              borderRadius: "10px",
              fontFamily: "Work Sans",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "15px",
              lineHeight: "30px",
            }}
          >
            Are you sure you want to Change status?
          </DialogContent>
          <DialogActions
            sx={{
              margin: "15px",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <Button className="cancel-button" onClick={closeConfirmDialog}>
              Cancel
            </Button>
            <Button
              className="button"
              onClick={() => {
                // closeConfirmDialog();
                // close();
                changeStatus();
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <CAtegoryEditModal
          category={currentCategory}
          open={editCategory}
          setOpen={setEditCategory}
          handleClose={() => {
            handleClose();
            closeEditCategory();
          }}
          refreshScreen={refreshScreen}
        />
        <CategoryDeleteModal
          category={currentCategory}
          open={deleteCategory}
          text="category"
          handleClose={() => {
            handleClose();
            closeDeleteCategory();
          }}
          refreshScreen={refreshScreen}
        />
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
};

export default CategoryList;
