import { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { Box } from "@mui/material";
import CategoryList from "./CategoryList";
import AddCategoryModal from "./CategoryAdd";
import "./Category.css";

const Category = () => {
  const [refresh, setRefresh] = useState(false);
  const refreshScreen = () => {
    setRefresh(!refresh);
  };
  const [addCategory, setAddCategory] = useState(false);
  const openCategoryModel = () => {
    setAddCategory(true);
  };
  const closeCategoryModel = () => {
    setAddCategory(false);
  };
  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "32px",
            color: "#0F0F0F",
          }}
        >
          Category
        </Typography>
        <Button
          className="button"
          onClick={openCategoryModel}
          startIcon={<Add />}
          color="primary"
        >
          Category
        </Button>
      </Stack>
      <AddCategoryModal
        open={addCategory}
        setOpen={setAddCategory}
        handleClickOpen={openCategoryModel}
        handleClose={closeCategoryModel}
        refreshScreen={refreshScreen}
      />
      <Box sx={{ mt: 4 }}>
        <CategoryList refresh={refresh} refreshScreen={refreshScreen} />
      </Box>
    </>
  );
};
export default Category;
