import { useState } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  InputAdornment,
} from "@mui/material";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { TextField, IconButton, Tooltip, Button, Stack } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { styled, Card, CardContent, useMediaQuery } from "@mui/material";
import RectangleGlass from "../../assets/Rectangle_glass.png";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import BackdropComponent from "../backdrop/backdrop";

import { forgotPassword } from "../../state/actions/authActions";

const StyledLeftSide = styled("div")(() => ({
  borderRadius: "10px",
  backgroundColor: "#60579A",
  height: "88vh",
  position: "relative",
}));
const StyledRightSide = styled("div")(() => ({
  borderRadius: "10px",
  height: "88vh",
}));
const StyledLabel = styled("label")(() => ({
  display: "inline-block",
  fontWeight: "500",
  fontSize: "14px",
  mt: 1,
  borderRadius: "8px",
}));
const StyledLogin = styled(Button)(() => ({
  backgroundColor: "#60579A",
  borderRadius: "10px",
  color: "white",
  fontSize: "16px",
  padding: "8px 20px",
  "&:hover": {
    backgroundColor: "#60579A",
    color: "white",
  },
}));
const StyledBack = styled(Button)(() => ({
  backgroundColor: "white",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  color: "black",
  fontSize: "16px",
  padding: "8px 20px",
  fontWeight: "bold",
  "&:hover": {},
}));
const ForgetPassword = () => {
  const lg = useMediaQuery("@media (max-width:900px)");
  const xs = useMediaQuery("@media (max-width:600px)");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [showSendEmail, setShowSendEmail] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const { enqueueSnackbar } = useSnackbar();
  //  backdrop states
  const [backdropState, setBackdropState] = useState(false);
  const stopBackdrop = () => {
    setBackdropState(false);
  };
  const startBackdrop = () => {
    setBackdropState(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validator.isEmail(email)) {
      return enqueueSnackbar("Invalid Email !", { variant: "error" });
    }
    startBackdrop();
    const response = await dispatch(forgotPassword({ email }));
    if (response && response.success === true) {
      setShowSendEmail(true);
      enqueueSnackbar(response.message, { variant: "success" });
    } else if (response && response.success === false) {
      enqueueSnackbar(
        response.message || "Unexpected error occured please try again later.",
        { variant: "success" }
      );
    }
    stopBackdrop();
  };
  return (
    <div>
      <BackdropComponent open={backdropState} />
      <Grid
        container
        spacing={1}
        sx={{ backgroundColor: "#F8F8F8;", height: "100vh" }}
      >
        <Grid item lg={5} md={5} sm={5} xs={12} sx={{ margin: "35px 20px" }}>
          <StyledLeftSide>
            <Box
              sx={{
                position: "absolute",
                borderRadius: "10px",
                height: "-webkit-fill-available",
                backgroundColor: "rgba(255, 255, 255, 0.2);",
                margin: lg ? "20px" : "40px",
                color: "white",
                padding: lg ? "0px 15px" : "0px 50px",
              }}
            >
              <h1>Welcome to Press Emporium</h1>
              <p>
                “Our approach to sourcing is simple… We offer the finest quality
                materials and a final product that delivers with impact !”
              </p>
            </Box>
            <img
              src={RectangleGlass}
              style={{ width: "100%", height: "100%" }}
              alt="Login Background"
            />
          </StyledLeftSide>
        </Grid>
        <Grid item lg={5} md={5} sm={5} xs={12} sx={{ margin: "35px 20px" }}>
          <StyledRightSide>
            <Container sx={xs ? {} : { pt: "20%" }}>
              {showSendEmail ? (
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="div" gutterbottom>
                      Email sent
                    </Typography>
                    <Typography variant="body2">
                      we sent an email to {email} with a link to reset password
                    </Typography>
                  </CardContent>
                </Card>
              ) : (
                <>
                  <Box sx={{ mb: 3, mt: 7 }}>
                    <Typography
                      component={"h4"}
                      sx={{
                        display: "inline-block",
                        fontSize: "36px",
                        fontWeight: "600",
                      }}
                    >
                      Forgot your password?
                    </Typography>
                    <br />
                    <Typography
                      sx={{
                        display: "inline-block",
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                    >
                      Please enter the email address associated with your
                      account and We will email you a link to reset your
                      password.
                    </Typography>
                  </Box>
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <Box sx={{ mt: 2 }}>
                      <StyledLabel>Email</StyledLabel>
                      <TextField
                        name="email"
                        value={email}
                        onChange={handleEmailChange}
                        sx={{ mt: 1, borderRadius: "8px" }}
                        size="small"
                        fullWidth
                      />
                    </Box>
                    <Stack flexDirection="row" gap={3}>
                      <StyledLogin sx={{ mt: 3 }} type="submit">
                        Send Request
                      </StyledLogin>
                      <StyledBack
                        onClick={() => {
                          navigate("/login");
                        }}
                        sx={{ mt: 3 }}
                      >
                        Back
                      </StyledBack>
                    </Stack>
                  </form>
                </>
              )}
            </Container>
          </StyledRightSide>
        </Grid>
      </Grid>
    </div>
  );
};
export default ForgetPassword;
