import React, { useState } from "react";
import { styled, useTheme, alpha } from "@mui/material/styles";
import { Box } from "@mui/material";

import { ListItemIcon, ListItemText } from "@mui/material";
import {
  Search as SearchIcon,
  Notifications as NotificationsIcon,
  ExpandMore,
  ExpandLess,
  Logout as LogoutIcon,
} from "@mui/icons-material";

import {
  AppBar,
  Toolbar,
  InputBase,
  IconButton,
  Badge,
  Button,
  Avatar,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../state/actions/authActions";



const Header = (props) => {
  const navigate = useNavigate();
  const token = useSelector((store) => store.auth.token);
  const user = useSelector((store) => store.auth.user);
  if (!token) {
    navigate("/login");
  }
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="static"
      sx={{
        mt: 2,
        backgroundColor: "transparent",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ paddingLeft: "5px !important" }}>
       
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: "flex" }}>
         

          <Button
            sx={{
              backgroundColor: "white",
              borderRadius: "10px",
              color: "black",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "white",
              },
            }}
            onClick={handleClick}
          >
            <Avatar
              sx={{ mx: 0.5 }}
              alt="User Profile"
              src={user?.profile_image}
            />
            <Typography sx={{ mx: 0.5 }}>{user?.name}</Typography>
            {open ? (
              <ExpandLess sx={{ mx: 0.5 }} />
            ) : (
              <ExpandMore sx={{ mx: 0.5 }} />
            )}
          </Button>
          <Menu
            sx={{
              mt: 0.3,
              "&#user-profile .MuiPaper-root ": {
                width: "190px",
              },
            }}
            id="user-profile"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {/* <MenuItem sx={{ mt: 1 }} onClick={handleClose}>
              <ListItemIcon>
                <img src={eye} alt="View Details" />
              </ListItemIcon>
              <ListItemText>View Details</ListItemText>
            </MenuItem>
            <MenuItem sx={{ mt: 1 }} onClick={handleClose}>
              <ListItemIcon>
                <img src={edit} alt="Edit" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem> */}

            <MenuItem sx={{ mt: 1 }} onClick={() => dispatch(logout())}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
