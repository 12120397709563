import axiosRequest from "../axios/axios";

export const addCountry = async (data) => {
  try {
    const res = await axiosRequest.post(`/api/v1/addCountry`, data);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
};

export const getCountry = async (queryString) => {
  try {
    const res = await axiosRequest.get(`/api/v1/countrylist${queryString}`);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
};

export const getState = async (queryString) => {
  try {
    const res = await axiosRequest.get(`/api/v1/statelist${queryString}`);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
};

export const getCities = async (queryString, id) => {
  try {
    const res = await axiosRequest.get(`/api/v1/citylist/${id}` + queryString);
    return res.data;
  } catch (err) {
    // return err.res.data;
  }
};

export const UpdateCountry = async (data, id) => {
  try {
    const res = await axiosRequest.post(`/api/v1/updateCountry/${id}`, data);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
};

export const deleteCountry = async (id) => {
  try {
    const res = await axiosRequest.delete(`/api/v1//deleteCountry/${id}`);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
};

// status change

export const countrytStatusChange = async (id) => {
  try {
    const res = await axiosRequest.post(`/api/v1/changeCountryStatus/${id}`);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
};

export const stateStatusChanges = async (id) => {
  try {
    const res = await axiosRequest.post(`/api/v1/stateStatusChange/${id}`);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
};

export const cityStatusChanges = async (id) => {
  try {
    const res = await axiosRequest.get(`/api/v1/statusChange/${id}`);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
};
