import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
  Visibility as VisibilityIcon,
  Sort as SortIcon,
} from "@mui/icons-material";
import moment from "moment";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  InputBase,
  Grid,
  Button,
  Typography,
  Avatar,
  IconButton,
  Stack,
  Switch,
  Pagination,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
  Chip,
  Tab,
} from "@mui/material";
import edit from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import { tableCellClasses } from "@mui/material/TableCell";

import {
  getOrder,
  supplierOrderCreates,
} from "../../state/actions/orderAction";

//  filter icons
import filterIcon from "../../assets/filter.svg";
//  modals with respect to menu

//  dipatch category actions
import { useForm } from "../../utils/useForm";
import BackdropComponent from "../backdrop/backdrop";
import { Navigate, useNavigate } from "react-router-dom";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { TabList } from "@material-ui/lab";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgb(241 241 241)",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "34px",
    color: "rgba(15, 15, 15, 0.5)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    // hover color add
    "&:hover": {
      color: "#60579A",
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#0957dd0a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableColumns = styled(Box)(({ theme }) => ({
  //set height of table columns
  display: "inline-flex",
  "&:hover": {
    cursor: "pointer",
    ".header-icon": {
      visibility: "visible",
    },
  },
}));

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontFamily: "Work Sans",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "34px",
  color: "#0F0F0F",
}));

const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "45px",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "34px",
  },
}));

const initialValues = {
  stateName: "",
  status: "",
  countryId: "",
};

const OrderList = (props) => {
  const { refreshScreen, refresh } = props;

  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [filterData, setFilterData] = useState(true);
  const [orderList, setOrderList] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [backdropState, setBackdropState] = useState(false);
  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentState, setcurrentState] = useState({});
  const [filter, setFilter] = useState(false);
  const [stateId, setStateId] = useState("");
  const [opens, setOpens] = useState([]);
  const [sortFlag, setSortFlag] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [flagName, setflagName] = useState(false);
  const [flagAmount, setflagAmount] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [sortBy, setSortBy] = useState("");

  const navigate = useNavigate();

  const stopBackdrop = () => {
    setBackdropState(false);
  };
  const startBackdrop = () => {
    setBackdropState(true);
  };

  const openConfirmDialog = () => {
    setConfirmDialog(true);
  };

  const closeConfirmDialog = () => {
    setConfirmDialog(false);
  };

  const handleChange = async (event, id) => {
    openConfirmDialog();
    setStateId(id);
  };

  const getOrderList = async (page, name = "", flag) => {
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }

    var localTime = moment(startDate).format("YYYY-MM-DD"); // store localTime
    var proposedDate = localTime + "T00:00:00.000Z";

    var localTimes = moment(endDate).format("YYYY-MM-DD"); // store localTime
    var proposedDates = localTimes + "T00:00:00.000Z";

    let queryString = `?page=${page}&pagePerSize=${pagePerSize}&search=${search}${string}&sortby=${name}&sortFlag=${flag}&startDate=${proposedDate}&endDate=${proposedDates}`;
    startBackdrop();
    const res = await getOrder(queryString);
    if (res && res.status === true) {
      setSortBy(name);
      setOrderList(res.data);
      setTotalRecords(res?.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
      stopBackdrop();
      setSortFlag(!sortFlag);
    } else {
      setOrderList([]);
      setTotalRecords(0);
      setTotalPages(0);
      stopBackdrop();
      setSortFlag(!sortFlag);
    }
    setFilter(false);
  };

  useEffect(() => {
    getOrderList(page);
  }, [refresh, filterData, page, search]);

  const handleChangePage = (event, value) => {
    getOrderList(value);
    setPage(value);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event, value) => {
    setAnchorEl(event.currentTarget);
    setcurrentState(value);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setcurrentState({});
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };
  const onFilterSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialValues,
    true
  );

  useEffect(() => {
    handleClose();
  }, [orderList]);

  const productStatus = (productstatus) => {
    const statusColors = {
      0: {
        key: "Closed",
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#f05252;",
      },
      1: {
        key: "Paid",
        backgroundColor: "#ede7dd",
        color: "#0e9f6e",
      },
      2: {
        key: "Pending",
        backgroundColor: "#fdf6b2",
        color: "#c27803",
      },
      3: {
        key: "Partial",
        backgroundColor: "#def7ec",
        color: "gray",
      },
    };

    return (
      <StyledChip
        style={{
          height: "35px",
          fontSize: "14px",
          marginLeft: "25px",
        }}
        sx={statusColors[productstatus]}
        label={
          statusColors[productstatus] ? statusColors[productstatus].key : ""
        }
      />
    );
  };

  const orderStatus = (orderstatus) => {
    const statusColors = {
      1: {
        key: "Pending",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      2: {
        key: "InProgress",
        backgroundColor: "#fdf6b2",
        color: "#c27803",
      },
      3: {
        key: " Ready to dispatch ",
        backgroundColor: "#def7ec",
        color: "#0e9f6e",
      },
      4: {
        key: "Shipped ",
        backgroundColor: "#def7ec",
        color: "blue",
      },
      5: {
        key: "Delivered",
        backgroundColor: "#def7ec",
        color: "green",
      },
      6: {
        key: "Cancel",
        backgroundColor: "#def7ec",
        color: "black",
      },
      7: {
        key: "Failed",
        backgroundColor: "#def7ec",
        color: "red",
      },
    };

    return (
      <StyledChip
        style={{
          height: "35px",
          marginLeft: "10px",
          fontSize: "14px",
        }}
        sx={statusColors[orderstatus]}
        label={statusColors[orderstatus] ? statusColors[orderstatus].key : ""}
      />
    );
  };

  const dataShorting = (name) => {
    if (name === "name") {
      setflagName(!flagName);
      getOrderList(page, name, !flagName);
    } else {
      setflagName(false);
      setSortFlag(!sortFlag);
    }

    if (name === "amount") {
      setflagAmount(!flagAmount);
      getOrderList(page, name, !flagAmount);
    } else {
      setflagAmount(false);
      setSortFlag(!sortFlag);
    }
  };

  return (
    <>
      <BackdropComponent open={backdropState} />
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        {/* search bar and filter button */}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "40px",
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                setSearch(e.target.value);
                setPage(1);
              }}
            />
          </Search>

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              color: "black",
              fontFamily: "Work Sans",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "34px",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            Filter
          </Button>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form onSubmit={onFilterSubmit}>
            <Box
              sx={{
                width: 220,
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={{ fontWeight: "bold" }}>Filter</Typography>
                <IconButton
                  sx={{ color: "black" }}
                  onClick={closeFilterDrawer1}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="name">Name </StyledFormLabel>
                <StyledTextField
                  placeholder="Name"
                  fullWidth
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />

              <Grid item md={4} xs={12}>
                <StyledFormLabel htmlFor="status">Status</StyledFormLabel>
                <StyledTextField
                  id="status"
                  name="status"
                  fullWidth
                  select
                  value={values.status}
                  //how to add place holder
                  onChange={handleInputChange}
                  error={Boolean(errors.status)}
                  helperText={errors.status}
                >
                  <MenuItem key="1" value="1">
                    Pending
                  </MenuItem>
                  <MenuItem key="2" value="2">
                    InProgress
                  </MenuItem>
                  <MenuItem key="3" value="3">
                    Ready to dispatch
                  </MenuItem>
                  <MenuItem key="4" value="4">
                    Shipped
                  </MenuItem>
                  <MenuItem key="5" value="5">
                    Delivered
                  </MenuItem>
                  <MenuItem key="6" value="6">
                    Cancel
                  </MenuItem>
                  <MenuItem key="7" value="7">
                    Failed
                  </MenuItem>
                </StyledTextField>
              </Grid>
            </Box>
            <Stack
              sx={{ margin: "410px 20px 10px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    // backgroundColor: "#60579A",
                  },
                  fontFamily: "Work Sans",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
                onClick={closeFilterDrawer}
              >
                Reset
              </Button>
              <Button
                sx={{
                  backgroundColor: "#60579A",
                  color: "white",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#60579A",
                  },
                  fontFamily: "Work Sans",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
                type="submit"
              >
                Filter
              </Button>
            </Stack>
          </form>
        </Drawer>
        <TableContainer component={Paper} sx={{ minHeight: "300px" }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableCell>Order Number</StyledTableCell>
              <StyledTableCell onClick={() => dataShorting("name")}>
                Name
                {flagName ? (
                  <ArrowUpwardIcon
                    style={{
                      fontSize: "1rem",
                      position: "relative ",
                      top: "2px ",
                      left: "3px ",
                      cursor: "pointer ",
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    style={{
                      fontSize: "1rem",
                      position: "relative ",
                      top: "2px ",
                      left: "3px ",
                      cursor: "pointer ",
                    }}
                  />
                )}
              </StyledTableCell>

              <StyledTableCell onClick={() => dataShorting("amount")}>
                Amount
                {flagAmount ? (
                  <ArrowUpwardIcon
                    style={{
                      fontSize: "1rem",
                      position: "relative ",
                      top: "2px ",
                      left: "3px ",
                      cursor: "pointer ",
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    style={{
                      fontSize: "1rem",
                      position: "relative ",
                      top: "2px ",
                      left: "3px ",
                      cursor: "pointer ",
                    }}
                  />
                )}
              </StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Order Status</StyledTableCell>
              <StyledTableCell>Payment Status</StyledTableCell>
            </TableHead>
            <TableBody>
              {orderList.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => {
                    navigate("/orders/ordersdetails", {
                      state: row.id,
                    });
                  }}
                  style={{ cursor: "pointer" }}
                  hover
                >
                  <TableCell
                    sx={{
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "34px",
                    }}
                  >
                    {row?.orderNumber}
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "34px",
                    }}
                  >
                    {row?.fname + " " + row?.lname}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "34px",
                    }}
                  >
                    {row?.amount}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "34px",
                    }}
                  >
                    {moment(row.createdAt).format("MMM DD, YYYY, h:mm:ss a")}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "34px",
                    }}
                  >
                    {orderStatus(row.status)}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "34px",
                    }}
                  >
                    {productStatus(row.paymentStatus)}
                  </TableCell>
                  {/*
                  <TableCell onClick={(e) => e.stopPropagation()}>
                    <AssignmentIndIcon
                      variant="contained"
                      onClick={() => {
                        navigate("/orders/ordersdetails/supplier", {
                          state: row.id,
                        });
                      }}
                      size="small"
                      sx={{ marginLeft: "20px" }}
                    />
                    </TableCell> */}
                </TableRow>
              ))}{" "}
              {orderList?.length === 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                      fontFamily: "Work Sans",
                    }}
                    colSpan="7"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          sx={{ mt: 0.3 }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        ></Menu>

        <Dialog
          id="confirm-dialog"
          open={confirmDialog}
          onClose={closeConfirmDialog}
          sx={{
            "&#confirm-dialog .MuiPaper-root": {
              borderRadius: "15px",
            },
          }}
        >
          <DialogContent>Are you sure you want to Change status?</DialogContent>
          <DialogActions
            sx={{
              margin: "15px",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <Button className="cancel-button" onClick={closeConfirmDialog}>
              Cancel
            </Button>
            <Button className="button" onClick={() => {}}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
};

export default OrderList;
