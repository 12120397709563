import { Box } from "@mui/material";
import { Routes, Route } from "react-router-dom";

import Dashboard from "../dashboardPage/dashboard";
import Admin from "../admin/Admin";
import Buyer from "../buyer/Buyer";
import PESuppliers from "../PESupplier/PESupplier";
import ASISupplier from "../ASISupplier/ASISupplier";
import Roles from "../roles/Roles";
import Countries from "../countries/Countries";
import State from "../countries/State";
import City from "../countries/City";
import Products from "../products/Products";
import AddProduct from "../products/AddProduct";
import ProductDetails from "../products/ProductDetail";
import Category from "../category/Category";
import Setting from "../setting/setting";
import Payment from "../payments/payment";
import AdminSupplierOrder from "../order/AdminSupplierOrder";
import Order from "../order/Order";
import SupplierOrder from "../order/SupplierOrder";

import OrderDetails from "../order/OrderDetails";
import OrderDetailsSupplier from "../order/OrderDetailsSupplier";
import SupplierOrderDetails from "../order/SupplierOrderDetails";
import User from "../user/User";
import UserDetails from "../user/UserDetails";
import SubCategory from "../subcategory/SubCategory";
import OrderInvoice from "../order/OrderInvoice";
import AdminSupplierOrderDetails from "../order/AdminSupplierOrderDetails";
import AdminSupplierOrders from "../order/AdminSupplierOrders";
import AdminSupplierOrdersDetails from "../order/AdminSupplierOrdersDetails";
import ProductSupplier from "../products/ProductSupplier";
import AddProductSupplier from "../products/AddProductSupplier";
import ProductDetailSupplier from "../products/ProductDetailSupplier";

const Main = (props) => {
  return (
    <Box
      component="main"
      sx={{ backgroundColor: "revert", mt: 2, padding: "0px 25px 10px 30px" }}
    >
      <Routes>
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/admin" element={<Admin />} />
        <Route exact path="/buyer" element={<Buyer />} />
        <Route exact path="/pesuppliers" element={<PESuppliers />} />
        <Route exact path="/asisupplier" element={<ASISupplier />} />
        <Route exact path="/roles" element={<Roles />} />
        <Route exact path="/category" element={<Category />} />
        <Route exact path="/subcategory" element={<SubCategory />} />
        <Route exact path="/countries" element={<Countries />} />
        <Route exact path="/states" element={<State />} />
        <Route exact path="/address/cities" element={<City />} />
        <Route exact path="/products" element={<Products />} />
        <Route exact path="/products/new" element={<AddProduct />} />
        <Route exact path="/products/edit" element={<AddProduct />} />
        <Route exact path="/products/details" element={<ProductDetails />} />
        <Route exact path="/productsupplier" element={<ProductSupplier />} />
        <Route
          exact
          path="/productsupplier/new"
          element={<AddProductSupplier />}
        />
        <Route
          exact
          path="/productsupplier/edit"
          element={<AddProductSupplier />}
        />
        <Route
          exact
          path="/productsupplier/details"
          element={<ProductDetailSupplier />}
        />
        <Route exact path="/setting" element={<Setting />} />
        <Route exact path="/payment" element={<Payment />} />
        <Route
          exact
          path="/adminSupplierOrder"
          element={<AdminSupplierOrder />}
        />
        <Route
          exact
          path="/adminSupplierOrderList"
          element={<AdminSupplierOrders />}
        />

        <Route exact path="/orders" element={<Order />} />
        <Route exact path="/supplierOrder" element={<SupplierOrder />} />

        <Route exact path="/orders/ordersdetails" element={<OrderDetails />} />

        <Route
          exact
          path="/orders/ordersdetails/supplier"
          element={<OrderDetailsSupplier />}
        />
        <Route
          exact
          path="/orders/adminsupplierorderdetails"
          element={<AdminSupplierOrderDetails />}
        />
        <Route
          exact
          path="/orders/adminsupplierordersdetails"
          element={<AdminSupplierOrdersDetails />}
        />

        <Route
          exact
          path="/orders/supplierorderdetails"
          element={<SupplierOrderDetails />}
        />
        <Route exact path="/orders/invoice" element={<OrderInvoice />} />
        <Route exact path="/user/userdetails" element={<UserDetails />} />
      </Routes>
    </Box>
  );
};

export default Main;
