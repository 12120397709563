import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    Grid,
    IconButton,
    TextField,
    FormLabel
  } from "@mui/material";
  import { styled } from "@mui/material/styles";
  import { Close as CloseIcon} from "@mui/icons-material";
  
  const StyledFormLabel = styled(FormLabel)(() => ({
    display: "block",
    fontWeight: "bold",
    color: "rgba(15, 15, 15, 0.5)",
  }));
  const StyledTextField = styled(TextField)(() => ({
    marginTop: "10px",
    ".MuiInput-root": {
      paddingBottom: "20px",
    },
  }));
  
  const SupplierDetails = (props) => {
    const { user, open, handleClose } = props;
  
    return (
      <div>
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
          <DialogTitle
            sx={{
              fontWeight: "bold",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Supplier Details
            <Tooltip title="Close">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </DialogTitle>
  
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} container spacing={1}>
                <>
                  <Grid item sx={{ m: 1 }} sm={5.5} xs={12}>
                    <StyledFormLabel htmlFor="name">Name </StyledFormLabel>
                    <StyledTextField
                      inputProps={{ readOnly: true }}
                      variant="standard"
                      fullWidth
                      id="name"
                      name="name"
                      defaultValue={user?.name}
                    />
                  </Grid>
                  <Grid item sx={{ m: 1 }} sm={5.5} xs={12}>
                    <StyledFormLabel htmlFor="email">Email </StyledFormLabel>
                    <StyledTextField
                      inputProps={{ readOnly: true }}
                      variant="standard"
                      fullWidth
                      id="email"
                      name="email"
                      defaultValue={user?.email}
                    />
                  </Grid>
                  <Grid item sx={{ m: 1 }} sm={5.5} xs={12}>
                    <StyledFormLabel htmlFor="asiNumber">
                      ASI Number
                    </StyledFormLabel>
                    <StyledTextField
                      inputProps={{ readOnly: true }}
                      variant="standard"
                      fullWidth
                      id="asiNumber"
                      name="asiNumber"
                      defaultValue={user?.asiNumber}
                    />
                  </Grid>
                  <Grid item sx={{ m: 1 }} sm={5.5} xs={12}>
                    <StyledFormLabel htmlFor="country">Country</StyledFormLabel>
                    <StyledTextField
                      inputProps={{ readOnly: true }}
                      variant="standard"
                      fullWidth
                      id="country"
                      defaultValue={user?.country}
                    />
                  </Grid>
                  <Grid item sx={{ m: 1 }} sm={5.5} xs={12}>
                    <StyledFormLabel htmlFor="state">State</StyledFormLabel>
                    <StyledTextField
                      inputProps={{ readOnly: true }}
                      variant="standard"
                      fullWidth
                      id="state"
                      defaultValue={user?.state}
                    />
                  </Grid>
  
                  <Grid item sx={{ m: 1 }} sm={5.5} xs={12}>
                    <StyledFormLabel htmlFor="city">City</StyledFormLabel>
                    <StyledTextField
                      inputProps={{ readOnly: true }}
                      variant="standard"
                      fullWidth
                      id="city"
                      defaultValue={user?.city}
                    />
                  </Grid>
  
                  <Grid item sx={{ m: 1 }} sm={5.5} xs={12}>
                    <StyledFormLabel htmlFor="city">Supplier ID</StyledFormLabel>
                    <StyledTextField
                      inputProps={{ readOnly: true }}
                      variant="standard"
                      fullWidth
                      id="supplierId"
                      defaultValue={user?.supplierId}
                    />
                  </Grid>
                  <Grid item sx={{ m: 1 }} sm={5.5} xs={12}>
                    <StyledFormLabel htmlFor="address">Mobile</StyledFormLabel>
                    <StyledTextField
                      inputProps={{ readOnly: true }}
                      variant="standard"
                      fullWidth
                      id="mobile"
                      defaultValue={user?.workPhone}
                    />
                  </Grid>
                  <Grid item sx={{ m: 1 }} sm={5.5} xs={12}>
                    <StyledFormLabel htmlFor="zip">Zip</StyledFormLabel>
                    <StyledTextField
                      inputProps={{ readOnly: true }}
                      variant="standard"
                      fullWidth
                      id="zip"
                      defaultValue={user?.zip}
                    />
                  </Grid>
                  <Grid item sx={{ m: 1 }} sm={5.5} xs={12}>
                    <StyledFormLabel htmlFor="address">Address</StyledFormLabel>
                    <StyledTextField
                      inputProps={{ readOnly: true }}
                      variant="standard"
                      fullWidth
                      id="address"
                      defaultValue={user?.street1}
                    />
                  </Grid>
                </>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ margin: "15px" }}>
            <Button
              sx={{
                backgroundColor: "#60579A",
                color: "white",
                borderRadius: "10px",
                padding: "10px 25px",
                "&:hover": {
                  backgroundColor: "#60579A",
                },
              }}
              onClick={handleClose}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
  
  export default SupplierDetails;
  