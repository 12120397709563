import { useState } from "react";
import { Box, Tabs, Tab, Badge, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

// import AddSupplierModal from "./AddSupplier";
import SuppliersList from "./SuppliersList/SupplierList";
import SuppliersRequest from "./SuppliersRequest/SuppliersRequest";

const StyledTab = styled(Box)(() => ({
  marginTop: "32px",
  width: "100%",
  "div > div > div > .MuiButtonBase-root": {
    color: "black",
    fontWeight: "600",
  },
  "div > div > div > .Mui-selected": {
    color: "black !important",
    backgroundColor: "white",
    borderRadius: " 10px 10px 0px 0px",
  },
  "div > div > div > .MuiTabs-indicator": {
    display: "none",
  },
  "div > div > div > .MuiTabs-flexContainer": {
    height: "0px",
  },
}));
const Suppliers = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const TabPanel = ({ value, index, children }) => {
    if (value === index) {
      return (
        <Box
          sx={{
            mt: 2,
          }}
        >
          {children}
        </Box>
      );
    }
  };
  const [refresh, setRefresh] = useState(false);
  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
          ASI Suppliers
        </Typography>
      </Stack>

      <StyledTab>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs value={value} onChange={handleChange}>
            <Tab label="All Suppliers" />
            <Tab label={<Box>Requested</Box>} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <SuppliersList refresh={refresh} refreshScreen={refreshScreen} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SuppliersRequest refresh={refresh} refreshScreen={refreshScreen} />
        </TabPanel>
      </StyledTab>
    </>
  );
};
export default Suppliers;
