import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import user1 from "../../../assets/user1.png";
import user2 from "../../../assets/user2.png";
import { useForm } from "../../../utils/useForm";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
  Done as DoneIcon,
  Visibility as VisibilityIcon,
  Sort as SortIcon,
} from "@mui/icons-material";
import {
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  Pagination,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  Chip,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@mui/material";
import filterIcon from "../../../assets/filter.svg";
import UserDetailsModal from "../PESupplierDetails";
import ApproveRequestModal from "./ApprovedRequest";
import RejectRequestModal from "./RejectRequest";
import { GetSuppliersRequestList } from "../../../state/actions/supplierActions";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const StyledTableColumns = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  "&:hover": {
    cursor: "pointer",
    ".header-icon": {
      visibility: "visible",
    },
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
}));

const initialValues = {
  name: "",
  email: "",
  phone_number: "",
  buyer_type: false,
  supplier_type: false,
  approved_status: false,
  pending_status: false,
  rejected_status: false,
};

const SuppliersRequest = (props) => {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPages] = useState(3);
  const [filter, setFilter] = useState(false);
  const [currentUser, setCurrentUser] = useState(-1);
  const [userDetails, setUserDetails] = useState(false);
  const [approveRequest, setApproveRequest] = useState(false);
  const [rejectRequest, setRejectRequest] = useState(false);
  const [column, setColumn] = useState("");
  const [sort, setSort] = useState(false);

  const validate = (fieldValues = values) => {};

  const { values, setValues, errors, setErrors, handelInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const handleClick = (index) => {
    setCurrentUser(index);
  };
  const handleClose = () => {
    setCurrentUser(-1);
  };

  function createData(name, email, phone_number, type, status, img) {
    return {
      name,
      email,
      phone_number,
      type,
      status,
      src: img,
      address: "8502 Preston Rd. Inglewood, Maine 98380",
      pancard_number: "CIEPG 8542C",
      vancard_number: "AAAAA0000A",
    };
  }

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
  };

  const handleApproveRequest = () => {
    setApproveRequest(true);
  };
  const closeApproveRequest = () => {
    setApproveRequest(false);
  };
  const handleRejectRequest = () => {
    setRejectRequest(true);
  };
  const closeRejectRequest = () => {
    setRejectRequest(false);
  };

  const handleUserDetails = () => {
    setUserDetails(true);
  };
  const closeUserDetails = () => {
    setUserDetails(false);
  };

  //  to enable sorting in every table while clicking on column name

  const handleColumn = (value) => {
    setColumn(value);
  };
  const handleSort = () => {
    setSort(!sort);
  };
  const sorting = () => {
    console.log(column, sort);
  };
  useEffect(() => {
    sorting();
  }, [column, sort]);

  const tableColumns = [
    { label: "Name", name: "name", isSortable: true },
    { label: "Email", name: "email", isSortable: true },
    { label: "Phone Number", name: "mobile", isSortable: true },
    {
      label: "Action",
      name: "",
      isSortable: false,
      styleProps: { align: "center" },
    },
  ];

  useEffect(() => {
    SuppliersRequestList();
  }, []);

  const SuppliersRequestList = async () => {
    let queryString = `?page=${page}&limit=10`;
    const res = await GetSuppliersRequestList(queryString);
    console.log("res", res);
    if (res.status === true) {
      // setSuppliersRequestList(res.data);
    }
  };

  const rows = [
    createData(
      "Devon Lane",
      "tanya.hill@example.com",
      "(302) 555-0107",
      "Pending",
      user2
    ),
    createData(
      "Marvin McKinney",
      "dolores.chambers@example.com",
      "(201) 555-0124",
      "Rejected",
      user1
    ),
    createData(
      "Kristin Watson",
      "debra.holt@example.com",
      "(239) 555-0108",
      "Approved",
      user2
    ),
    createData(
      "Albert Flores",
      "sara.cruz@example.com",
      "(907) 555-0101",
      "Rejected",
      user1
    ),
    createData(
      "Cody Fisher",
      "jackson.graham@example.com",
      "(205) 555-0100",
      "Approved",
      user2
    ),
  ];

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        {/* search bar and filter button */}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "40px",
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            Filter
          </Button>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form>
            <Box
              sx={{
                width: 220,
                padding: "10px 20px",
              }}
              // onClick={toggleDrawer(anchor, false)}
              // onKeyDown={toggleDrawer(anchor, false)}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={{ fontWeight: "bold" }}>Filter</Typography>
                <IconButton sx={{ color: "black" }} onClick={closeFilterDrawer}>
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="name">Name</StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Name"
                  fullWidth
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handelInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="email">Email</StyledFormLabel>
                <StyledTextField
                  type="email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Email"
                  fullWidth
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handelInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="phone_number">
                  Phone Number
                </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Phone Number"
                  fullWidth
                  id="phone_number"
                  name="phone_number"
                  value={values.phone_number}
                  onChange={handelInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel>Status</StyledFormLabel>
                <FormGroup sx={{ mt: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.approved_status}
                        onChange={handelInputChange}
                      />
                    }
                    name="approved_status"
                    label="Approved"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.pending_status}
                        onChange={handelInputChange}
                        name="pending_status"
                      />
                    }
                    label="Pending"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.rejected_status}
                        onChange={handelInputChange}
                        name="rejected_status"
                      />
                    }
                    label="Rejected"
                  />
                </FormGroup>
              </Box>
            </Box>
            <Stack
              sx={{ margin: "100px 20px 10px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    // backgroundColor: "#60579A",
                  },
                }}
                onClick={closeFilterDrawer}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: "#60579A",
                  color: "white",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#60579A",
                  },
                }}
                type="submit"
              >
                Filter
              </Button>
            </Stack>
          </form>
        </Drawer>
        <TableContainer component={Paper} sx={{ minHeight: "235px" }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableColumns.map((columns, index) => {
                  const { label, isSortable, name, styleProps } = columns;
                  return (
                    <StyledTableCell key={index} {...styleProps}>
                      <StyledTableColumns
                        onClick={() => {
                          if (isSortable) {
                            handleColumn(name);
                            handleSort();
                          }
                        }}
                      >
                        <span>{label}</span>
                        <Typography
                          className={isSortable ? "header-icon" : ""}
                          sx={{
                            visibility:
                              isSortable && column === name
                                ? "visible"
                                : "hidden",
                          }}
                        >
                          <SortIcon
                            className={`sort-table ${
                              sort ? "sort-desc" : "sort-asc"
                            }`}
                          />
                        </Typography>
                      </StyledTableColumns>
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Stack flexDirection="row" alignItems="center">
                      <Avatar
                        component="span"
                        variant="rounded"
                        alt="user1"
                        // src={index % 2 == 0 ? user1 : user2}
                        src={row.src}
                      />
                      <Typography sx={{ ml: 2 }}>{row.name}</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.phone_number}</TableCell>
                  {/* <TableCell>{row.type}</TableCell> */}
                  <TableCell>
                    <Tooltip title="View Details">
                      <IconButton
                        sx={{ borderRadius: "0px" }}
                        onClick={() => {
                          handleClick(index);
                          handleUserDetails();
                        }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    &nbsp;
                    <Tooltip title="Approve Request">
                      <IconButton
                        onClick={() => {
                          handleClick(index);
                          handleApproveRequest();
                        }}
                        sx={{
                          borderRadius: "0px",
                          backgroundColor: "rgba(18, 183, 106, 0.05)",
                          color: "#12B76A",
                        }}
                      >
                        <DoneIcon />
                      </IconButton>
                    </Tooltip>
                    &nbsp;
                    <Tooltip title="Reject Request">
                      <IconButton
                        onClick={() => {
                          handleClick(index);
                          handleRejectRequest();
                        }}
                        sx={{
                          borderRadius: "0px",
                          backgroundColor: "rgba(240, 68, 56, 0.05)",
                          color: "#F04438;",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <UserDetailsModal
          user={rows[currentUser]}
          open={userDetails}
          setOpen={setUserDetails}
          handleClose={closeUserDetails}
        />
        <ApproveRequestModal
          open={approveRequest}
          user={rows[currentUser]}
          handleClose={closeApproveRequest}
        />
        <RejectRequestModal
          open={rejectRequest}
          user={rows[currentUser]}
          handleClose={closeRejectRequest}
        />
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing {page} of {totalPage} Results
        </div>
        <Pagination
          count={totalPage}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
};

export default SuppliersRequest;
