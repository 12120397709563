import { applyMiddleware, compose, createStore } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../state/reducers/authReducer";
import buyerReducer from "../state/reducers/buyerReducer";

const initialState = {};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = configureStore({
  reducer: {
    auth: authReducer,
    buyer: buyerReducer,
  },
});
