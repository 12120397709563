import axios from "axios";
import axiosRequest from "../axios/axios";

///////////////////////////////////////////////////////
export const GetAdmins = async (queryString) => {
  try {
    const res = await axiosRequest.get(`/api/v1/adminlist` + queryString);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};
///////////////////////////////////////////////////////

export const AddAdmin = async (data) => {
  try {
    const body = data;
    var formData = new FormData();
    Object.keys(body).map((key) => {
      formData.append(key, body[key]);
    });
    const res = await axiosRequest.post(`/api/v1/addAdmin`, formData);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};
///////////////////////////////////////////////////////

export const deleteAdmin = async (id) => {
  try {
    const res = await axiosRequest.delete(`/api/v1/deleteAdmin/${id}`);
    if (res && res.data && res.data.status) {
      return res.data;
    }

    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

export const UpdateAdmin = async (data) => {
  try {
    const { body, id } = data;
    var formData = new FormData();
    Object.keys(body).map((key) => {
      // check profile_image type is string or file type
      if (key === "profile_image") {
        if (typeof body[key] === "object") {
          formData.append(key, body[key]);
        }
      } else {
        formData.append(key, body[key]);
      }
    });
    const res = await axiosRequest.post(`/api/v1/updateAdmin/${id}`, formData);

    if (res && res.data && res.data.status && res.data.data) {
      return res.data;
    }
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};
///////////////////////////////////////////////////////
