import { useState } from "react";

import { Grid, Button, Stack, Typography, IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
import { Box, Tabs, Tab, Badge } from "@mui/material";
import { styled } from "@mui/material/styles";

import AdminsList from "./AdminList";
import AddAdminModal from "./AdminAdd";

const AdminPage = () => {
  const [refresh, setRefresh] = useState(false);
  const refreshScreen = () => {
    setRefresh(!refresh);
  };
  const [addAdmin, setAddAdmin] = useState(false);
  const openAdminModal = () => {
    setAddAdmin(true);
  };
  const closeAdminModal = () => {
    setAddAdmin(false);
  };
  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
          Admin
        </Typography>
        <Button
          sx={{
            backgroundColor: "#60579A",
            color: "white",
            borderRadius: "10px",
            height: "3em",
            padding: "5px 40px",
            "&:hover": {
              backgroundColor: "#60579A",
            },
          }}
          onClick={openAdminModal}
          startIcon={<Add />}
          color="primary"
        >
           Add Admin
        </Button>
      </Stack>
      <AddAdminModal
        open={addAdmin}
        handleClose={closeAdminModal}
        refreshScreen={refreshScreen}
      />
      <Box sx={{ mt: 4 }}>
        <AdminsList refresh={refresh} refreshScreen={refreshScreen} />
      </Box>
    </>
  );
};
export default AdminPage;
