import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Grid,
  Typography,
  IconButton,
  TextField,
  FormLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";
import { getSupplierDetails } from "../../state/actions/supplierActions";
import { useEffect, useState } from "react";
import DefaultImage from "../../assets/user1.png"


const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "rgba(15, 15, 15, 0.5)",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiInput-root": {
    paddingBottom: "20px",
  },
}));

const StyledButton = styled(Button)(() => ({
  backgroundColor: "white",
  color: "black",
  border: "1px solid #0F0F0F80",
  borderRadius: "10px",
  padding: "10px 50px",
}));

const UserDetails = (props) => {
  const { open, handleClose, supplierId } = props;
  const [supplier, setSupplier] = useState({});

  useEffect(() => {
    if (supplierId) {

      getSupplier(supplierId);
    }
  }, [supplierId]);

  const getSupplier = async (supplierId) => {
    const res = await getSupplierDetails(supplierId);
    if (res && res.status === true) {
      setSupplier(res.data);
    }
  };

  const statusColors = {
    1: {
      backgroundColor: "rgba(18, 183, 106, 0.05)",
      color: "#12B76A",
    },
    3: {
      backgroundColor: "#ffff0040",
      color: "orange",
    },
    2: {
      backgroundColor: "rgba(240, 68, 56, 0.05)",
      color: "#F04438;",
    },
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Supplier Details
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={3} sm={4} xs={12}>
              <Stack
                sx={{
                  mt: 2,
                  padding: "30px 0px",
                  border: "1px solid rgba(15, 15, 15, 0.15)",
                  // height: "206px",
                  borderRadius: "10px",
                }}
                flexDirection="column"
                alignItems="center"
                spacing={2}
              >
                <img
                  src={ supplier?.profile_image ? supplier?.profile_image : DefaultImage}
                  style={{ width: "80px", height: "80px" }}
                  alt="supplier profile"
                />
                <div style={{ textAlign: "center" }}>
                  <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                    {supplier?.name}
                  </Typography>
                  {/* <Typography
                    sx={{
                      color: "rgba(15, 15, 15, 0.5)",
                      fontSize: "12px",
                      mt: 1,
                    }}
                  >
                    Buyer
                  </Typography> */}
                </div>
                {supplier?.status === "Pending" ? (
                  <>
                    <StyledButton>Approve</StyledButton>
                    <StyledButton sx={{ px: "60px" }}>Block</StyledButton>
                  </>
                ) : supplier?.status === "Approved" ? (
                  <>
                    <StyledButton>Block</StyledButton>
                    <StyledButton>Delete</StyledButton>
                  </>
                ) : supplier?.status === "Rejected" ? (
                  <>
                    <StyledButton>Approve</StyledButton>
                  </>
                ) : (
                  ""
                )}
              </Stack>
            </Grid>
            <Grid item md={9} sm={8} xs={12} container spacing={1}>
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <StyledFormLabel htmlFor="email">Email </StyledFormLabel>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  variant="standard"
                  fullWidth
                  id="email"
                  name="email"
                  value={supplier.email}
                />
              </Grid>
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <StyledFormLabel htmlFor="phone_number">
                  Phone Number
                </StyledFormLabel>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  variant="standard"
                  fullWidth
                  id="phone_number"
                  value={supplier?.mobile}
                />
              </Grid>

              <Grid item sx={{ m: 1 }} md={11.2} sm={12} xs={12}>
                <StyledFormLabel htmlFor="address">Address</StyledFormLabel>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  variant="standard"
                  fullWidth
                  id="address"
                  value={
                    `${supplier?.address}, ${supplier?.cityName}, ${supplier?.stateName}, ${supplier?.countryName}, ${supplier?.zip}` ||
                    ""
                  }
                />
              </Grid>
              <Grid item sx={{ m: 1 }} md={11.2} sm={12} xs={12}>
                <StyledFormLabel htmlFor="status">Status</StyledFormLabel>
                {/* {supplier?.isActive === "Rejected" && ( */}
                <Typography
                  sx={{
                    backgroundColor:
                      statusColors[supplier?.isActive]?.backgroundColor,
                    color: statusColors[supplier?.isActive]?.color,
                    borderRadius: "10px",
                    width: "fit-content",
                  }}
                >
                  {supplier.isActive == "1" ? "Active" : "Inactive"}
                </Typography>
                {/* )} */}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ margin: "15px" }}>
          <Button
            sx={{
              backgroundColor: "#60579A",
              color: "white",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                backgroundColor: "#60579A",
              },
            }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserDetails;
