import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
  Done as DoneIcon,
  Visibility as VisibilityIcon,
  Sort as SortIcon
} from "@mui/icons-material";
import {
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  Pagination,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
  Tooltip,
} from "@mui/material";

//  used in table
import user1 from "../../../assets/user1.png";
import user2 from "../../../assets/user2.png";

// icons for user menu
import eye from "../../../assets/eye.svg";
import edit from "../../../assets/edit.svg";
import deleteIcon from "../../../assets/delete.svg";
import block from "../../../assets/block.svg";

//  filter icons
import filterIcon from "../../../assets/filter.svg";
//  modals with respect to menu

import SuppliersDetailsModal from "../AsiSupplierDetails";
import ApproveRequestModal from "./ApproveRequest";
import RejectRequestModal from "./RejectRequest";
import { GetSuppliersRequestList } from "../../../state/actions/supplierActions";
import { useForm } from "../../../utils/useForm";
import { useSnackbar } from "notistack";
import BackdropComponent from "../../backdrop/backdrop";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));


const StyledTableColumns = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  "&:hover": {
    cursor: "pointer",
    ".header-icon": {
      visibility: "visible",
    },
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
}));
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const SupplersRequest = (props) => {
  const { refresh, refreshScreen } = props;
  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const [suppliers, setSuppliers] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const [backdropState, setBackdropState] = useState(false);
  const stopBackdrop = () => {
    setBackdropState(false);
  };
  const startBackdrop = () => {
    setBackdropState(true);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    getSuppliersList(value);
  };

  const getSuppliersList = async (page) => {
    let queryString = "?page=" + page + "&size=" + pagePerSize;
    startBackdrop();
    const res = await GetSuppliersRequestList(queryString);
    if (res && res.status) {
      setSuppliers(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setSuppliers([]);
      setTotalRecords([]);
      setTotalPages(0);
    }
    stopBackdrop();
  };

  useEffect(() => {
    getSuppliersList(page);
  }, [refresh]);

  const [currentUser, setCurrentUser] = useState(-1);
  const selectCurrentUser = (index) => {
    setCurrentUser(index);
  };
  const handleClick = (index) => {
    setCurrentUser(index);
  };
  const handleClose = () => {
    setCurrentUser(-1);
  };
  const onSearch = async (search) => {
    let queryString =
      "?page=" + page + "&size=" + pagePerSize + "&search=" + search;
    startBackdrop();
    const res = await GetSuppliersRequestList(queryString);
    if (res && res.status) {
      setSuppliers(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setSuppliers([]);
      setTotalRecords([]);
      setTotalPages(0);
    }
    stopBackdrop();
  };
  const [filter, setFilter] = useState(false);
  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
  };
  const initialValues = {
    name: "",
    email: "",
    mobile: "",
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const onFilterSubmit = async (e) => {
    e.preventDefault();

    let queryString = "?page=" + page + "&size=" + pagePerSize;
    Object.keys(values).map((key) => {
      queryString += "&" + key + "=" + values[key];
    });
    startBackdrop();
    const res = await GetSuppliersRequestList(queryString);
    if (res && res.status) {
      setSuppliers(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setSuppliers([]);
      setTotalRecords([]);
      setTotalPages(0);
    }
    stopBackdrop();
    closeFilterDrawer();
  };
  const [approveRequest, setApproveRequest] = useState(false);
  const [rejectRequest, setRejectRequest] = useState(false);

  const handleApproveRequest = () => {
    setApproveRequest(true);
  };
  const closeApproveRequest = () => {
    setApproveRequest(false);
  };
  const handleRejectRequest = () => {
    setRejectRequest(true);
  };
  const closeRejectRequest = () => {
    setRejectRequest(false);
  };

  const [userDetails, setUserDetails] = useState(false);

  const handleUserDetails = () => {
    setUserDetails(true);
  };
  const closeUserDetails = () => {
    setUserDetails(false);
  };

  //  to enable sorting in every table while clicking on column name
const [column, setColumn] = useState("");
const handleColumn = (value) => {
  setColumn(value);
};
const [sort, setSort] = useState(false);
const handleSort = () => {
  setSort(!sort);
};
const sorting = () => {
  console.log(column, sort);
};
useEffect(() => {
  sorting();
}, [column, sort]);

const tableColumns = [
  { label: "Name", name: "name", isSortable: true },
  { label: "Email", name: "email", isSortable: true },
  { label: "Phone Number", name: "mobile", isSortable: true },
  { label: "ASI Number", name: "", isSortable: false },
  { label: "Action", name: "", isSortable: false ,styleProps:{align:'center'}},
];


  return (
    <>
      <BackdropComponent open={backdropState} />
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        {/* search bar and filter button */}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "50px",
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => onSearch(e.target.value)}
            />
          </Search>

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            Filter
          </Button>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form onSubmit={onFilterSubmit}>
            <Box
              sx={{
                width: 220,
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={{ fontWeight: "bold" }}>Filter</Typography>
                <IconButton sx={{ color: "black" }} onClick={closeFilterDrawer}>
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="name">Name</StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Name"
                  fullWidth
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="email">Email</StyledFormLabel>
                <StyledTextField
                  type="email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Email"
                  fullWidth
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="mobile">Phone Number</StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Phone Number"
                  fullWidth
                  id="mobile"
                  name="mobile"
                  value={values.workPhone}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />
            </Box>
            <Stack
              sx={{ margin: "195px 20px 10px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    // backgroundColor: "#60579A",
                  },
                }}
                onClick={closeFilterDrawer}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: "#60579A",
                  color: "white",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#60579A",
                  },
                }}
                type="submit"
              >
                Filter
              </Button>
            </Stack>
          </form>
        </Drawer>
        <TableContainer
          component={Paper}
          sx={{ minHeight: "235px" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
              {tableColumns.map((columns, index) => {
                  const { label, isSortable, name } = columns;
                  return (
                    <StyledTableCell key={index}>
                      <StyledTableColumns
                        onClick={() => {
                          if (isSortable) {
                            handleColumn(name);
                            handleSort();
                          }
                        }}
                      >
                        <span>{label}</span>
                        <Typography
                          className={isSortable ? "header-icon" : ""}
                          sx={{
                            visibility:
                              isSortable && column === name
                                ? "visible"
                                : "hidden",
                          }}
                        >
                        <SortIcon
                            className={`sort-table ${
                              sort ? "sort-desc" : "sort-asc"
                            }`}
                          />
                        </Typography>
                      </StyledTableColumns>
                    </StyledTableCell>
                  );
                })}
                
              </TableRow>
            </TableHead>
            <TableBody>
              {suppliers.map((row, index) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Typography>{row.name}</Typography>
                  </TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.workPhone}</TableCell>
                  <TableCell>{row.asiNumber}</TableCell>
                  <TableCell>
                    <Tooltip title="View Details">
                      <IconButton
                        sx={{ borderRadius: "0px" }}
                        onClick={() => {
                          handleClick(index);
                          handleUserDetails();
                        }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    &nbsp;
                    <Tooltip title="Approve Request">
                      <IconButton
                        onClick={() => {
                          handleClick(index);
                          handleApproveRequest();
                        }}
                        sx={{
                          borderRadius: "0px",
                          backgroundColor: "rgba(18, 183, 106, 0.05)",
                          color: "#12B76A",
                        }}
                      >
                        <DoneIcon />
                      </IconButton>
                    </Tooltip>
                    &nbsp;
                    <Tooltip title="Reject Request">
                      <IconButton
                        onClick={() => {
                          handleClick(index);
                          handleRejectRequest();
                        }}
                        sx={{
                          borderRadius: "0px",
                          backgroundColor: "rgba(240, 68, 56, 0.05)",
                          color: "#F04438;",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <SuppliersDetailsModal
          user={suppliers[currentUser]}
          open={userDetails}
          setOpen={setUserDetails}
          handleClose={closeUserDetails}
        />
        <ApproveRequestModal
          open={approveRequest}
          user={suppliers[currentUser]}
          handleClose={closeApproveRequest}
          refreshScreen={refreshScreen}
        />
        <RejectRequestModal
          open={rejectRequest}
          user={suppliers[currentUser]}
          handleClose={closeRejectRequest}
          refreshScreen={refreshScreen}
        />
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
};

export default SupplersRequest;
