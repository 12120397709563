import axiosRequest from "../axios/axios";

///////////////////////////////////////////////////////

export const GetRoles = async (queryString) => {
  try {
    const res = await axiosRequest.get(`/api/v1/rolelist` + queryString);
    return res.data;
  } catch (err) {
    return { success: false, message: err };
  }
};
///////////////////////////////////////////////////////

export const AddRole = async (data) => {
  try {
    const body = data;
    const res = await axiosRequest.post(`/api/v1/addRole`, body);
    return res.data;
  } catch (err) {
    return { success: false, message: err };
  }
};
///////////////////////////////////////////////////////

export const deleteRole = async (id) => {
  try {
    const res = await axiosRequest.delete(`/api/v1/deleteRole/${id}`);
    return res.data;
  } catch (err) {
    return { success: false, message: err };
  }
};
export const UpdateRole = async (data, role) => {
  try {
    const { body, id } = data;
    console.log("0-0-0-0-0-0-0--0-0", body, id);
    const res = await axiosRequest.post(`/api/v1/updateRole/${role.id}`, data);
    return res.data;
  } catch (err) {
    return { success: false, message: err };
  }
};
///////////////////////////////////////////////////////

//role status change
export const changeStatus = async (id, body) => {
  try {
    const res = await axiosRequest.post(`/api/v1/changeStatusRole/${id}`, body);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};
