import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
  Sort as SortIcon,
} from "@mui/icons-material";
import { tableCellClasses } from '@mui/material/TableCell';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  Chip,
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  Pagination,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
} from "@mui/material";

// icons for user menu
import eye from "../../assets/eye.svg";
import edit from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
//  filter icons
import filterIcon from "../../assets/filter.svg";
//  modals with respect to menu
import AdminDetailsModal from "./AdminDetails";
import AdminEditModal from "./AdminAdd";
import AdminDeleteModal from "./AdminDelete";

import { GetAdmins } from "../../state/actions/adminActions";
import { GetRoles } from "../../state/actions/roleActions";
import { useForm } from "../../utils/useForm";
import { useSnackbar } from "notistack";
import BackdropComponent from "../backdrop/backdrop";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "12px",
    paddingTop: "10px",
    paddingBottom: "10px",
    position: "sticky",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    // hover color add 
    "&:hover": {
      color: "#60579A",
    },
  },
}));
const StyledTableColumns = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  "&:hover": {
    cursor: "pointer",
    ".header-icon": {
      visibility: "visible",
    },
  },
}));
const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
}));

const initialValues = {
  name: "",
  email: "",
  mobile: "",
  role: "",
};

const AdminsList = (props) => {
  const { refresh, refreshScreen } = props;
  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [admins, setAdmins] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentUser, setCurrentUser] = useState(-1);
  const [backdropState, setBackdropState] = useState(false);
  const [userView, setUserView] = useState(false);
  const [editView, setEditView] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [column, setColumn] = useState("");
  const [sort, setSort] = useState(false);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [search, setSearch] = useState("");

  const stopBackdrop = () => {
    setBackdropState(false);
  };
  const startBackdrop = () => {
    setBackdropState(true);
  };

  const getAdminsList = async (page) => {
    let queryString = `?page=${page}&pagePerSize=${pagePerSize}&search=${search}`;
    startBackdrop();
    const res = await GetAdmins(queryString);
    if (res && res.status) {
      setAdmins(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setAdmins([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
    stopBackdrop();
  };

  const getRolesList = async (page) => {
    let queryString = "?page=" + page + "&size=-1";
    startBackdrop();
    const res = await GetRoles(queryString);
    if (res && res.status) {
      setRolesList(res.data);
    } else {
      setRolesList([]);
    }
    stopBackdrop();
  };

  //  whenever admin list change fetch the list again
  useEffect(() => {
    getAdminsList(page);
    getRolesList(page);
  }, [refresh, page, search]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCurrentUser(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setCurrentUser(-1);
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const onFilterSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);


  const handleUserView = () => {
    setUserView(true);
  };
  const closeUserView = () => {
    setUserView(false);
    handleClose();
  };
  const handleEditView = () => {
    setEditView(true);
  };
  const closeEditView = () => {
    setEditView(false);
    handleClose();
  };

  const handleDeleteUser = () => {
    setDeleteUser(true);
  };
  const closeDeleteUser = () => {
    setDeleteUser(false);
    handleClose();
  };
  const getRole = (roleId) => {
    roleId = Number(roleId);
    const element = rolesList.find((role) => role.id === roleId);
    if (element) return element.name;
    else return "";
  };

  //  to enable sorting in every table while clicking on column name
  const handleColumn = (value) => {
    setColumn(value);
  };
  const handleSort = () => {
    setSort(!sort);
  };
  const sorting = () => {
  };
  useEffect(() => {
    sorting();
  }, [column, sort]);
  const tableColumns = [
    { label: "Name", name: "name", isSortable: true },
    { label: "Email", name: "email", isSortable: true },
    { label: "Phone Number", name: "mobile", isSortable: true },
    { label: "Role", name: "", isSortable: false },
    { label: "Action", name: "", isSortable: false },
  ];

  return (
    <>
      <BackdropComponent open={backdropState} />
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        {/* search bar and filter button */}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "40px",
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                setSearch(e.target.value)
                setPage(1)
              }}
            />
          </Search>

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              fontWeight: "bold",
              color: "black",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            Filter
          </Button>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form onSubmit={onFilterSubmit}>
            <Box
              sx={{
                width: 220,
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={{ fontWeight: "bold" }}>Filter</Typography>
                <IconButton sx={{ color: "black" }} onClick={closeFilterDrawer1}>
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="name">Name </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Name"
                  fullWidth
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="email">Email </StyledFormLabel>
                <StyledTextField
                  type="email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Email"
                  fullWidth
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="mobile">
                  Phone Number{" "}
                </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Phone Number"
                  fullWidth
                  id="mobile"
                  name="mobile"
                  value={values.mobile}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="role">Role </StyledFormLabel>
                <StyledTextField
                  select
                  placeholder="Select Role"
                  fullWidth
                  id="role"
                  name="role"
                  value={values.role}
                  onChange={handleInputChange}
                >
                  {rolesList.map((role) => {
                    return (
                      <MenuItem key={role.id} value={role.id}>
                        {role.name}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </Box>

              <Divider sx={{ mt: 2 }} />
            </Box>
            <Stack
              sx={{ margin: "80px 20px 10px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    // backgroundColor: "#60579A",
                  },
                }}
                onClick={closeFilterDrawer}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: "#60579A",
                  color: "white",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#60579A",
                  },
                }}
                type="submit"
              >
                Filter
              </Button>
            </Stack>
          </form>
        </Drawer>
        <TableContainer
          component={Paper}
          sx={{ minHeight: "300px" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableColumns.map((columns, index) => {
                  const { label, isSortable, name } = columns;
                  return (
                    <StyledTableCell key={index}>
                      <StyledTableColumns
                        style={{
                          fontWeight: "700",
                          fontSize: "16px",
                        }}
                        onClick={() => {
                          if (isSortable) {
                            handleColumn(name);
                            handleSort();
                          }
                        }}
                      >
                        <span>{label}</span>
                        <Typography
                          className={isSortable ? "header-icon" : ""}
                          sx={{
                            visibility:
                              isSortable && column === name
                                ? "visible"
                                : "hidden",
                          }}
                        >
                          <SortIcon
                            className={`sort-table ${sort ? "sort-desc" : "sort-asc"
                              }`}
                          />
                        </Typography>
                      </StyledTableColumns>
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {admins.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => {
                    //open details modal
                    handleUserView();
                    setCurrentUser(row);
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Stack flexDirection="row" alignItems="center">
                      <Avatar
                        component="span"
                        variant="rounded"
                        alt="profile_image"
                        src={row.profile_image}
                      />
                      <Typography sx={{ ml: 2 }}>
                        {row.name.length > 15
                          ? row.name.substr(0, 15) + "..."
                          : row.name}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.mobile}</TableCell>
                  <TableCell>{getRole(row.role)}</TableCell>
                  <TableCell onClick={(e) => e.stopPropagation()}>
                    <IconButton
                      onClick={(e) => {
                        const currentUser = admins.find(
                          (role) => role.id === row.id
                        );
                        handleClick(e, currentUser);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {" "}
              {admins?.length === 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="7"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          sx={{ mt: 0.3 }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              handleUserView();
            }}
          >
            <ListItemIcon>
              <img src={eye} alt="View Details" />
            </ListItemIcon>
            <ListItemText>View Details</ListItemText>
          </MenuItem>
          <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              handleEditView();
            }}
          >
            <ListItemIcon>
              <img src={edit} alt="Edit" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
          <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              handleDeleteUser();
            }}
          >
            <ListItemIcon>
              <img src={deleteIcon} alt="Delete" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>
        <AdminDetailsModal
          user={currentUser}
          open={userView}
          setOpen={setUserView}
          handleClose={closeUserView}
        />
        <AdminEditModal
          refreshScreen={refreshScreen}
          user={currentUser}
          open={editView}
          setOpen={setEditView}
          handleClose={closeEditView}
        />

        <AdminDeleteModal
          refreshScreen={refreshScreen}
          user={currentUser}
          open={deleteUser}
          handleClose={closeDeleteUser}
        />
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
};

export default AdminsList;
