import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
  Sort as SortIcon,
} from "@mui/icons-material";

import {
  Drawer,
  Divider,
  Box,
  TextField,
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  Pagination,
  tableCellClasses,
  FormLabel,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  Chip,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

// icons for user menu
import eye from "../../assets/eye.svg";
import edit from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import block from "../../assets/block.svg";

//  filter icons
import filterIcon from "../../assets/filter.svg";

import { getProductList } from "../../state/actions/productAction";
import { useForm } from "../../utils/useForm";

import { alpha } from "@mui/system";
import SliderUnstyled, {
  sliderUnstyledClasses,
} from "@mui/base/SliderUnstyled";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import DeleteProductModel from "./DeleteProduct";
import { deleteProduct } from "../../state/actions/productAction";
import { useSnackbar } from "notistack";
import BackdropComponent from "../backdrop/backdrop";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { categoryDropdown } from "../../state/actions/categoryAction";
import { useSelector } from "react-redux";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const initialValues = {
  sname: "",
  name: "",
  price: "",
  c_premiumPrintCafe: false,
  c_corporateId: false,
  c_branding: false,
  c_tShirt: false,
  s_inStock: false,
  s_outOfStock: false,
};

const StyledTableColumns = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  "&:hover": {
    cursor: "pointer",
    ".header-icon": {
      visibility: "visible",
    },
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontFamily: "Work Sans",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "34px",
  color: "#0F0F0F",
}));

const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "45px",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "34px",
  },
}));
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});

const StyledSlider = styled(SliderUnstyled)(
  ({ theme }) => `
    color: ${theme.palette.mode === "light" ? "#1976d2" : "#90caf9"};
  height: 4px;
  width: 100%;
  padding: 13px 0;
  display: inline-block;
  position: relative;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
  opacity: 0.75;

  &:hover {
    opacity: 1;
  }

  &.${sliderUnstyledClasses.disabled} { 
    pointer-events: none;
    cursor: default;
    color: #bdbdbd; 
  }

  & .${sliderUnstyledClasses.rail} {
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: currentColor;
    opacity: 0.38;
  }

  & .${sliderUnstyledClasses.track} {
    display: block;
    position: absolute;
    height: 4px;
    border-radius: 2px;
    background-color: currentColor;
  }

  & .${sliderUnstyledClasses.thumb} {
    position: absolute;
    width: 14px;
    height: 14px;
    margin-left: -6px;
    margin-top: -5px;
    box-sizing: border-box;
    border-radius: 50%;
    outline: 0;
    border: 2px solid currentColor;
    background-color: #fff;

    :hover,
    &.${sliderUnstyledClasses.focusVisible} {
      box-shadow: 0 0 0 0.25rem ${alpha(
        theme.palette.mode === "light" ? "#1976d2" : "#90caf9",
        0.15
      )};
    }

    &.${sliderUnstyledClasses.active} {
      box-shadow: 0 0 0 0.25rem ${alpha(
        theme.palette.mode === "light" ? "#1976d2" : "#90caf9",
        0.3
      )};
    }
  }

  & .${sliderUnstyledClasses.mark} {
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: currentColor;
    top: 50%;
    opacity: 0.7;
    transform: translateX(-50%);
  }

  & .${sliderUnstyledClasses.markActive} {
    background-color: #fff;
  }

  & .${sliderUnstyledClasses.valueLabel} {
    font-family: IBM Plex Sans;
    font-size: 14px;
    display: block;
    position: relative;
    top: -1.6em;
    text-align: center;
    transform: translateX(-50%);
  }

    & .${sliderUnstyledClasses.mark} {
      position: absolute;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background-color: currentColor;
      top: 50%;
      opacity: 0.7;
      transform: translateX(-50%);
    }
  
    & .${sliderUnstyledClasses.markActive} {
      background-color: #fff;
    }
  
    & .${sliderUnstyledClasses.valueLabel} {
      font-family: IBM Plex Sans;
      font-size: 14px;
      display: block;
      position: relative;
      top: -1.6em;
      text-align: center;
      transform: translateX(-50%);
    }
  
    & .${sliderUnstyledClasses.markLabel} {
      font-family: IBM Plex Sans;
      font-size: 12px;
      position: absolute;
      top: 20px;
      transform: translateX(-50%);
    }
  `
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgb(241 241 241)",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "34px",
    color: "rgba(15, 15, 15, 0.5)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    // hover color add
    "&:hover": {
      color: "#60579A",
    },
  },
}));

const ProductList = (props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [backdropState, setBackdropState] = useState(false);
  const stopBackdrop = () => {
    setBackdropState(false);
  };
  const startBackdrop = () => {
    setBackdropState(true);
  };
  const { refresh, refreshScreen } = props;
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [priceRange, newPriceRange] = useState([20, 40]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentUser, setCurrentUser] = useState(-1);
  const [filter, setFilter] = useState(false);
  const [sortFlag, setSortFlag] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [search, setSearch] = useState("");
  const [flagName, setflagName] = useState(false);
  const [flagPrice, setflagPrice] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [productId, setProductId] = useState();

  const handleChangePage = (event, value) => {
    setPage(value);
    getProductApiCall(value);
  };

  const usersID = useSelector((state) => state?.auth?.user?.id);

  const getProductApiCall = async (page, name = "", flag) => {
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }

    let queryString = `?page=${page}&pagePerSize=${pagePerSize}&search=${search}&sortby=${name}&sortFlag=${flag}`;
    // startBackdrop();
    const res = await getProductList(queryString);
    if (res && res.status === true) {
      setSortBy(name);
      setProducts(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
      setSortFlag(!sortFlag);
    } else {
      setProducts([]);
      setTotalRecords(0);
      setTotalPages(0);
      setSortFlag(!sortFlag);
    }
    // stopBackdrop();
    setFilter(false);
  };

  useEffect(() => {
    getProductApiCall(page);
  }, [refresh, search, pagePerSize, page, filterData]);

  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = async () => {
    const res = await categoryDropdown();
    if (res && res.status === true) {
      setCategoryList(res.data);
    }
  };

  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCurrentUser(index);
    setProductId(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setCurrentUser(-1);
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const onFilterSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  const handleConfirmationModal = () => {
    setConfirmationModal(true);
  };
  const closeConfirmationModal = () => {
    setConfirmationModal(false);
  };

  //  when admin successfully delete we have to close menu
  useEffect(() => {
    handleClose();
  }, [products]);

  const close = () => {
    closeConfirmationModal();
    handleClose();
  };
  function priceRangehandleChange(event, newValue) {
    newPriceRange(newValue);
  }

  const productStatus = (productstatus) => {
    const statusColors = {
      1: {
        backgroundColor: "rgba(18, 183, 106, 0.05)",
        color: "#12B76A",
      },

      0: {
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#F04438;",
      },
    };
    return (
      <StyledChip
        sx={statusColors[productstatus]}
        label={productstatus ? "Active" : "In Active"}
      />
    );
  };

  const onSuccess = async () => {
    const productId = currentUser.id;
    const res = await deleteProduct(productId);

    if (res && res.status) {
      close();
      refreshScreen();
      enqueueSnackbar(res.message, { variant: "success" });
    }
    if (res && res.status === false) {
      close();
      refreshScreen();
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  //  to enable sorting in every table while clicking on column name

  const dataShorting = (name) => {
    if (name === "name") {
      setflagName(!flagName);
      getProductApiCall(page, name, !flagName);
    } else {
      setflagName(false);
      setSortFlag(!sortFlag);
    }
    if (name === "date") {
      setflagPrice(!flagPrice);
      getProductApiCall(page, name, !flagPrice);
    } else {
      setflagPrice(false);
      setSortFlag(!sortFlag);
    }
  };

  return (
    <>
      <BackdropComponent open={backdropState} />
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        {/* search bar and filter button */}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "40px",
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                setSearch(e.target.value);
                setPage(1);
              }}
            />
          </Search>

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              color: "black",
              fontFamily: "Work Sans",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "34px",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            Filter
          </Button>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form onSubmit={onFilterSubmit}>
            <Box
              sx={{
                width: 220,
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  sx={{
                    fontFamily: "Work Sans",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "34px",
                  }}
                >
                  Filter
                </Typography>
                <IconButton
                  sx={{ color: "black" }}
                  onClick={closeFilterDrawer1}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="sname">
                  Supplier Name{" "}
                </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Supplier Name"
                  fullWidth
                  id="sname"
                  name="sname"
                  value={values.aname}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="name">Product Name </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Product Name"
                  fullWidth
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel>Category</StyledFormLabel>

                <select
                  name="category"
                  id="category"
                  value={values.category}
                  onChange={handleInputChange}
                  style={{
                    width: "100%",
                    height: "43px",
                    border: "1px solid #D9D9D9",
                    borderRadius: "8px",
                    marginTop: "8px",
                    fontSize: "14px",
                    backgroundColor: "white",
                  }}
                >
                  <option value="">Select Category</option>
                  {categoryList.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </select>
              </Box>
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel>Status</StyledFormLabel>
                <FormGroup sx={{ mt: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.s_inStock}
                        onChange={handleInputChange}
                      />
                    }
                    id="s_inStock"
                    name="s_inStock"
                    label="In Stock"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.s_outOfStock}
                        onChange={handleInputChange}
                      />
                    }
                    id="s_outOfStock"
                    name="s_outOfStock"
                    label="Out of Stock"
                  />
                </FormGroup>
              </Box>
            </Box>
            <Stack
              sx={{ margin: "80px 20px 10px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "9px 19px",
                  "&:hover": {
                    // backgroundColor: "#60579A",
                  },
                  fontFamily: "Work Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                }}
                onClick={closeFilterDrawer}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: "#60579A",
                  color: "white",
                  borderRadius: "10px",
                  padding: "9px 19px",
                  "&:hover": {
                    backgroundColor: "#60579A",
                  },
                  fontFamily: "Work Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                }}
                type="submit"
              >
                Filter
              </Button>
            </Stack>
          </form>
        </Drawer>
        <TableContainer component={Paper} sx={{ minHeight: "300px" }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow hover>
                <StyledTableCell onClick={() => dataShorting("name")}>
                  Product Name
                  {flagName ? (
                    <ArrowUpwardIcon
                      style={{
                        fontSize: "1rem",
                        position: "relative ",
                        top: "2px ",
                        left: "3px ",
                        cursor: "pointer ",
                      }}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      style={{
                        fontSize: "1rem",
                        position: "relative ",
                        top: "2px ",
                        left: "3px ",
                        cursor: "pointer ",
                      }}
                    />
                  )}
                </StyledTableCell>

                <StyledTableCell>Supplier</StyledTableCell>
                <StyledTableCell>Price</StyledTableCell>
                <StyledTableCell onClick={() => dataShorting("date")}>
                  Date
                  {flagPrice ? (
                    <ArrowUpwardIcon
                      style={{
                        fontSize: "1rem",
                        position: "relative ",
                        top: "2px ",
                        left: "3px ",
                        cursor: "pointer ",
                      }}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      style={{
                        fontSize: "1rem",
                        position: "relative ",
                        top: "2px ",
                        left: "3px ",
                        cursor: "pointer ",
                      }}
                    />
                  )}
                </StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products &&
                products?.map((product, index) => (
                  <StyledTableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() => {
                      navigate("/products/details", {
                        state: product.id,
                      });
                    }}
                    hover
                  >
                    <TableCell component="th" scope="row">
                      <Stack flexDirection="row" alignItems="center">
                        <Avatar
                          component="span"
                          variant="rounded"
                          alt="profile_image"
                          src={product?.Images[0]?.imageUrl}
                        />
                        <Typography
                          sx={{
                            fontFamily: "Work Sans",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "34px",
                            ml: 2,
                          }}
                        >
                          {product.name}
                        </Typography>
                      </Stack>
                    </TableCell>
                    {/* <TableCell>{product.categoryId}</TableCell> */}
                    <TableCell
                      sx={{
                        fontFamily: "Work Sans",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "34px",
                      }}
                    >
                      {product.aname}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Work Sans",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "34px",
                      }}
                    >
                      {product.mrpPrice}
                    </TableCell>
                    <TableCell>
                      {moment(product.createdAt).format("MMM DD, YYYY")}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Work Sans",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "34px",
                      }}
                    >
                      {productStatus(product.isActive)}
                    </TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <IconButton
                        onClick={(e) => {
                          const currentUser = products.find(
                            (role) => role.id === product.id
                          );
                          handleClick(e, currentUser, currentUser?.id);
                          // setProductId(product.id);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                ))}{" "}
              {products?.length === 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                      fontFamily: "Work Sans",
                    }}
                    colSpan="7"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
              <Menu
                sx={{ mt: 0.3 }}
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  sx={{ mt: 1 }}
                  onClick={() => {
                    navigate(`/products/edit`, {
                      state: {
                        id: currentUser.id,
                      },
                    });
                  }}
                >
                  <ListItemIcon>
                    <img src={edit} alt="Edit" />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "30px",
                    }}
                  >
                    Edit
                  </ListItemText>
                </MenuItem>
                <MenuItem sx={{ mt: 1 }} onClick={handleConfirmationModal}>
                  <ListItemIcon>
                    <img src={deleteIcon} alt="Delete" />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "30px",
                    }}
                  >
                    Delete
                  </ListItemText>
                </MenuItem>
              </Menu>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <DeleteProductModel
        open={confirmationModal}
        handleClose={close}
        handleSuccess={onSuccess}
      />
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
};

export default ProductList;
