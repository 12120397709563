import { useState } from "react";

import { Button, Stack, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/material";
import styled from "@emotion/styled";

import AdminSupplierOrderList from "../order/AdminSupplierOrderList";
import NewAdminOrder from "../order/NewAdminOrder";

const StyledTab = styled(Box)(() => ({
  marginTop: "32px",
  width: "100%",
  "div > div > div > .MuiButtonBase-root": {
    color: "black",
    fontWeight: "600",
  },
  "div > div > div > .Mui-selected": {
    color: "black !important",
    backgroundColor: "white",
    borderRadius: " 10px 10px 0px 0px",
  },
  "div > div > div > .MuiTabs-indicator": {
    display: "none",
  },
  "div > div > div > .MuiTabs-flexContainer": {
    height: "0px",
  },
}));

const SupplierOrder = () => {
  const [refresh, setRefresh] = useState(false);
  const [value, setValue] = useState(0);

  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const TabPanel = ({ value, index, children }) => {
    if (value === index) {
      return (
        <Box
          sx={{
            mt: 2,
          }}
        >
          {children}
        </Box>
      );
    }
  };

  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
          Supplier Order Details
        </Typography>
      </Stack>

      <Box sx={{ mt: 4 }}>
        <StyledTab>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Supplier List" />
              <Tab label={<Box>Order</Box>} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <AdminSupplierOrderList
              refresh={refresh}
              refreshScreen={refreshScreen}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <NewAdminOrder refresh={refresh} refreshScreen={refreshScreen} />
          </TabPanel>
        </StyledTab>
      </Box>
    </>
  );
};
export default SupplierOrder;
