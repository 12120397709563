import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Collapse,
} from "@mui/material";
import {
  ExpandMore,
  ExpandLess,
  Person,
  PersonAdd,
  SupervisorAccount,
  Dashboard,
} from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import logo from "../../assets/logo.svg";
import dashboard from "../../assets/dashboard.svg";
import products from "../../assets/product.svg";
import orders from "../../assets/order.svg";
import payment from "../../assets/payment.svg";
import setting from "../../assets/setting.svg";
import { useSelector } from "react-redux";

const SelectedStyles = {
  ".MuiListItemButton-root": {
    backgroundColor: "#60579A",
    ".MuiListItemIcon-root img": {
      filter: " brightness(0) invert(1)",
    },

    ".MuiSvgIcon-root": {
      color: "white",
    },
    ".MuiListItemText-root": {
      color: "white",
    },
  },
};

const Sidebar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location?.pathname.substr(1);

  //  if unknown route is found then redirect it to dashboard page
  const [active, setActive] = useState(pathname);

  //  state for sub menus
  const [userMenu, setUserMenu] = useState(false);
  const [categoryMenu, setCategoryMenu] = useState(false);
  const [othersMenu, setOthersMenu] = useState(false);
  const [ordersMenu, setOrdersMenu] = useState(false);

  //admin
  const user = useSelector((state) => state?.auth?.user?.role === 2);

  //supplier
  const users = useSelector((state) => state?.auth?.user?.role === 1);

  const toggleUserMenu = () => {
    setUserMenu(!userMenu);
  };

  const closeUserMenu = () => {
    setUserMenu(false);
  };

  const toggleCategoryMenu = () => {
    setCategoryMenu(!categoryMenu);
  };

  const closeCategoryMenu = () => {
    setCategoryMenu(false);
  };

  const toggleOthersMenu = () => {
    setOthersMenu(!othersMenu);
  };
  const closeOthersMenu = () => {
    setOthersMenu(false);
  };
  const toggleOrdersMenu = () => {
    setOrdersMenu(!ordersMenu);
  };
  const closeOrdersMenu = () => {
    setOrdersMenu(false);
  };

  const menus = [
    { menu: "dashboard", subMenu: [] },
    {
      menu: "",
      // subMenu: ["admin", "buyer", "pesuppliers", "roles"],
      subMenu: ["buyer", "pesuppliers"],
      toggle: toggleUserMenu,
    },
    { menu: "products", subMenu: [] },
    { menu: "supplierOrders", subMenu: [] },

    {
      menu: "",
      subMenu: ["category", "subcategory"],
      toggle: toggleCategoryMenu,
    },
    { menu: "orders", subMenu: [] },
    { menu: "payment", subMenu: [] },
    { menu: "settings", subMenu: [] },
    {
      menu: "",
      subMenu: ["countries", "state"],
      toggle: toggleOthersMenu,
    },
    {
      menu: "products/new",
      subMenu: [],
    },
    {
      menu: "products/edit",
      subMenu: [],
    },
    {
      menu: "products/details",
      subMenu: [],
    },
  ];
  //  after refreshing the page select the respective tab
  const getCurrentTab = () => {
    if (pathname === "") {
      navigate("/dashboard");
    }
    var flag = false;
    //  matching at menu level
    for (let index = 0; index < menus.length; index++) {
      const element = menus[index];
      if (element.menu === pathname) {
        setActive(pathname);
        flag = true;
        break;
      }
    }
    if (!flag) {
      //  matching at sub-menus level
      for (let index = 0; index < menus.length; index++) {
        const element = menus[index];
        if (element.subMenu.includes(pathname)) {
          element.toggle();
          setActive(pathname);
          flag = true;
          break;
        }
      }
    }
    //  found unknown route then redirect to dashboard page
    if (!flag) {
      navigate("/dashboard");
    }
  };
  useEffect(() => {
    getCurrentTab();
  }, []);

  //  we have to close all sub menus when we click on other menu items
  const closeAllMenus = (key) => {
    const subMenusList = [
      { key: "userMenu", close: closeUserMenu },
      { key: "categoryMenu", close: closeCategoryMenu },
      { key: "othersMenu", close: closeOthersMenu },
      { key: "ordersMenu", close: closeOrdersMenu },
    ];

    subMenusList.forEach((value) => {
      if (key === value.key) {
        //  if matches we dont have to do anything
      } else {
        //  if not match then close all other open sub menus
        value.close();
      }
    });
  };

  {
    /* for Supplier MenuList */
  }

  const MenusLists = [
    {
      name: "Dashboard",
      icon: Dashboard,
      path: "dashboard",
    },
    {
      name: "Product",
      img: products,
      path: "productsupplier",
    },
    {
      name: "Orders",
      img: orders,
      path: "supplierOrder",
    },
    {
      name: "Setting",
      img: setting,
      path: "setting",
    },
    {
      name: "Location",
      icon: LocationOnIcon,
      path: "countries",
    },
  ];

  {
    /* for admin MenuList */
  }

  const MenusList = [
    {
      name: "Dashboard",
      // img: dashboard,
      icon: Dashboard,
      path: "dashboard",
    },
    {
      name: "Users",
      icon: Person,
      isSubMenu: true,
      subMenus: [
        // {
        //   name: "Admin",
        //   icon: SupervisorAccount,
        //   path: "admin",
        // },
        {
          name: "Customer",
          icon: SupervisorAccount,
          path: "buyer",
        },
        {
          name: "Suppliers",
          icon: SupervisorAccount,
          path: "pesuppliers",
        },
        // {
        //   name: "ASI Supplier",
        //   icon: SupervisorAccount,
        //   path: "asisupplier",
        // },
        // {
        //   name: "Roles",
        //   icon: SupervisorAccount,
        //   path: "roles",
        // },
      ],
      key: "userMenu",
      state: userMenu,
      toggle: toggleUserMenu,
    },
    {
      name: "Categories",
      img: products,
      isSubMenu: true,
      subMenus: [
        {
          name: "Category",
          img: products,
          path: "category",
        },
        {
          name: "Sub Category",
          img: products,
          path: "subcategory",
        },
      ],
      key: "categoryMenu",
      state: categoryMenu,
      toggle: toggleCategoryMenu,
    },
    {
      name: "Product",
      img: products,
      path: "products",
    },

    {
      name: "Orders",
      img: orders,
      isSubMenu: true,
      subMenus: [
        {
          name: "User Orders",
          img: products,
          path: "orders",
        },
        // {
        //   name: "Supplier Orders",
        //   img: products,
        //   path: "adminSupplierOrder",
        // },

        // {
        //   name: "Supplier Orders Details",
        //   img: products,
        //   path: "adminSupplierOrderList",
        // },
      ],
      key: "ordersMenu",
      state: ordersMenu,
      toggle: toggleOrdersMenu,
    },

    {
      name: "Payment",
      img: payment,
      path: "payment",
    },
    {
      name: "Setting",
      img: setting,
      path: "setting",
    },
    {
      name: "Location",
      icon: LocationOnIcon,
      isSubMenu: true,
      key: "othersMenu",
      subMenus: [
        {
          name: "Countries",
          icon: SupervisorAccount,
          path: "countries",
        },
      ],
      state: othersMenu,
      toggle: toggleOthersMenu,
    },
  ];

  return (
    <>
      <Box sx={{ textAlign: "center", marginTop: "25px" }}>
        <img src={logo} alt="LOGO" />
      </Box>
      <Box className="PE_sidebar" sx={{ height: "80%", overflowY: "auto" }}>
        {/* for admin list */}

        <List
          sx={{
            ".MuiListItemButton-root": {
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#60579A",
                ".MuiListItemIcon-root img": {
                  filter: " brightness(0) invert(1)",
                },
                ".MuiSvgIcon-root": {
                  color: "white",
                },
                ".MuiListItemText-root": {
                  color: "white",
                },
              },
            },
          }}
        >
          {true &&
            MenusList.map((value, i) => {
              const flag = value?.isSubMenu;
              //  if it is not submenu then it is only menu no sub menus
              if (!flag && user) {
                return (
                  <>
                    <ListItem
                      key={i}
                      sx={active === value.path ? SelectedStyles : {}}
                      onClick={() => {
                        navigate("/" + value.path);
                        setActive(value.path);
                        closeAllMenus();
                      }}
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          {value?.img ? (
                            <img src={value.img} alt={value.name} />
                          ) : (
                            <value.icon />
                          )}
                        </ListItemIcon>

                        <ListItemText primary={value.name} />
                      </ListItemButton>
                    </ListItem>
                  </>
                );
              }
              //  it means it is a submenus
              if (flag && user) {
                return (
                  <>
                    <div key={i}>
                      <ListItem
                        onClick={() => {
                          value.toggle();
                        }}
                      >
                        <ListItemButton>
                          <ListItemIcon>
                            {value?.img ? (
                              <img src={value.img} alt={value.name} />
                            ) : (
                              <value.icon />
                            )}
                          </ListItemIcon>
                          <ListItemText> {value.name} </ListItemText>
                          {value.state ? <ExpandMore /> : <ExpandLess />}
                        </ListItemButton>
                      </ListItem>
                      {/*  our submenus */}
                      <Collapse in={value.state} timeout="auto" unmountOnExit>
                        <List
                          component="div"
                          sx={{ margin: "0px 5px 0px 20px" }}
                        >
                          {/*  our submenus list */}
                          {value?.subMenus.map((subMenuButton, i) => {
                            return (
                              <>
                                {user && (
                                  <ListItem
                                    key={i}
                                    sx={
                                      active === subMenuButton.path
                                        ? SelectedStyles
                                        : {}
                                    }
                                    onClick={() => {
                                      navigate("/" + subMenuButton.path);
                                      setActive(subMenuButton.path);
                                      //  close all the sub menus except this sub menu that's why passed
                                      //  a key to recognize that
                                      closeAllMenus(value.key);
                                    }}
                                  >
                                    <ListItemButton>
                                      <ListItemIcon>
                                        {subMenuButton?.img ? (
                                          <img
                                            src={subMenuButton.img}
                                            alt={subMenuButton.name}
                                          />
                                        ) : (
                                          <subMenuButton.icon />
                                        )}
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={subMenuButton.name}
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                )}
                              </>
                            );
                          })}
                        </List>
                      </Collapse>
                    </div>
                  </>
                );
              }
            })}
        </List>

        {/* for supplier list */}
        <List
          sx={{
            ".MuiListItemButton-root": {
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#60579A",
                ".MuiListItemIcon-root img": {
                  filter: " brightness(0) invert(1)",
                },
                ".MuiSvgIcon-root": {
                  color: "white",
                },
                ".MuiListItemText-root": {
                  color: "white",
                },
              },
            },
          }}
        >
          {true &&
            MenusLists.map((value, i) => {
              const flag = value?.isSubMenu;
              //  if it is not submenu then it is only menu no sub menus
              if (!flag && users) {
                return (
                  <>
                    <ListItem
                      key={i}
                      sx={active === value.path ? SelectedStyles : {}}
                      onClick={() => {
                        navigate("/" + value.path);
                        setActive(value.path);
                        closeAllMenus();
                      }}
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          {value?.img ? (
                            <img src={value.img} alt={value.name} />
                          ) : (
                            <value.icon />
                          )}
                        </ListItemIcon>

                        <ListItemText>{value.name}</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </>
                );
              }
              //  it means it is a submenus
            })}
        </List>
      </Box>
    </>
  );
};

export default Sidebar;
