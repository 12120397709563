import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
  Sort as SortIcon,
} from "@mui/icons-material";
import {
  TableContainer,
  Paper,
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  Pagination,
  tableCellClasses,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Drawer,
  Box,
  TextField,
  FormLabel,
  Switch,
  Grid,
  InputAdornment,
  Avatar,
} from "@mui/material";
import eye from "../../../assets/eye.svg";
import edit from "../../../assets/edit.svg";
import deleteIcon from "../../../assets/delete.svg";
import UserDetailsModal from "../PESupplierDetails";
import SupplierDeleteModal from "./PESupplierDelete";
import AddUserModal from "../AddPESupplier";
import filterIcon from "../../../assets/filter.svg";
import {
  GetSuppliersList,
  changeSupplierStatus,
} from "../../../state/actions/supplierActions";
import { useForm } from "../../../utils/useForm";
import BackdropComponent from "../../../components/backdrop/backdrop";
import Modal from "@mui/material/Modal";
import DefaultImage from "../../../assets/user1.png";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontFamily: "Work Sans",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "34px",
  color: "#0F0F0F",
}));

const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "45px",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "34px",
  },
}));
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgb(241 241 241)",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "34px",
    color: "rgba(15, 15, 15, 0.5)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    // hover color add
    "&:hover": {
      color: "#60579A",
    },
  },
}));

const initialValues = {
  name: "",
  email: "",
  mobile: "",
  status: "",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const PeSupplierList = (props) => {
  const [backdropState, setBackdropState] = useState(false);
  const [switchId, setSwitchId] = useState(null);
  const [status, setStatus] = useState();
  const [checked, setChecked] = useState(true);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);

  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);

  const stopBackdrop = () => {
    setBackdropState(false);
  };
  const startBackdrop = () => {
    setBackdropState(true);
  };
  const { refresh, refreshScreen } = props;
  const [supplierList, setSupplierList] = useState([]);
  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [search, setSearch] = useState("");
  const [userDetails, setUserDetails] = useState(false);
  const [supplierId, setSupplierId] = useState("");
  const [addSupplier, setAddSupplier] = useState(false);
  const [deleteSupplier, setDeleteSupplier] = useState(false);

  const openUserModal = () => {
    setAddSupplier(true);
  };
  const closeUserModal = () => {
    setAddSupplier(false);
  };

  const openDeleteModal = () => {
    setDeleteSupplier(true);
  };
  const closeDeleteModal = () => {
    setDeleteSupplier(false);
  };

  const handleChangeStatus = async (id) => {
    const payload = {
      status: checked ? 1 : 2,
      SupplierId: id,
    };
    console.log("object", payload);
    const res = await changeSupplierStatus(payload);
    if (res && res.status === true) {
      getSupplierApiCall();
      handleCloseSwitch();
    }
  };

  const handleChangePage = (e, value) => {
    setPage(value);
    getSupplierApiCall(value);
  };

  const handleUserDetails = () => {
    setUserDetails(true);
  };
  const closeUserDetails = () => {
    setUserDetails(false);
  };

  const getSupplierApiCall = async () => {
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }

    let queryString = `?page=${page}&pagePerSize=${pagePerSize}&search=${search}${string}`;
    startBackdrop();
    const res = await GetSuppliersList(queryString);
    if (res && res.status === true) {
      setSupplierList(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setSupplierList([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
    stopBackdrop();
    setFilter(false);
  };

  useEffect(() => {
    getSupplierApiCall();
  }, [refresh, search, pagePerSize, page, filterData]);

  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const onFilterSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  const handleConfirmationModal = () => {
    setConfirmationModal(true);
  };
  const closeConfirmationModal = () => {
    setConfirmationModal(false);
  };

  useEffect(() => {
    handleClose();
  }, [refresh, supplierList]);

  const close = () => {
    closeConfirmationModal();
    handleClose();
  };

  const supplierStatus = (suppliertatus) => {
    const statusColors = {
      1: {
        backgroundColor: "rgba(18, 183, 106, 0.05)",
        color: "#12B76A",
      },

      0: {
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#F04438;",
      },
    };
    return (
      <StyledChip
        sx={statusColors[suppliertatus]}
        label={suppliertatus ? "Active" : "In Active"}
      />
    );
  };

  return (
    <>
      <BackdropComponent open={backdropState} />
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "40px",
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                setSearch(e.target.value);
                setPage(1);
              }}
            />
          </Search>

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              color: "black",
              fontFamily: "Work Sans",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "34px",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            Filter
          </Button>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form onSubmit={onFilterSubmit}>
            <Box
              sx={{
                width: 220,
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={{ fontWeight: "bold" }}>Filter</Typography>
                <IconButton
                  sx={{ color: "black" }}
                  onClick={closeFilterDrawer1}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="name">Supplier Name </StyledFormLabel>
                <StyledTextField
                  placeholder="Supplier Name"
                  fullWidth
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                />
              </Box>
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="email">Email </StyledFormLabel>
                <StyledTextField
                  placeholder="Supplier Email"
                  fullWidth
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                />
              </Box>
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="mobile">
                  Mobile Number{" "}
                </StyledFormLabel>
                <StyledTextField
                  placeholder="Mobile Number"
                  fullWidth
                  id="mobile"
                  name="mobile"
                  value={values.mobile}
                  onChange={handleInputChange}
                />
              </Box>
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="status">Status </StyledFormLabel>
                <select
                  id="status"
                  name="status"
                  value={values.status}
                  onChange={handleInputChange}
                  style={{
                    width: "100%",
                    height: "45px",
                    borderRadius: "10px",
                    border: "1px solid #0F0F0F80",
                    padding: "0px 10px",
                    backgroundColor: "white",
                    fontSize: "14px",
                  }}
                >
                  <option value="">Select Status</option>
                  <option value="1">Active</option>
                  <option value="2">InActive</option>
                  <option value="3">Delete</option>
                </select>
              </Box>
            </Box>
            <Stack
              sx={{ margin: "80px 20px 10px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "9px 19px",
                  "&:hover": {
                    // backgroundColor: "#60579A",
                  },
                  fontFamily: "Work Sans",
                  fontWeight: 400,
                  top: "55px",
                }}
                onClick={closeFilterDrawer}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: "#60579A",
                  color: "white",
                  borderRadius: "10px",
                  padding: "9px 19px",
                  "&:hover": {
                    backgroundColor: "#60579A",
                  },
                  fontFamily: "Work Sans",
                  fontWeight: 400,
                  top: "55px",
                }}
                type="submit"
              >
                Filter
              </Button>
            </Stack>
          </form>
        </Drawer>
        <TableContainer component={Paper} sx={{ minHeight: "300px" }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableCell>Name</StyledTableCell>

              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Phone Number</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableHead>
            <TableBody>
              {supplierList &&
                supplierList?.map((supplier, index) => (
                  <TableRow
                    key={supplier.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() => {
                      setSupplierId(supplier.id);
                      handleUserDetails();
                    }}
                    hover
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        fontFamily: "Work Sans",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "34px",
                      }}
                    >
                      <Stack flexDirection="row" alignItems="center">
                        <Avatar
                          component="span"
                          variant="rounded"
                          alt="user1"
                          src={
                            supplier.profile_image
                              ? supplier.profile_image
                              : DefaultImage
                          }
                        />
                        <Typography
                          sx={{
                            fontFamily: "Work Sans",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "34px",
                            ml: 2,
                          }}
                        >
                          {supplier.name}
                        </Typography>
                      </Stack>
                    </TableCell>
                    {/* <TableCell>{product.categoryId}</TableCell> */}
                    <TableCell
                      sx={{
                        fontFamily: "Work Sans",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "34px",
                      }}
                    >
                      {supplier.email}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Work Sans",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "34px",
                      }}
                    >
                      {supplier.mobile}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Work Sans",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "34px",
                      }}
                    >
                      <Switch
                        checked={supplier.isActive == 1 ? true : false}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onChange={(e) => {
                          handleSwitchModel();
                          setStatus(
                            e.target.checked == true ? "Active" : "Inactive"
                          );
                          setSwitchId(supplier.id);
                          setChecked(e.target.checked);
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    </TableCell>
                    {/* <TableCell>{supplierStatus(supplier.isActive)}</TableCell> */}
                    <TableCell
                      sx={{
                        fontFamily: "Work Sans",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "34px",
                      }}
                    >
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setSupplierId(supplier.id);
                          handleClick(e, supplier.id);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}{" "}
              {supplierList?.length === 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="7"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
              <Menu
                sx={{ mt: 0.3 }}
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <MenuItem
                  sx={{ mt: 1 }}
                  onClick={(e) => {
                    handleUserDetails();
                    handleClose(e);
                  }}
                >
                  <ListItemIcon>
                    <img src={eye} alt="View Details" />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "30px",
                    }}
                  >
                    View Details
                  </ListItemText>
                </MenuItem>
                <MenuItem
                  sx={{ mt: 1 }}
                  onClick={(e) => {
                    openUserModal();
                    handleClose(e);
                  }}
                >
                  <ListItemIcon>
                    <img src={edit} alt="Edit" />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "30px",
                    }}
                  >
                    Edit
                  </ListItemText>
                </MenuItem>
                <MenuItem
                  sx={{ mt: 1 }}
                  onClick={(e) => {
                    openDeleteModal();
                    handleClose(e);
                  }}
                >
                  <ListItemIcon>
                    <img src={deleteIcon} alt="Delete" />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "30px",
                    }}
                  >
                    Delete
                  </ListItemText>
                </MenuItem>
              </Menu>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <UserDetailsModal
        open={userDetails}
        setOpen={setUserDetails}
        supplierId={supplierId}
        handleClose={closeUserDetails}
        refreshScreen={refreshScreen}
      />

      <AddUserModal
        open={addSupplier}
        setOpen={setAddSupplier}
        handleClickOpen={openUserModal}
        handleClose={closeUserModal}
        refreshScreen={refreshScreen}
        supplierId={supplierId}
      />
      <SupplierDeleteModal
        open={deleteSupplier}
        setOpen={setDeleteSupplier}
        handleClose={closeDeleteModal}
        supplierId={supplierId}
        refreshScreen={refreshScreen}
        supplierList={getSupplierApiCall}
      />

      <Modal
        open={openSwitchModel}
        // onClose={handleClose}
        disableAutoFocus={true}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            bgcolor: "#FFFFFF",
            padding: "20px",
            borderRadius: "10px",
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "15px",
            lineHeight: "30px",
          }}
        >
          <p className="modal-title"> Update Status </p>
          <p style={{ fontSize: "16px" }}>
            Are you sure you want to Update Status ?
          </p>

          <br />
          <div className="form-button-container">
            <button className="form-action-button" onClick={handleCloseSwitch}>
              Cancel
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              className="form-action-button-submit"
              onClick={() => handleChangeStatus(switchId)}
            >
              YES
            </button>
          </div>
        </Box>
      </Modal>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
};

export default PeSupplierList;
