import * as React from "react";
import Box from "@mui/material/Box";
import {
  Card,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SwipeableDrawer,
  Tab,
  TableContainer,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Grid, Button, Stack, Typography, IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import { Table, TableHead, TableBody, TableRow, Chip } from "@mui/material";
import "./orderDetails.css";
import {
  adminStatusChange,
  getOrderDetails,
  GetOrderStatusList,
  GetOrderSuppliersList,
  getSupplierOrderDetails,
  supplierQuatation,
  supplierQuotationsAccepted,
} from "../../state/actions/orderAction";
import moment from "moment";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import { useForm } from "../../utils/useForm";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import CheckIcon from "@mui/icons-material/Check";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "12px",
    paddingTop: "10px",
    paddingBottom: "10px",
    position: "sticky",
    fontWeight: "bold",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#0957dd0a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledLabel = styled("label")(() => ({
  display: "inline-block",
  fontWeight: "500",
  fontSize: "14px",
  mt: 1,
  borderRadius: "8px",
}));

const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const initialValues = {
  name: "",
};

export default function FormPropsTextFields() {
  const [orderProductData, setorderProductData] = useState([]);
  const [orderData, setorderData] = useState([]);
  const location = useLocation();
  const [supplshippingAddress, setshippingAddress] = useState({});

  const [value, setValue] = useState("1");

  const [supplierList, setSupplierList] = useState([]);
  const [supplierName, setSupplierName] = useState();

  const [isStatusEditing, setStatusEditing] = useState(false);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [quotationId, setQuotationId] = useState(0);
  const [statusMenu, setStatusMenu] = useState(true);
  const [city, setCity] = useState([]);
  const [states, setStates] = useState([]);
  const [country, setCountry] = useState([]);
  const [orderStatusList, setOrderStatusList] = useState([]);
  const [hideButton, setHideButton] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const openStatusMenu = () => {
    setStatusMenu(true);
  };
  const closeStatusMenu = () => {
    setStatusMenu(false);
  };

  const startStatusEditing = (id, e) => {
    setStatusEditing(id);
    setQuotationId(id);
  };

  const startStatusUpdate = (e, index) => {
    let newArr = [...orderData]; // copying the old datas array
    newArr[index][e.target.name] = e.target.value;
    setorderData(newArr);
    // setorderProductData({ ...orderProductData, [e.target.name]: e.target.value });
  };

  const stopStatusEditing = () => {
    setStatusEditing();
  };

  const handleChanges = (e, index) => {
    // setSupplierName({ ...supplierName, [e.target.id]: e.target.value });
    let newArr = [...supplierName]; // copying the old datas array
    newArr[index][e.target.name] = e.target.value;
    setSupplierName(newArr);
  };

  const getAllSupplierList = async () => {
    const res = await GetOrderSuppliersList();
    if (res && res.status) {
      setSupplierList(res.data);
    }
  };

  useEffect(() => {
    setShowEditIcon(orderData[orderData.length - 1]);
    getAllSupplierList();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const { state } = useLocation();
  const id = state;

  const OrderDetails = async (id) => {
    const res = await getSupplierOrderDetails(id);
    if (res && res.status === true) {
      setorderProductData(res.orderData);
      setorderData(res.data);
      // setcustomerData(res.data.customer);
      setshippingAddress(res?.orderData?.shippingAddressId);
      setCity(res.orderData.shippingAddressId?.city);
      setStates(res.orderData.shippingAddressId?.state);
      setCountry(res.orderData.shippingAddressId?.country);
      // setbillingAddress(res.data.billingAddress);
    }
  };

  useEffect(() => {
    OrderDetails(id);
  }, [id]);

  const productStatus = (productstatus) => {
    const statusColors = {
      0: {
        key: "Closed",
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#f05252;",
      },
      1: {
        key: "Paid",
        backgroundColor: "#ede7dd",
        color: "#0e9f6e",
      },
      2: {
        key: "Pending",
        backgroundColor: "#fdf6b2",
        color: "#c27803",
      },
      3: {
        key: "Partial",
        backgroundColor: "#def7ec",
        color: "gray",
      },
    };

    return (
      <StyledChip
        style={{
          width: "30%",
          height: "23px",
          fontSize: "10px",
        }}
        sx={statusColors[productstatus]}
        label={
          statusColors[productstatus] ? statusColors[productstatus].key : ""
        }
      />
    );
  };

  const orderStatus = (orderstatus) => {
    const statusColors = {
      1: {
        key: "Pending",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      2: {
        key: "InProgress",
        backgroundColor: "#fdf6b2",
        color: "#c27803",
      },
      3: {
        key: " Ready to dispatch ",
        backgroundColor: "#def7ec",
        color: "#0e9f6e",
      },
      4: {
        key: "Shipped ",
        backgroundColor: "#def7ec",
        color: "blue",
      },
      5: {
        key: "Delivered",
        backgroundColor: "#def7ec",
        color: "green",
      },
      6: {
        key: "Cancel",
        backgroundColor: "#def7ec",
        color: "black",
      },
      7: {
        key: "Failed",
        backgroundColor: "#def7ec",
        color: "red",
      },
    };

    return (
      <StyledChip
        style={{
          width: "70%",
          height: "23px",
          fontSize: "10px",
        }}
        sx={statusColors[orderstatus]}
        label={statusColors[orderstatus] ? statusColors[orderstatus].key : ""}
      />
    );
  };

  const orderstatus = orderData?.map((x) => x.status);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true);

  const user = useSelector((state) => state?.auth?.user);

  const onSubmit = async () => {
    let data = orderData?.filter((x) => {
      if (x.id === quotationId) {
        return x;
      }
    });

    if (data.length > 0) {
      data = data[0];
      const body = {
        orderId: data.id,
        supplierId: user?.id,
        productId: data.itemId,
        itemQty: data.itemQty,
        supplierPrice: data.supplierPrice,
        totalAmount: data.itemPrice,
        status: 3,
        type: 2,
        supplierOrderId: data.supplierOrderId,
      };
      const res = await supplierQuatation(body);
      if (res && res.status === true) {
        enqueueSnackbar(res.message, { variant: "success" });
      }
      if (res && res.status === false) {
        enqueueSnackbar(
          res.message || "Unexpected error occured please try again later.",
          { variant: "error" }
        );
      }
    }
  };

  const approveQuotation = async (quotationid, status) => {
    const body = {
      status: status,
    };
    const res = await supplierQuotationsAccepted(quotationid, body);
    setHideButton(true);
    if (res && res.status === true) {
      enqueueSnackbar(res.message, { variant: "success" });
    }
    if (res && res.status === false) {
      enqueueSnackbar(
        res.message || "Unexpected error occured please try again later.",
        { variant: "error" }
      );
    }
  };

  const getorderStatus = async () => {
    const res = await GetOrderStatusList();
    if (res && res.status) {
      setOrderStatusList(res.data);
    }
  };

  useEffect(() => {
    getorderStatus();
  }, []);

  useEffect(() => {
    if (values.status) {
      changeStatus();
    }
  }, [values.status]);

  const changeStatus = async () => {
    let datas = orderData?.filter((x) => {
      if (x.id) {
        return x;
      }
    });
    if (datas.length > 0) {
      datas = datas[0];

      const body = {
        supplierOrderId: datas?.supplierOrderId,
        orderId: datas?.orderId,
        itemId: datas?.itemId,
        status: values.status,
      };
      const res = await adminStatusChange(body);
      if (res && res.status === true) {
        enqueueSnackbar(res.message, { variant: "success" });
      }
      if (res && res.status === false) {
        enqueueSnackbar(
          res.message || "Unexpected error occured please try again later.",
          { variant: "error" }
        );
      }
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h1
            style={{
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "26px",
              marginBottom: "8px",
            }}
          >
            {" "}
          </h1>
        </div>
        <Box
          borderRadius="12px"
          style={{
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#60579A",
              borderRadius: "10px",
            }}
            onClick={() => {
              navigate(`/orders`);
            }}
          >
            <strong
              style={{ color: "white", fontWeight: "500", fontSize: "13px" }}
            >
              Back
            </strong>
          </Button>
        </Box>
      </div>
      <Card
        style={{
          padding: "20px",
          borderRadius: "12px",
          marginTop: "20px",
        }}
      >
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Order" value="1" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Grid item md={12} sm={8} xs={12} container spacing={1}>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography variant="h2" id="purchase-order-details-number">
                    Order No. {orderProductData.orderNumber}
                  </Typography>
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                  <Typography
                    sx={{
                      color: "rgb(107, 114, 128)",
                      fontWeight: "500",
                      marginTop: "20px",
                      fontSize: "20px",
                    }}
                  >
                    Shipping Address
                  </Typography>

                  <Typography
                    sx={{
                      color: "black",
                      fontWeight: "500",
                      marginTop: "20px",
                      fontSize: "19px",
                    }}
                  >
                    {supplshippingAddress?.address} {city?.cityName}{" "}
                    {states?.stateName}, {country?.name}{" "}
                    {supplshippingAddress?.zipcode}
                  </Typography>
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                  <TableContainer
                    component={Paper}
                    sx={{
                      minHeight: "300px",
                      marginTop: "10px",
                      boxShadow: "none",
                    }}
                  >
                    <Table stickyHeader aria-label="simple table">
                      <TableHead>
                        <StyledTableCell
                          style={{
                            fontWeight: "700",
                            fontSize: "16px",
                          }}
                        >
                          Product Name
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontWeight: "700",
                            fontSize: "16px",
                          }}
                        >
                          Quantity
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontWeight: "700",
                            fontSize: "16px",
                          }}
                        >
                          Supplier Price
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontWeight: "700",
                            fontSize: "16px",
                          }}
                        >
                          Status
                        </StyledTableCell>
                      </TableHead>
                      <TableBody>
                        {orderData.map((row, index) =>
                          orderData.length - 1 === index ? (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <TableCell>{row.productName}</TableCell>
                              <TableCell>{row.itemQty}</TableCell>

                              {isStatusEditing === row.id ? (
                                <Box sx={{ marginTop: "15px" }}>
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    id="status"
                                    name="supplierPrice"
                                    value={row?.supplierPrice}
                                    open={statusMenu}
                                    onOpen={openStatusMenu}
                                    onKeyPress={(e) => {
                                      if (e.key === "Enter") {
                                        stopStatusEditing();
                                        closeStatusMenu();
                                      }
                                    }}
                                    onChange={(event) =>
                                      startStatusUpdate(event, index)
                                    }
                                    onBlur={() => stopStatusEditing()}
                                    sx={{ width: "200px" }}
                                  >
                                    {row?.supplierPrice}
                                  </TextField>
                                </Box>
                              ) : (
                                <Button
                                  onClick={() => {
                                    startStatusEditing(row.id);
                                    openStatusMenu();
                                  }}
                                  size="large"
                                  style={{ color: "black", margin: "17px" }}
                                  endIcon={<EditIcon />}
                                >
                                  {row?.supplierPrice
                                    ? row?.supplierPrice
                                    : 0.0}
                                </Button>
                              )}

                              <TableCell>{orderStatus(row?.status)}</TableCell>

                              <IconButton
                                variant="contained"
                                onClick={onSubmit}
                                sx={{
                                  backgroundColor: "#60579A",
                                  color: "white",
                                  borderRadius: "10px",
                                  "&:hover": {
                                    backgroundColor: "#60579A",
                                  },
                                }}
                              >
                                <SendIcon />
                              </IconButton>

                              {!hideButton && (
                                <IconButton
                                  variant="contained"
                                  sx={{
                                    backgroundColor: "#60579A",
                                    color: "white",
                                    borderRadius: "10px",
                                    "&:hover": {
                                      backgroundColor: "#60579A",
                                    },
                                    marginLeft: "15px",
                                  }}
                                  onClick={() => approveQuotation(row.id, 4)}
                                >
                                  <CheckIcon />
                                </IconButton>
                              )}
                            </TableRow>
                          ) : (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <TableCell>{row.productName}</TableCell>
                              <TableCell>{row.itemQty}</TableCell>

                              <TableCell>{row.supplierPrice}</TableCell>

                              <TableCell sx={{ width: "30%" }}>
                                {orderStatus(row?.status)}
                              </TableCell>
                            </TableRow>
                          )
                        )}{" "}
                        {orderProductData?.length === 0 ? (
                          <TableRow>
                            <TableCell
                              sx={{
                                color: "silver",
                                textAlign: "center",
                                paddingTop: "90px",
                                borderBottom: "none",
                                fontSize: "30px",
                              }}
                              colSpan="7"
                            >
                              No records to display
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                  <Typography variant="h6" id="purchase-order-details-status">
                    Status
                  </Typography>
                </Grid>

                <Grid container md={12} sm={12} xs={12}>
                  <Grid
                    item
                    md={3}
                    sm={12}
                    xs={12}
                    style={{
                      borderLeft: "4px solid #60579A",
                    }}
                  >
                    <Typography
                      variant="h6"
                      id="purchase-order-details-status-value"
                    >
                      Order Status
                    </Typography>
                  </Grid>

                  <Grid item md={9} sm={12} xs={12}>
                    <Typography
                      variant="h6"
                      id="purchase-order-details-status-value2"
                    >
                      <select
                        name="status"
                        value={values.status}
                        onChange={handleInputChange}
                        style={{
                          width: "50%",
                          height: "37px",
                          borderadius: "20px",
                          border: " 2px solid rgb(204, 204, 204)",
                          padding: "7px",
                          marginTop: "8px",
                          fontSize: "16px",
                          backgroundColor: "rgb(255, 255, 255)",
                        }}
                      >
                        <option value="">{orderStatus(orderstatus)}</option>
                        {orderStatusList.map((status) => (
                          <option value={status.id} key={status?.key}>
                            {status?.name}
                          </option>
                        ))}
                      </select>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography variant="h6" id="purchase-order-details-status">
                    Date
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={3}
                  sm={12}
                  xs={12}
                  style={{
                    borderLeft: "4px solid #60579A",
                  }}
                >
                  <Typography
                    variant="h6"
                    id="purchase-order-details-status-value"
                  >
                    Order Date
                  </Typography>
                </Grid>

                <Grid item md={9} sm={12} xs={12}>
                  <Typography
                    variant="h6"
                    id="purchase-order-details-status-value2"
                  >
                    {moment(orderData.createdAt).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
          </TabContext>
        </Box>
      </Card>
    </>
  );
}
