import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
} from "@mui/material";

import { changeStatus } from "../../../state/actions/supplierActions";
import { useSnackbar } from "notistack";
import BackdropComponent from "../../backdrop/backdrop";

const RejectRequest = (props) => {
  const { user, open, handleClose, refreshScreen } = props;
  const { enqueueSnackbar } = useSnackbar();
  //  backdrop states
  const [backdropState, setBackdropState] = useState(false);
  const stopBackdrop = () => {
    setBackdropState(false);
  };
  const startBackdrop = () => {
    setBackdropState(true);
  };

  const onSubmit = async () => {
    const body = {
      status: "rejected",
      userId: user.id,
      type: "supplier",
    };
    startBackdrop();
    const res = await changeStatus(body);
    if (res && res.status === true) {
      handleClose();
      refreshScreen();
      enqueueSnackbar(res.message, { variant: "success" });
    }
    if (res && res.status === false) {
      handleClose();
      refreshScreen();
      enqueueSnackbar(res.message, { variant: "error" });
    }
    stopBackdrop();
  };
  return (
    <div>
      <BackdropComponent open={backdropState} />
      <Dialog
        id="rejectRequest"
        sx={{
          "&#rejectRequest .MuiPaper-root": {
            width: "500px",
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Reject Request
        </DialogTitle>

        <DialogContent>
          <div>Are you sure you want to reject this customer ?</div>

          <Typography sx={{ mt: 3, fontWeight: "bold", mb: 1 }}>
            Reject Reason
          </Typography>
          <TextField
            multiline
            fullWidth
            minRows={4}
            maxRows={4}
            placeholder="Enter a description..."
          />
        </DialogContent>
        <DialogActions sx={{ margin: "15px", marginLeft: "auto" }}>
          <Button
            sx={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid #0F0F0F80",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                // backgroundColor: "#60579A",
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: "#60579A",
              color: "white",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                backgroundColor: "#60579A",
              },
            }}
            onClick={onSubmit}
          >
            Reject
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RejectRequest;
