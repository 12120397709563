import axiosRequest from "../axios/axios";

export const getOrder = async (queryString) => {
  try {
    const res = await axiosRequest.get(`/api/v1/orderList${queryString}`);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
};

export const getProductDetails = async (id) => {
  try {
    const res = await axiosRequest.get(`/api/v1/orderDetail/${id}`);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
};

//get order details
export const getOrderDetails = async (id) => {
  try {
    const res = await axiosRequest.get(`/api/v1/OrderDetail/${id}`);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
};

export const GetOrderSuppliersList = async () => {
  try {
    const res = await axiosRequest.get(`/api/v1/activeSupplierList`);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
};

// get order status

export const GetOrderStatusList = async () => {
  try {
    const res = await axiosRequest.get(`/api/v1/orderStatusList`);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
};

// Supplier Quatation

export const supplierQuatation = async (data) => {
  try {
    const res = await axiosRequest.post(`/api/v1/SupplierQuatation`, data);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
};

//supplier order creates
export const supplierOrderCreates = async (data) => {
  try {
    const res = await axiosRequest.post(`/api/v1/supplierOrderCreate`, data);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
};

// get supplier order list
export const getSupplierOrderList = async (id) => {
  try {
    const res = await axiosRequest.get(`/api/v1/supplierOrderList/${id}`);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
};

//get supplier order details

export const getSupplierOrderDetails = async (id) => {
  try {
    const res = await axiosRequest.get(
      `/api/v1/supplierQuotationDetail/${id}/2`
    );
    return res.data;
  } catch (err) {
    return err.res.data;
  }
};

//admin list

export const getAdminQuatationList = async () => {
  try {
    const res = await axiosRequest.get(`/api/v1/adminQuatationList`);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
};

//admin new accpeted get

export const getAdminSupplierOrderList = async (queryString) => {
  try {
    const res = await axiosRequest.get(
      `/api/v1/adminSupplierOrderList${queryString}`
    );

    return res.data;
  } catch (err) {
    return err.res.data;
  }
};

export const supplierQuotationsAccepted = async (id, data) => {
  try {
    const res = await axiosRequest.post(
      `/api/v1/supplierQuotationAccepted/${id}`,
      data
    );
    return res.data;
  } catch (err) {
    return err.res.data;
  }
};

//admin status

export const adminStatusChange = async (data) => {
  try {
    const res = await axiosRequest.post(`api/v1/adminChangeOrderStatus`, data);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
};

//supplier status

export const supplierStatusChange = async (data) => {
  try {
    const res = await axiosRequest.post(
      `api/v1/supplierChangeOrderStatus`,
      data
    );
    return res.data;
  } catch (err) {
    return err.res.data;
  }
};
