// import axios from "axios";
// import {
//   REGISTER_SUCCESS,
//   REGISTER_FAIL,
//   USER_LOADED,
//   AUTH_ERROR,
//   LOGIN_SUCCESS,
//   LOGIN_FAIL,
//   LOGOUT,
//   CHANGE_PASSWORD,
// } from "../action-types/authActionTypes";

// import handleAuthToken from "../../utils/handleAuthToken";

// //Register user

// // export const register = ({ name, email, password }) => async dispatch => {
// //   const config = {
// //     headers: {
// //       "Content-Type": "application/json"
// //     }
// //   };
// //   const body = JSON.stringify({ name, email, password });
// //   try {
// //     const res = await axios.post("/api/v1/admin/users/register", body, config);

// //     if (res && res.data && res.data.success) {
// //       // dispatch({
// //       //     type: REGISTER_SUCCESS,
// //       //     payload: res.data&&res.data.data
// //       // });
// //       // dispatch(loadUser());
// //     } else {
// //       // dispatch({
// //       //     type: REGISTER_FAIL
// //       // });
// //     }
// //     return res.data;
// //   } catch (err) {
// //     // dispatch({
// //     //     type: REGISTER_FAIL
// //     // });
// //   }
// // };

// ///////////////////////////////////////////////////////
// export const loadUser = () => async (dispatch) => {
//   if (localStorage.token) {
//     handleAuthToken(localStorage.token);
//   }
//   try {
//     const res = await axios.get("/api/v1/userDetailsByToken");
//     if (res.data && res.data.status === true) {
//       dispatch({
//         type: USER_LOADED,
//         payload: res.data.data,
//       });
//     } else {
//       dispatch({
//         type: AUTH_ERROR,
//       });
//     }
//   } catch (err) {
//     dispatch({
//       type: AUTH_ERROR,
//     });
//   }
// };

// ////////////////////////////////

// //Login user

// export const loginUser = (email, password) => async (dispatch) => {
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//     },
//   };
//   const body = JSON.stringify({ email, password });
//   try {
//     const res = await axios.post("/api/v1/login", body, config);
//     if (res.data && res.data.status === true) {
//       dispatch({
//         type: LOGIN_SUCCESS,
//         payload: res.data.data,
//       });
//     } else {
//       dispatch({
//         type: LOGIN_FAIL,
//       });
//     }
//     return res.data;
//   } catch (err) {
//     dispatch({
//       type: LOGIN_FAIL,
//     });
//     return { ...err.response.data };
//   }
// };

// // export const changePassword = data => async dispatch => {
// //   const config = {
// //     headers: {
// //       "Content-Type": "application/json"
// //     }
// //   };
// //   const body = JSON.stringify(data);
// //   try {
// //     const res = await axios.post(
// //       "/api/v1/admin/users/changepassword",
// //       body,
// //       config
// //     );

// //     if (res && res.data && res.data.success) {
// //       dispatch({
// //         type: CHANGE_PASSWORD,
// //         payload: res.data && res.data.data
// //       });
// //       // dispatch(loadUser());
// //       dispatch({ type: LOGOUT });
// //     }

// //     return res.data;
// //   } catch (err) {
// //     return { success: false, message: error };
// //   }
// // };

// export const logout = () => (dispatch) => {
//   dispatch({ type: LOGOUT });
// };

// export const forgotPassword = (data) => async (dispatch) => {
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//     },
//   };
//   const body = JSON.stringify(data);
//   try {
//     const res = await axios.post("/api/v1/forgotPassword", body, config);
//     return res.data;
//   } catch (err) {
//     return { success: false, message: err.response.data.message };
//   }
// };

// // export const forgotPasswordValidation = data => async dispatch => {
// //   const config = {
// //     headers: {
// //       "Content-Type": "application/json"
// //     }
// //   };
// //   const body = JSON.stringify(data);
// //   try {
// //     const res = await axios.post(
// //       "/api/v1/admin/users/resetTokenValidation",
// //       body,
// //       config
// //     );

// //     return res.data;
// //   } catch (err) {
// //     return { success: false, message: error };
// //   }
// // };

import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "../action-types/authActionTypes";
import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const BASE_URL = "https://pressemporium.mobiginie.com";
// const BASE_URL = "http://localhost:4200";

export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.token}`;
  }
  try {
    const res = await axios.get(`${BASE_URL}/api/v1/adminDetailsByToken`);
    if (res.data && res.data.status === true) {
      dispatch({
        type: USER_LOADED,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: AUTH_ERROR,
      });
    }
    return res.data;
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
    return { success: false, message: err.response.data.message };
  }
};

export const loginUser = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email, password });
  try {
    const res = await axios.post(`${BASE_URL}/api/v1/login`, body, config);
    if (res.data && res.data.status === true) {
      if (password === true) {
        cookies.set("token", res.data.data.token, { path: "/" });
        // cookies.set("email", email.email, { path: "/" });
        // cookies.set("password", email.password, { path: "/" });
      } else {
      }
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: LOGIN_FAIL,
      });
    }
    return res.data;
  } catch (error) {
    console.log("error", error);
    return { success: false, message: error.response.data.message };
  }
};

//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//     },
//   };
//   const body = JSON.stringify({ email, password });
//   try {
//     const res = await axios.post("/api/v1/login", body, config);
//     if (res.data && res.data.status === true) {
//       dispatch({
//         type: LOGIN_SUCCESS,
//         payload: res.data.data,
//       });
//     } else {
//       dispatch({
//         type: LOGIN_FAIL,
//       });
//     }
//     return res.data;
//   } catch (err) {
//     dispatch({
//       type: LOGIN_FAIL,
//     });
//     return { ...err.response.data };
//   }
// };

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};

export const forgotPassword = (data) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify(data);
  try {
    const res = await axios.post("/api/v1/forgotPassword", body, config);
    return res.data;
  } catch (err) {
    return { success: false, message: err.response.data.message };
  }
};
