import { useState } from "react";

import { Grid, Button, Stack, Typography, IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
import { Box, Tabs, Tab, Badge } from "@mui/material";
import { styled } from "@mui/material/styles";

import UsersList from "./PESuppliersList/PESupplierList";
import UsersRequest from "./PESupliersRequest/PESupplierRequest";

import AddUserModal from "./AddPESupplier";

const StyledTab = styled(Box)(() => ({
  marginTop: "32px",
  width: "100%",
  "div > div > div > .MuiButtonBase-root": {
    color: "black",
    fontWeight: "600",
  },
  "div > div > div > .Mui-selected": {
    color: "black !important",
    backgroundColor: "white",
    borderRadius: " 10px 10px 0px 0px",
  },
  "div > div > div > .MuiTabs-indicator": {
    display: "none",
  },
  "div > div > div > .MuiTabs-flexContainer": {
    height: "0px",
  },
}));
const UserPage = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const TabPanel = ({ value, index, children }) => {
    if (value === index) {
      return (
        <Box
          sx={{
            mt: 2,
          }}
        >
          {children}
        </Box>
      );
    }
  };

  const [addUser, setAddUser] = useState(false);
  const openUserModal = () => {
    setAddUser(true);
  };
  const closeUserModal = () => {
    setAddUser(false);
  };
  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "32px",
            color: "#0F0F0F",
          }}
        >
          Suppliers
        </Typography>
        <Button
          sx={{
            backgroundColor: "#60579A",
            color: "white",
            borderRadius: "10px",
            height: "3em",
            padding: "5px 27px",
            "&:hover": {
              backgroundColor: "#60579A",
            },
          }}
          onClick={openUserModal}
          startIcon={<Add />}
          color="primary"
        >
          Add Supplier
        </Button>
      </Stack>
      <AddUserModal
        open={addUser}
        setOpen={setAddUser}
        handleClickOpen={openUserModal}
        handleClose={closeUserModal}
      />
      <StyledTab>
        <TabPanel
          value={value}
          index={0}
          sx={{
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          <UsersList />
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          sx={{
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          <UsersRequest />
        </TabPanel>
      </StyledTab>
    </>
  );
};
export default UserPage;
