import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { Card, FormLabel, Grid, Stack, TextField, Typography } from "@mui/material";
import {  styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
// import avtarimage from "../../assets/Rectangle752.png";
import { useLocation } from "react-router-dom";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
} from "@mui/material";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { getProductDetails } from "../../state/actions/orderAction"

const strong = {
  color: "black",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));
const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "rgba(15, 15, 15, 0.5)",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiInput-root": {},
}));




const UserDetails = () => {
  
  const { state } = useLocation();


  const navigate = useNavigate();

  var parent = "";


  const [orderDetails, setorderDetails] = useState({});
  const [openBackdrop, setOpenBreakdrop] = useState(false);


  const id = state;
  const getOrderDetails = async (id) => {
    setOpenBreakdrop(true);
    const res = await getProductDetails(id);
    if (res && res.data) {
      setorderDetails(res.data);
      setOpenBreakdrop(false);
    }
  };

  useEffect(() => {
    getOrderDetails(id);
  }, [id]);

 

 
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", paddingRight: "5px" }}>
          <div
            className="back-btuoon-details-equip-order"
            onClick={() => {
              if (parent) {
                navigate(parent);
              } else {
                navigate("/user");
              }
            }}
          >
            <ArrowBackIosIcon style={{ width: "15px" }} />
            <span>Back</span>
          </div>
        </div>
      </div>
      <Card
        style={{
          padding: "20px",
          borderRadius: "12px",
          marginTop: "20px",
        }}
      >
        <Grid item md={12} sm={8} xs={12} container spacing={1}>
          <Grid item md={12} xs={12}>
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                variant="h6"
                style={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "24px",
                  lineHeight: "32px",
                }}
              >
               Customer Details
                
              </Typography>
            </Grid>  
       </Grid>


       <Grid md={12} sm={8} xs={12} spacing={1}>
              <Grid
                container
                xs={12} 
                sx={{ m: 1 }}
                style={{ alignItems: "center" }}
              >
               
                  <Grid item sx={{ m: 1 }} xs={5.6}>
                    <StyledFormLabel htmlFor="fname">Name </StyledFormLabel>
                    <StyledTextField
                      inputProps={{ readOnly: true }}
                      variant="standard"
                      fullWidth
                      id="fname"
                      name="fname"
                      defaultValue="name"
                    />
                  </Grid>
            
                  
                    <Grid item sx={{ m: 1 }} xs={5.6}>
                      <StyledFormLabel htmlFor="fname">
                        First Name{" "}
                      </StyledFormLabel>
                      <StyledTextField
                        inputProps={{ readOnly: true }}
                        variant="standard"
                        fullWidth
                        id="fname"
                        name="fname"
                        defaultValue="first name"
                      />
                    </Grid>
                    <Grid item sx={{ m: 1 }} xs={5.6}>
                      <StyledFormLabel htmlFor="lname">
                        Last Name{" "}
                      </StyledFormLabel>
                      <StyledTextField
                        inputProps={{ readOnly: true }}
                        variant="standard"
                        fullWidth
                        id="lname"
                        name="lname"
                        defaultValue="last name"
                      />
                    </Grid>
                  
              

                <Grid item sx={{ m: 1 }} xs={5.6}>
                  <StyledFormLabel htmlFor="email">Email</StyledFormLabel>
                  <StyledTextField
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    fullWidth
                    id="email"
                    defaultValue="email"
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} xs={5.6}>
                  <StyledFormLabel htmlFor="mobile">Mobile</StyledFormLabel>
                  <StyledTextField
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    fullWidth
                    id="mobile"
                    defaultValue="mobile numnber"
                  />
                </Grid>
                  </Grid>
                  </Grid>
        </Grid>
       
      </Card>
    </>
  );
}

export default UserDetails;
