import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Stack,
  Grid,
  Paper,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import camera from "../../../assets/camera.svg";
import { TextField, FormLabel, MenuItem, Input } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import { deleteSupplierDetails } from "../../../state/actions/supplierActions";

const UserDelete = (props) => {
  const { user, open, handleClose, supplierId, supplierList } = props;

  const deleteSupplier = async () => {
    const res = await deleteSupplierDetails(supplierId);
    if (res.status === true) {
      handleClose();
      supplierList();
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Delete
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          Are you sure you want to delete this Supplier ?
        </DialogContent>
        <DialogActions sx={{ margin: "15px", mx: "auto" }}>
          <Button
            sx={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid #0F0F0F80",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                // backgroundColor: "#60579A",
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: "#60579A",
              color: "white",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                backgroundColor: "#60579A",
              },
            }}
            // type="submit"
            onClick={deleteSupplier}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserDelete;
