import axiosRequest from "../axios/axios";

///////////////////////////////////////////////////////
export const GetBuyersList = async (queryString) => {
  try {
    const res = await axiosRequest.get(`/api/v1/userlist` + queryString);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};
///////////////////////////////////////////////////////
export const GetBuyersRequestList = async (queryString) => {
  try {
    const res = await axiosRequest.get(`/api/v1/userNewList` + queryString);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};
///////////////////////////////////////////////////////

export const changeStatus = async (body) => {
  try {
    const res = await axiosRequest.post(`/api/v1/statusUser`, body);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

///////////////////////////////////////////////////////
export const getStates = async (countryId) => {
  try {
    const res = await axiosRequest.get(
      `/api/v1/statelist/` + countryId + "?page=1&size=-1"
    );
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

///////////////////////////////////////////////////////

// export const AddBuyer = async (data) => {
//   try {
//     const body = data;
//     var formData = new FormData();
//     Object.keys(body).map((key) => {
//       formData.append(key, body[key]);
//     });
//     const res = await axiosRequest.post(`/api/v1/registerBuyer`, formData);
//     return res.data;
//   } catch (err) {
//     return { ...err.response.data };
//   }
// };

//buyer status change
export const changeBuyerStatus = async (body) => {
  try {
    const res = await axiosRequest.post(`/api/v1/statusUser`, body);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

//add buyer
export const AddBuyer = async (data) => {
  try {
    const res = await axiosRequest.post(`/api/v1/registerBuyerByAdmin`, data);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

//update buyer
export const UpdateBuyer = async (id, data) => {
  try {
    const res = await axiosRequest.post(`/api/v1/updateUser/${id}`, data);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

//details of buyer
export const getBuyerDetails = async (id) => {
  try {
    const res = await axiosRequest.get(`/api/v1/userDetails/${id}`);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

//conste delete buyer
export const deleteBuyer = async (id) => {
  try {
    const res = await axiosRequest.delete(`/api/v1/deleteUser/${id}`);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

//export buyerdata
export const exportBuyerData = async () => {
  try {
    const res = await axiosRequest.get(`/api/v1/userCSV`);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};
