import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
} from "@mui/material";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import camera from "../../assets/camera.svg";
import { TextField, FormLabel, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useForm } from "../../utils/useForm";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  getCountries,
  getStates,
  getCities,
} from "../../state/actions/addressAction";
import {
  addSuppleir,
  getSupplierDetails,
  updateSupplierDetails,
} from "../../state/actions/supplierActions";
import BackdropComponent from "../../../src/components/backdrop/backdrop";

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "50px",
  },
  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "14px",
  },
}));

const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
}));
const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",
  img: {
    marginTop: "30px",
  },
}));

const StyledInput = styled("input")({
  display: "none",
});
const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});
const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});

const initialValues = {
  name: "",
  email: "",
  mobile: "",
  country: "",
  state: "",
  city: "",
  address: "",
  zipcode: "",
};


const AddForm = (props) => {
  const { open, refreshScreen, handleClose, supplierId } = props;
  const [image, setImage] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [backdropState, setBackdropState] = useState(false);
  const [countries, setCountries] = useState([]);
  const [edit, setEdit] = useState(false);

  const stopBackdrop = () => {
    setBackdropState(false);
  };
  const startBackdrop = () => {
    setBackdropState(true);
  };
  const resetImage = () => {
    setImage("");
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    if ("email" in fieldValues) {
      if (fieldValues.email !== "") {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(fieldValues.email)) {
          temp.email = "";
        } else {
          temp.email = "Please enter valid email.";
        }
      } else {
        temp.email = fieldValues.email ? "" : "This field is required.";
      }
    }


    if ("mobile" in fieldValues) {
      if (fieldValues.mobile !== "") {
        if (/^[0-9\s]*$/.test(fieldValues.mobile)) {
          temp.mobile = "";
        } else {
          temp.mobile = "Only Number allow";
        }
      } else {
        temp.mobile = "This field is required.";
      }
    }

    if ("country" in fieldValues)

      temp.country = fieldValues.country !== "" ? "" : "This field is required.";
    console.log(temp.country)

    if ("state" in fieldValues)
      temp.state = fieldValues.state !== "" ? "" : "This field is required.";

    if ("city" in fieldValues)
      temp.city = fieldValues.city !== "" ? "" : "This field is required.";

    if ("address" in fieldValues)
      temp.address = fieldValues.address ? "" : "This field is required.";

    if ("zipcode" in fieldValues)
      temp.zipcode = fieldValues.zipcode ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const getStateList = async (countryId) => {
    const res = await getStates(countryId);
    if (res && res.status) {
      setStates(res.data);
    }
  };

  const getAllCountry = async () => {
    startBackdrop();
    const res = await getCountries();
    if (res && res.status) {
      setCountries(res.data);
    }
    stopBackdrop();
  };

  const getCityList = async (stateId) => {
    const res = await getCities(stateId);
    if (res && res.status) {
      setCities(res.data);
    }
  };

  useEffect(() => {
    getAllCountry();
  }, []);


  useEffect(() => {
    if (values.country) {
      getStateList(values.country);
    }
  }, [values.country]);

  useEffect(() => {
    if (supplierId) {
      setEdit(true);
      getSupplier(supplierId);
    }
  }, [supplierId]);

  useEffect(() => {
    if (values.state) {
      getCityList(values.state);
    }
  }, [values.state]);
  const { enqueueSnackbar } = useSnackbar();

  const getSupplier = async () => {
    startBackdrop();
    const res = await getSupplierDetails(supplierId);
    console.log("🚀 ~ file: AddPESupplier.jsx:222 ~ getSupplier ~ res", res?.data?.cityId)
    if (res && res.status === true) {
      getAllCountry();
      if (res.data.countryId) {
        getStateList(res.data.countryId);
      }
      if (res.data.stateId) {
        getCityList(res.data.stateId);
      }
      setValues({
        ...values,
        name: res.data.name,
        email: res.data.email,
        mobile: res.data.mobile,
        country: res.data.countryId,
        state: res.data.stateId,
        city: res.data.cityId,
        address: res.data.address,
        zipcode: res.data.zip,
      });
      if (res.data.profile_image) {
      setImage(res.data.profile_image);
      }
    }
    stopBackdrop();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("mobile", values.mobile);
      formData.append("countryId", values.country);
      formData.append("stateId", values.state);
      formData.append("cityId", values.city);
      formData.append("address", values.address);
      formData.append("zip", values.zipcode);
      if (values.profile_image) {
        formData.append("profile_image", values.profile_image);
      }
      startBackdrop();

      if (edit) {
        const res = await updateSupplierDetails(supplierId, formData);
        if (res && res.status) {
          close();
          refreshScreen();
          enqueueSnackbar("Suppleir Updated Successfully", {
            variant: "success",
          });
        }
      } else {
        const res = await addSuppleir(formData);
        if (res && res.status) {
          close();
          refreshScreen();
          enqueueSnackbar("Suppleir Added Successfully", {
            variant: "success",
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
          });
        }
      }
        stopBackdrop();

    }
  };

  const getCountryList = () => {
    const res = getCountries();
    if (res.status === 200) {
      setCountryData(res.data);
    }
  };
  useEffect(() => {
    getCountryList();
    // getStateList();
  }, []);

  const openConfirmDialog = () => {
    setConfirmDialog(true);
  };
  const closeConfirmDialog = () => {
    setConfirmDialog(false);
  };
  const reset = () => {
    resetImage();
    resetForm();
  };
  const close = () => {
    reset();
    handleClose();
  };

  const removeImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["profile_image"];
      return newValues;
    });
  };

  async function handleImageItem(e) {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      const base64String = await getBase64(selected);
      setImage(base64String);
      setValues({ ...values, profile_image: selected });
    } else {
    }
  }

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  return (
    <div>

      {/* add new user modal */}
      <Dialog
        open={open}
        onClose={openConfirmDialog}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {edit ? "Edit Supplier" : "Add New Supplier"}
          {/* Add New Supplier */}
          <Tooltip title="Close">
            <IconButton onClick={openConfirmDialog}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <form onSubmit={handleSubmit} id="addNewUser">
            <Grid container spacing={2}>
              <Grid item md={3} sm={4} xs={12}>
                <StyledImageUploadWrapper>
                  <label htmlFor="image-upload">
                    <StyledInput
                      accept="image/*"
                      id="image-upload"
                      type="file"
                      onChange={handleImageItem}
                      onClick={(event) => {
                        event.currentTarget.value = null;
                      }}
                    />
                    <StyledIconWrapper
                      sx={
                        image == ""
                          ? {}
                          : {
                            backgroundImage: `url(${image})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }
                      }
                    >
                      {image === "" && <img src={camera} alt="Camera" />}
                    </StyledIconWrapper>
                  </label>
                  <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                    Add Photo
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(15, 15, 15, 0.5)",
                      mx: 2,
                      fontSize: "12px",
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, max size of 3.1 MB
                  </Typography>
                  {image != "" && (
                    <StyledRemoveButton
                      onClick={(e) => {
                        e.preventDefault();
                        removeImage();
                      }}
                    >
                      Remove
                    </StyledRemoveButton>
                  )}
                </StyledImageUploadWrapper>
              </Grid>

              <Grid item md={9} sm={8} xs={12} container spacing={1}>
                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <StyledFormLabel htmlFor="name">
                    Name
                    {Boolean(errors.name) && (
                      <StyledRequired> * </StyledRequired>
                    )}
                  </StyledFormLabel>
                  <StyledTextField
                    fullWidth
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleInputChange}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <StyledFormLabel htmlFor="email">
                    Email{" "}
                    {Boolean(errors.email) && (
                      <StyledRequired> * </StyledRequired>
                    )}
                  </StyledFormLabel>
                  <StyledTextField
                    fullWidth
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <StyledFormLabel htmlFor="mobile">
                    Phone Number
                    {Boolean(errors.mobile) && (
                      <StyledRequired> * </StyledRequired>
                    )}
                  </StyledFormLabel>
                  <StyledTextField
                    fullWidth
                    id="mobile"
                    name="mobile"
                    value={values.mobile}
                    onChange={handleInputChange}
                    error={Boolean(errors.mobile)}
                    helperText={errors.mobile}
                    inputProps={{ maxLength: 15, minLength: 10 }}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  <StyledFormLabel htmlFor="address">
                    Address
                    {Boolean(errors.address) && (
                      <StyledRequired> * </StyledRequired>
                    )}
                  </StyledFormLabel>
                  <StyledTextField
                    fullWidth
                    id="address"
                    name="address"
                    value={values.address}
                    onChange={handleInputChange}
                    error={Boolean(errors.address)}
                    helperText={errors.address}
                    inputProps={{ maxLength: 500 }}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <StyledFormLabel htmlFor="country">
                    Country{" "}
                    {Boolean(errors.country) && (
                      <StyledRequired> * </StyledRequired>
                    )}
                  </StyledFormLabel>
                 
                  <select
                    id="country"
                    name="country"
                    value={values.country}
                    onChange={handleInputChange}
                    error={Boolean(errors.country)}
                    helperText={errors.country}
                    style={{
                      width: "100%",
                      height: "50px",
                      borderRadius: "10px",
                      border: "1px solid #ccc",
                      padding: "5px",
                      marginTop: "10px",
                      fontSize: "16px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <option value="0">Select Country</option>
                    {countries.map((country) => (
                      <option value={country.id}>{country.name}</option>
                    ))}
                  </select>
                  {Boolean(errors.country) && (
                    <p class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root css-v7esy.Mui-error"> {errors.country} </p>
                  )}
                </Grid>
                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <StyledFormLabel htmlFor="state">
                    State{" "}
                    {Boolean(errors.state) && (
                      <StyledRequired> * </StyledRequired>
                    )}
                  </StyledFormLabel>
              
                  <select
                    id="state"
                    name="state"
                    value={values.state}
                    onChange={handleInputChange}
                    error={Boolean(errors.state)}
                    helperText={errors.state}
                    style={{
                      width: "100%",
                      height: "50px",
                      borderRadius: "10px",
                      border: "1px solid #ccc",
                      padding: "5px",
                      marginTop: "10px",
                      fontSize: "16px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <option value="0">Select State</option>
                    {states.map((state) => (
                      <option value={state.id}>{state.stateName}</option>
                    ))}
                  </select>
                  {Boolean(errors.state) && (
                    <p class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root css-v7esy.Mui-error"> {errors.state} </p>
                  )}
                </Grid>
                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <StyledFormLabel htmlFor="city">
                    City
                    {Boolean(errors.city) && (
                      <StyledRequired> * </StyledRequired>
                    )}
                  </StyledFormLabel>
          
                  <select
                    id="city"
                    name="city"
                    value={values.city}
                    error={Boolean(errors.city)}
                    helperText={errors.city}
                    onChange={handleInputChange}
                    style={{
                      width: "100%",
                      height: "50px",
                      borderRadius: "10px",
                      border: "1px solid #ccc",
                      padding: "5px",
                      marginTop: "10px",
                      fontSize: "16px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <option value="0">Select City</option>
                    {cities.map((city) => (
                      <option value={city.id}>{city.cityName}</option>
                    ))}
                  </select>
                  {Boolean(errors.city) && (
                    <p class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root css-v7esy.Mui-error"> {errors.city} </p>
                  )}
                </Grid>

                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <StyledFormLabel htmlFor="zipcode">
                    Zipcode{" "}
                    {Boolean(errors.zipcode) && (
                      <StyledRequired> * </StyledRequired>
                    )}
                  </StyledFormLabel>
                  <StyledTextField
                    fullWidth
                    id="zipcode"
                    name="zipcode"
                    value={values.zipcode}
                    onChange={handleInputChange}
                    error={Boolean(errors.zipcode)}
                    helperText={errors.zipcode}
                    inputProps={{ maxLength: 6 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions sx={{ margin: "15px" }}>
          <Button className="cancel-button" onClick={close}>
            Cancel
          </Button>
          <Button className="button" type="submit" form="addNewUser">
            {edit ? "Save" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        id="confirm-dialog"
        open={confirmDialog}
        onClose={
          closeConfirmDialog
        }
        sx={{
          "&#confirm-dialog .MuiPaper-root": {
            borderRadius: "15px",
          },
        }}
      >
        <DialogContent>
          Are you sure you want to Close this form ?
        </DialogContent>
        <DialogActions
          sx={{
            margin: "15px",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <Button
            className="cancel-button"
            onClick={
              closeConfirmDialog
            }
          >
            Cancel
          </Button>
          <Button
            className="button"
            // type="submit"
            onClick={() => {
              closeConfirmDialog();
              handleClose();
               resetForm();
              reset();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddForm;
