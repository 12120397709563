import axiosRequest from "../axios/axios";

///////////////////////////////////////////////////////
export const getCountries = async () => {
  try {
    const res = await axiosRequest.get(`/api/v1/countryNameList`);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

export const getStates = async (countryId) => {
  try {
    const res = await axiosRequest.get(`/api/v1/stateNameList/${countryId}`);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

export const getCities = async (stateId) => {
  try {
    const res = await axiosRequest.get(`/api/v1/cityNameList/${stateId}`);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};
