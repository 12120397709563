import axiosRequest from "../axios/axios";

export const getSubCategory = async (queryString) => {
  try {
    const res = await axiosRequest.get(`/api/v1/getSubCategoryList${queryString}`);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

export const AddSubCategory = async (data) => {
  try {
    const res = await axiosRequest.post(`/api/v1/addSubCategory`, data);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

export const deleteSubCategory = async (id) => {
  try {
    const res = await axiosRequest.delete(`/api/v1/deleteSubCategory/${id}`);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

export const UpdateSubCategory = async (data, id) => {
  try {
    const res = await axiosRequest.post(`/api/v1/updateSubCategory/${id}`, data);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

export const categorySubStatusChange = async (id) => {
  try {
    const res = await axiosRequest.post(`/api/v1/subCategoryStatus/${id}`);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};


