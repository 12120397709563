import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { Stack, Grid, Typography, IconButton } from "@mui/material";
import camera from "../../assets/camera.svg";
import { TextField, FormLabel, MenuItem, Input } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import { Close } from "@mui/icons-material";
import { GetRoles } from "../../state/actions/roleActions";
import Logo from "../../assets/logo1.png"

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "rgba(15, 15, 15, 0.5)",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiInput-root": {
    paddingBottom: "20px",
  },
}));

const AdminDetails = (props) => {
  const { user, open, handleClose } = props;
  const [rolesList, setRolesList] = useState([]);
  const getRolesList = async (page) => {
    let queryString = "?page=" + page + "&size=-1";
    const res = await GetRoles(queryString);
    if (res && res.status) {
      setRolesList(res.data);
    }
  };
  useEffect(() => {
    if (open) {
      getRolesList(1);
    }
  }, [open]);

  const getRole = (roleId) => {
    roleId = Number(roleId);
    const element = rolesList.find((role) => role.id === roleId);
    if (element) return element.name;
    else return "";
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Admin Details
          <Tooltip title="Close">
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={3} sm={4} xs={12}>
              <Stack
                sx={{
                  mt: 2,
                  padding: "30px 0px",
                  border: "1px solid rgba(15, 15, 15, 0.15)",
                  // height: "206px",
                  borderRadius: "10px",
                }}
                flexDirection="column"
                alignItems="center"
                spacing={2}
              >

             
                <img
                  src={user?.profile_image ?  user?.profile_image  :  Logo}
                  style={{ width: "80px", height: "80px" }}
                  alt="user profile"
                />
                <div style={{ textAlign: "center" }}>
                  <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                    {user?.name}
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(15, 15, 15, 0.5)",
                      fontSize: "12px",
                      mt: 1,
                    }}
                  >
                    {getRole(user?.role)}
                  </Typography>
                </div>
              </Stack>
            </Grid>
            <Grid item md={9} sm={8} xs={12} container spacing={1}>
              <>
                <Grid item sx={{ m: 1 }} xs={12}>
                  <StyledFormLabel htmlFor="email">Email </StyledFormLabel>
                  <StyledTextField
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    fullWidth
                    id="email"
                    name="email"
                    defaultValue={user?.email}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} xs={12}>
                  <StyledFormLabel htmlFor="phone_number">
                    Phone Number
                  </StyledFormLabel>
                  <StyledTextField
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    fullWidth
                    id="phone_number"
                    defaultValue={user?.mobile}
                  />
                </Grid>
              </>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ margin: "15px" }}>
          <Button
            sx={{
              backgroundColor: "#60579A",
              color: "white",
              borderRadius: "10px",
              padding: "10px 25px",
              height: "3em",
              "&:hover": {
                backgroundColor: "#60579A",
              },
            }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdminDetails;
