import axiosRequest from "../axios/axios";

///////////////////////////////////////////////////////
export const GetSuppliersList = async (queryString) => {
  try {
    const res = await axiosRequest.get(`/api/v1/supplierlist` + queryString);

    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};
///////////////////////////////////////////////////////
export const GetSuppliersRequestList = async (queryString) => {
  try {
    const res = await axiosRequest.get(
      `/api/v1/supplierRequestList` + queryString
    );
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};
///////////////////////////////////////////////////////

//  we are using same api for user and supplier to change their status
export const changeStatus = async (body) => {
  try {
    const res = await axiosRequest.post(`/api/v1/statusUser`, body);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

///////////////////////////////////////////////////////
export const deleteSupplier = async (id) => {
  try {
    const res = await axiosRequest.delete(`/api/v1/deleteSupplier/${id}`);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

export const addSuppleir = async (body) => {
  try {
    const res = await axiosRequest.post(`/api/v1/addSupplier`, body);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

//get supplier details
export const getSupplierDetails = async (id) => {
  try {
    const res = await axiosRequest.get(`/api/v1/supplierDetails/${id}`);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

//update supplier details
export const updateSupplierDetails = async (id, body) => {
  try {
    const res = await axiosRequest.post(`/api/v1/updateSupplier/${id}`, body);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

//delete supplier details
export const deleteSupplierDetails = async (id) => {
  try {
    const res = await axiosRequest.delete(`/api/v1/deleteSupplier/${id}`);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

//changes supplier status
export const changeSupplierStatus = async (payload) => {
  try {
    const res = await axiosRequest.post(
      `/api/v1/changeSupplierStatus`,
      payload
    );
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};
