import { useEffect, useState } from "react";
import { Grid, Box, Button, Typography, IconButton } from "@mui/material";
import camera from "../../assets/camera.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { TextField, FormLabel, MenuItem, Input, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";
import Switch from "@mui/material/Switch";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { categoryDropdown } from "../../state/actions/categoryAction";
import {
  addProduct,
  updateProduct,
  getProductDetails,
  deleteProductImage,
  getSubCategoryList,
} from "../../state/actions/productAction";

import { useForm } from "../../utils/useForm";
import DeleteProductModel from "./DeleteProduct";
import BackdropComponent from "../backdrop/backdrop";
import { useSnackbar } from "notistack";

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontFamily: "Work Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "30px",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "50px",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "30px",
  },
  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "14px",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "30px",
  },
}));
const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "220px",
  borderRadius: "10px",
  textAlign: "center",
}));
const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

const StyledInput = styled("input")({
  display: "none",
});
const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});

const StyledDiv = styled("div")({
  display: "flex",
  background: "#FFFFFF",
  boxShadow:
    "  0px 20px 94px rgba(16, 24, 40, 0.01), 0px 8px 30px rgba(16, 24, 40, 0.01)",
  borderRadius: "12px !important",
  padding: "22px",
  width: "100%",
  height: "100%",
});
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#60579A",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const StyledParagraph = styled("p")({
  // fontStyle: "normal",
  // fontWeight: "400",
  fontSize: "16px",
  lineHeight: "34px",
  marginTop: "0px",
  fontFamily: "Work Sans",
});
const StyledHeading = styled("h1")({
  // fontFamily: 'Work Sans',
  // fontStyle: "normal",
  fontWeight: "600",
  fontSize: "24px",
  lineHeight: "32px",
});

const initialValues = {
  name: "",
  description: "",
  sku: "",
  categoryId: "",
  subCategoryId: "",
  mrpPrice: "",
  sellPrice: "",
  discountedPrice: "",
  quantity: "",
  manufacturer: "",
  isActive: "1",
  productType: "",
  supplierId: "",
  createdBy: "",
  updatedBy: "",
  trendingFlag: "yes",
};

const AddProduct = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [backdropState, setBackdropState] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const stopBackdrop = () => {
    setBackdropState(false);
  };
  const startBackdrop = () => {
    setBackdropState(true);
  };
  const user = useSelector((state) => state.auth.user);

  var { state, pathname } = useLocation();

  //  we have 2 routes one for adding new product and second for edit product
  pathname = pathname.split("/")[2];

  // for edit route
  var id = state?.id;
  const getDetails = async (id) => {
    startBackdrop();
    const res = await getProductDetails(id);
    if (res && res.status) {
      getSubCategories(Number(res.data.productData.categoryId));
      var { productData, productSpecificationData, productImages } = res.data;
      delete productData["createdAt"];
      delete productData["updatedAt"];
      delete productData["id"];
      productData["productId"] = id;
      setValues({ ...values, ...productData });
      setSpecificationArr(
        productSpecificationData.map((value) => ({
          name: value.name,
          value: value.value,
        }))
      );
      setFiles(productImages);
    }
    stopBackdrop();
  };
  useEffect(() => {
    if (pathname === "edit") {
      if (state === null) {
        navigate("/dashboard");
      } else {
        if (id) {
          getDetails(id);
        }
      }
    }
  }, [state]);

  const getCategories = async () => {
    startBackdrop();
    const res = await categoryDropdown();
    if (res && res.status) {
      setCategories(res.data);
    }
    stopBackdrop();
  };

  const getSubCategories = async (id) => {
    startBackdrop();
    const payload = {
      categoryId: id,
    };
    const res = await getSubCategoryList(payload);
    if (res && res.status) {
      setSubCategories(res.data);
    }
    stopBackdrop();
  };

  useEffect(() => {
    getCategories();
  }, []);

  //  to handle local files
  const [files, setFiles] = useState([]);
  async function handleImageItem(e) {
    const selectedFiles = e.target.files;
    var localFiles = [...Array.from(selectedFiles)];

    var uniqueFiles = [];
    const onlyLocalImages = files.filter((file) => isLocalImage(file));
    for (let index = 0; index < localFiles.length; index++) {
      const element = localFiles[index];
      const fileName = element.name;
      var flag = false;
      for (let index2 = 0; index2 < onlyLocalImages.length; index2++) {
        const element2 = onlyLocalImages[index2];
        if (element2.file.name === fileName) {
          flag = true;
          break;
        }
      }
      if (!flag) {
        uniqueFiles.push(element);
      }
    }
    localFiles = uniqueFiles.map((file) => ({
      file: file,
      src: URL.createObjectURL(file),
    }));
    setFiles([...files, ...localFiles]);
  }

  const isLocalImage = (image) => {
    return "src" in image;
  };

  const getImageURL = (file) => {
    if (isLocalImage(file)) {
      return file.src;
    } else {
      return file.imageUrl;
    }
  };
  const revokeLocalImageURL = (url) => {
    URL.revokeObjectURL(url);
  };
  const removeLocalImage = (index) => {
    setFiles(files.filter((value, i) => i !== index));
  };

  const inputSpecificationArr = [
    {
      name: "",
      value: "",
    },
  ];
  const [specificationarr, setSpecificationArr] = useState(
    inputSpecificationArr
  );
  const addClick = async (event) => {
    setSpecificationArr((s) => {
      return [
        ...s,
        {
          name: "",
          value: "",
        },
      ];
    });
  };
  const handleSpecificationChange = (e) => {
    e.preventDefault();
    const index = e.target.id;
    const filedname = e.target.name;
    setSpecificationArr((s) => {
      const newArr = s.slice();
      if (filedname === "name") {
        newArr[index].name = e.target.value;
      } else {
        newArr[index].value = e.target.value;
      }
      return newArr;
    });
  };

  //  for validation
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";

    if ("sku" in fieldValues)
      temp.sku = fieldValues.sku ? "" : "This field is required.";
    if ("categoryId" in fieldValues)
      temp.categoryId = fieldValues.categoryId ? "" : "This field is required.";
    if ("subCategoryId" in fieldValues)
      temp.subCategoryId = fieldValues.subCategoryId
        ? ""
        : "This field is required.";
    if ("description" in fieldValues)
      temp.description = fieldValues.description
        ? ""
        : "This field is required.";
    if ("mrpPrice" in fieldValues)
      temp.mrpPrice = fieldValues.mrpPrice ? "" : "This field is required.";

    if ("sellPrice" in fieldValues)
      temp.sellPrice = fieldValues.sellPrice ? "" : "This field is required.";

    if ("discountedPrice" in fieldValues)
      temp.discountedPrice = fieldValues.discountedPrice
        ? ""
        : "This field is required.";
    if ("quantity" in fieldValues)
      temp.quantity = fieldValues.quantity ? "" : "This field is required.";

    if ("manufacturer" in fieldValues)
      temp.manufacturer = fieldValues.manufacturer
        ? ""
        : "This field is required.";

    if ("productType" in fieldValues)
      temp.productType = fieldValues.productType
        ? ""
        : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  //  form object
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  //  store the product image details while removing product image
  const [productImageDetails, setProductImageDetails] = useState({});

  useEffect(() => {
    if (values.categoryId) {
      getSubCategories(values.categoryId);
    }
  }, [values.categoryId]);
  //  submit function for adding or updating product
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      var body = {
        data: {
          ...values,
          createdBy: user.id,
          updatedBy: user.id,
          supplierId: user.id,
          specification: JSON.stringify(specificationarr),
        },
        images: files
          .filter((file) => isLocalImage(file))
          .map((file) => file.file),
      };
      var res = {};
      startBackdrop();
      if (pathname === "new") {
        res = await addProduct(body);
      }
      if (pathname === "edit") {
        body = { ...body, id };
        res = await updateProduct(body);
      }
      if (res && res.status === true) {
        enqueueSnackbar(res.message, { variant: "success" });
        setTimeout(() => {
          navigate("/products");
        }, 2000);
        stopBackdrop();
      }

      if (res && res.status === false) {
        enqueueSnackbar(res.message, { variant: "error" });
        stopBackdrop();
      }
    }
  };

  //  for confirmation modal
  const [confirmationModal, setConfirmationModal] = useState(false);
  const handleConfirmationModal = () => {
    setConfirmationModal(true);
  };
  const closeConfirmationModal = () => {
    setConfirmationModal(false);
  };

  //  close confirmation modal when click on cancel button
  const close = () => {
    closeConfirmationModal();
  };

  //  this function will be executed when we click confirm button on confirmation modal while deleting
  //  prouduct images
  const onSuccess = () => {
    const body = productImageDetails;
    // pid represents proudct id
    if ("pid" in body) {
      removeOnlineImage();
    } else {
      removeOfflineImage();
    }
  };

  // this is for removing local image
  const removeOfflineImage = () => {
    const body = productImageDetails;
    const { index, url } = body;
    removeLocalImage(index);
    revokeLocalImageURL(url);
    close();
  };

  // this is for removing online image
  const removeOnlineImage = async () => {
    const body = productImageDetails;
    const res = await deleteProductImage(body);
    if (res && res.status === true) {
      close();
      enqueueSnackbar(res.message, { variant: "success" });
      const localId = body.localId;
      removeLocalImage(localId);
    }

    if (res && res.status === false) {
      close();
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  return (
    <>
      <BackdropComponent open={backdropState} />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <StyledHeading>
              {pathname === "new" ? "Add New Product" : "Edit Product"}
            </StyledHeading>
            <StyledParagraph>
              <span onClick={() => navigate("/products")}>Products | </span>
              <span style={{ color: "rgb(166 166 166)" }}>
                {pathname === "new" ? "Add New Product" : "Edit Product"}
              </span>
            </StyledParagraph>
          </Grid>
          <DeleteProductModel
            open={confirmationModal}
            handleClose={close}
            handleSuccess={onSuccess}
          />

          <StyledDiv>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item md={6} sm={8} xs={12} container spacing={1}>
                  <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                    <StyledFormLabel htmlFor="name">
                      Product Name{" "}
                      {Boolean(errors.name) && (
                        <StyledRequired> *</StyledRequired>
                      )}
                    </StyledFormLabel>
                    <StyledTextField
                      fullWidth
                      id="name"
                      name="name"
                      value={values.name}
                      onChange={handleInputChange}
                      error={Boolean(errors.name)}
                      helperText={errors.name}
                    />
                  </Grid>
                  <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                    <StyledFormLabel htmlFor="sku">
                      Product SKU{" "}
                      {Boolean(errors.sku) && (
                        <StyledRequired> *</StyledRequired>
                      )}
                    </StyledFormLabel>
                    <StyledTextField
                      fullWidth
                      id="sku"
                      name="sku"
                      value={values.sku}
                      onChange={handleInputChange}
                      error={Boolean(errors.sku)}
                      helperText={errors.sku}
                    />
                  </Grid>
                  <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                    <StyledFormLabel htmlFor="categoryId">
                      Product Category
                      {Boolean(errors.categoryId) && (
                        <StyledRequired> *</StyledRequired>
                      )}
                    </StyledFormLabel>
                    <StyledTextField
                      id="categoryId"
                      name="categoryId"
                      fullWidth
                      select
                      value={values.categoryId}
                      onChange={handleInputChange}
                      error={Boolean(errors.categoryId)}
                      helperText={errors.categoryId}
                    >
                      {categories.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </StyledTextField>
                  </Grid>
                  <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                    <StyledFormLabel htmlFor="subCategoryId">
                      Product Sub Category
                      {Boolean(errors.subCategoryId) && (
                        <StyledRequired> *</StyledRequired>
                      )}
                    </StyledFormLabel>
                    <StyledTextField
                      id="subCategoryId"
                      name="subCategoryId"
                      fullWidth
                      select
                      value={values.subCategoryId}
                      onChange={handleInputChange}
                      error={Boolean(errors.subCategoryId)}
                      helperText={errors.subCategoryId}
                    >
                      {subCategories.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </StyledTextField>
                  </Grid>
                  <Grid item sx={{ m: 1 }} xs={12}>
                    <StyledFormLabel htmlFor="description">
                      Product Description{" "}
                      {Boolean(errors.description) && (
                        <StyledRequired> *</StyledRequired>
                      )}
                    </StyledFormLabel>
                    <StyledTextField
                      fullWidth
                      id="description"
                      name="description"
                      multiline
                      value={values.description}
                      onChange={handleInputChange}
                      error={Boolean(errors.description)}
                      helperText={errors.description}
                    />
                  </Grid>
                  <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                    <StyledFormLabel htmlFor="mrpPrice">
                      Supplier Price{" "}
                      {Boolean(errors.mrpPrice) && (
                        <StyledRequired> *</StyledRequired>
                      )}
                    </StyledFormLabel>
                    <StyledTextField
                      type="number"
                      fullWidth
                      id="mrpPrice"
                      name="mrpPrice"
                      value={values.mrpPrice}
                      onChange={handleInputChange}
                      error={Boolean(errors.mrpPrice)}
                      helperText={errors.mrpPrice}
                    />
                  </Grid>
                  <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                    <StyledFormLabel htmlFor="sellPrice">
                      Selling Price{" "}
                      {Boolean(errors.sellPrice) && (
                        <StyledRequired> *</StyledRequired>
                      )}
                    </StyledFormLabel>
                    <StyledTextField
                      fullWidth
                      type="number"
                      id="sellPrice"
                      name="sellPrice"
                      value={values.sellPrice}
                      onChange={handleInputChange}
                      error={Boolean(errors.sellPrice)}
                      helperText={errors.sellPrice}
                    />
                  </Grid>

                  <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                    <StyledFormLabel htmlFor="discountedPrice">
                      Discounted Price
                      {Boolean(errors.discountedPrice) && (
                        <StyledRequired> *</StyledRequired>
                      )}
                    </StyledFormLabel>
                    <StyledTextField
                      fullWidth
                      type="number"
                      id="discountedPrice"
                      name="discountedPrice"
                      value={values.discountedPrice}
                      onChange={handleInputChange}
                      error={Boolean(errors.discountedPrice)}
                      helperText={errors.discountedPrice}
                    />
                  </Grid>

                  <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                    <StyledFormLabel htmlFor="quantity">
                      Quantity
                      {Boolean(errors.quantity) && (
                        <StyledRequired> *</StyledRequired>
                      )}
                    </StyledFormLabel>
                    <StyledTextField
                      fullWidth
                      type="number"
                      id="quantity"
                      name="quantity"
                      value={values.quantity}
                      onChange={handleInputChange}
                      error={Boolean(errors.quantity)}
                      helperText={errors.quantity}
                    />
                  </Grid>

                  <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                    <StyledFormLabel htmlFor="manufacturer">
                      Manufacturer
                      {Boolean(errors.manufacturer) && (
                        <StyledRequired> *</StyledRequired>
                      )}
                    </StyledFormLabel>
                    <StyledTextField
                      fullWidth
                      id="manufacturer"
                      name="manufacturer"
                      value={values.manufacturer}
                      onChange={handleInputChange}
                      error={Boolean(errors.manufacturer)}
                      helperText={errors.manufacturer}
                    />
                  </Grid>

                  <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                    <StyledFormLabel htmlFor="productType">
                      Product Type
                      {Boolean(errors.productType) && (
                        <StyledRequired> *</StyledRequired>
                      )}
                    </StyledFormLabel>
                    <StyledTextField
                      fullWidth
                      id="productType"
                      name="productType"
                      value={values.productType}
                      onChange={handleInputChange}
                      error={Boolean(errors.productType)}
                      helperText={errors.productType}
                    />
                  </Grid>
                  <Grid
                    style={{
                      alignItems: "center",
                      paddingTop: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    {specificationarr.map((item, i) => {
                      return (
                        <>
                          <Grid
                            container
                            spacing={2}
                            xs={12}
                            sx={{ marginLeft: "-12px" }}
                          >
                            <Grid
                              item
                              xs={specificationarr.length - 1 === i ? 4 : 6}
                            >
                              <StyledTextField
                                name="name"
                                label="Specification Key"
                                variant="outlined"
                                size="small"
                                type="text"
                                fullWidth
                                id={i}
                                onChange={handleSpecificationChange}
                                value={item.name}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={specificationarr.length - 1 === i ? 4 : 6}
                            >
                              <StyledTextField
                                name="value"
                                label="Specification Value"
                                variant="outlined"
                                size="small"
                                type="text"
                                id={i}
                                fullWidth
                                // onBlur={handleBlur}
                                onChange={handleSpecificationChange}
                                value={item.value}
                              />
                            </Grid>
                            {specificationarr.length - 1 === i ? (
                              <Grid
                                item
                                xs={specificationarr.length > 1 ? 4 : 2}
                              >
                                <Button
                                  type="button"
                                  style={{
                                    background: "#60579A",
                                    marginTop: "19px",
                                  }}
                                  variant="contained"
                                  sx={{ mt: 1.3 }}
                                  onClick={addClick}
                                >
                                  <AddIcon />
                                </Button>
                                {specificationarr.length > 1 ? (
                                  <Button
                                    type="button"
                                    variant="contained"
                                    sx={{ mt: 1.3, ml: 1 }}
                                    style={{
                                      background: "#F0F0F0",
                                    }}
                                    onClick={() => {
                                      setSpecificationArr((preval) => {
                                        preval.splice(-1);
                                        return [...preval];
                                      });
                                    }}
                                  >
                                    <DeleteIcon style={{ color: "red" }} />
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </Grid>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                </Grid>

                <Grid item md={6} sm={4} xs={12}>
                  <StyledImageUploadWrapper>
                    <label htmlFor="image-upload">
                      <StyledInput
                        accept="image/*"
                        id="image-upload"
                        type="file"
                        multiple
                        onChange={handleImageItem}
                        onClick={(event) => {
                          event.currentTarget.value = null;
                        }}
                      />
                      <StyledIconWrapper>
                        <img src={camera} alt="Camera" />
                      </StyledIconWrapper>
                    </label>
                    <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                      Add Photo
                    </Typography>
                    <Typography
                      sx={{
                        color: "rgba(15, 15, 15, 0.5)",
                        mx: 2,
                        fontSize: "12px",
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, max size of 3.1 MB
                    </Typography>
                    {/* {image != "" && (
                        <StyledRemoveButton
                          onClick={(e) => {
                            e.preventDefault();
                            removeImage();
                          }}
                        >
                          Remove
                        </StyledRemoveButton>
                      )} */}
                  </StyledImageUploadWrapper>
                  <Grid container item xs={12}>
                    {files.map((file, index) => {
                      const url = getImageURL(file);
                      return (
                        <div>
                          <StyledIconWrapper
                            sx={{
                              position: "relative",
                              margin: "25px 5px 5px 5px",
                              width: "100px",
                              height: "100px",
                              backgroundImage: `url(${url})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                            }}
                          >
                            <IconButton
                              size="large"
                              onClick={() => {
                                var body = {};
                                if (isLocalImage(file)) {
                                  body = {
                                    url: url,
                                    index: index,
                                  };
                                } else {
                                  body = {
                                    pid: file.productId,
                                    id: file.id,
                                    localId: index,
                                  };
                                }

                                setProductImageDetails(body);
                                handleConfirmationModal();
                              }}
                              sx={{
                                position: "absolute",
                                right: "2%",
                                top: "3%",
                                width: "36px",
                                height: "36px",
                                background: "rgba(15, 15, 15, 0.5)",
                                boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                                borderRadius: "10px",
                                // backgroundColor: "white",
                                color: "white",
                                "&:hover": {
                                  backgroundColor: "#60579A",
                                  // color: "white",
                                },
                              }}
                            >
                              <CloseIcon
                                sx={{ width: "20px", height: "20px" }}
                              />
                            </IconButton>
                          </StyledIconWrapper>
                        </div>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>

              <Grid sx={12} style={{ marginTop: "25px" }}>
                <Link
                  style={{
                    textDecoration: "none",
                  }}
                  to="/products"
                >
                  <Button
                    type="button"
                    // color="primary"
                    variant="contained"
                    sx={{
                      mb: 2,
                      px: 6,
                      backgroundColor: "white",
                      color: "black",
                      border: "1px solid #0F0F0F",
                      borderRadius: "10px",
                      padding: "10px 25px",
                      "&:hover": {
                        backgroundColor: "#ffffff",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                </Link>

                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mb: 2,
                    px: 6,
                    ml: 2,
                    backgroundColor: "#60579A",
                    color: "white",
                    height: "48px",
                    borderRadius: "10px",
                    padding: "10px 25px",
                    "&:hover": {
                      backgroundColor: "#60579A",
                    },
                  }}
                >
                  {pathname === "new" ? "Add Product" : "Save Product"}
                </Button>
              </Grid>
            </form>
          </StyledDiv>
        </Grid>
      </Box>
    </>
  );
};
export default AddProduct;
