import axiosRequest from "../axios/axios";

///////////////////////////////////////////////////////
export const getProductList = async (queryString) => {
  try {
    const res = await axiosRequest.get(`/api/v1/productlist` + queryString);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

///////////////////////////////////////////////////////
export const getProductDetails = async (id) => {
  try {
    const res = await axiosRequest.get(`/api/v1/productDetails/` + id);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

export const addProduct = async (body) => {
  try {
    const { data, images } = body;
    var formData = new FormData();

    Object.keys(data).map((key) => {
      formData.append(key, data[key]);
    });
    images.map((file) => {
      formData.append("images", file);
    });
    const res = await axiosRequest.post(`/api/v1/addProduct`, formData);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};
///////////////////////////////////////////////////////
export const deleteProduct = async (id) => {
  try {
    const res = await axiosRequest.delete(`/api/v1/deleteProduct/${id}`);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

///////////////////////////////////////////////////////
export const updateProduct = async (body) => {
  try {
    const { data, images, id } = body;
    var formData = new FormData();
    Object.keys(data).map((key) => {
      formData.append(key, data[key]);
    });
    images.map((file) => {
      formData.append("images", file);
    });
    const res = await axiosRequest.post(
      `/api/v1/updateProduct/${id}`,
      formData
    );
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

///////////////////////////////////////////////////////
export const deleteProductImage = async (body) => {
  try {
    const { pid, id } = body;
    const res = await axiosRequest.delete(
      `/api/v1/deleteProductImage/${pid}/${id}`
    );
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};

//get subcategory list
export const getSubCategoryList = async (payload) => {
  try {
    const res = await axiosRequest.post(`/api/v1/subCategoryDropdown`, payload);
    return res.data;
  } catch (err) {
    return { ...err.response.data };
  }
};
