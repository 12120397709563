import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
  Visibility as VisibilityIcon,
  Sort as SortIcon,
} from "@mui/icons-material";
import moment from "moment";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  InputBase,
  Grid,
  Button,
  Typography,
  Avatar,
  IconButton,
  Stack,
  Switch,
  Pagination,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
  Chip,
} from "@mui/material";
import edit from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import { tableCellClasses } from "@mui/material/TableCell";

import { getAdminQuatationList, getAdminSupplierOrderList } from "../../state/actions/orderAction";

//  filter icons
import filterIcon from "../../assets/filter.svg";
//  modals with respect to menu

//  dipatch category actions
import { useForm } from "../../utils/useForm";
import { Navigate, useNavigate } from "react-router-dom";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useSelector } from "react-redux";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "12px",
    paddingTop: "10px",
    paddingBottom: "10px",
    position: "sticky",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    // hover color add
    "&:hover": {
      color: "#60579A",
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#0957dd0a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableColumns = styled(Box)(({ theme }) => ({
  //set height of table columns
  display: "inline-flex",
  "&:hover": {
    cursor: "pointer",
    ".header-icon": {
      visibility: "visible",
    },
  },
}));

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "50px",
  },
  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "14px",
  },
}));

const initialValues = {
  stateName: "",
  status: "",
  countryId: "",
};

const NewAdminOrder = (props) => {
  const { refreshScreen, refresh } = props;

  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [filterData, setFilterData] = useState(true);
  const [orderList, setOrderList] = useState([]);
  const [item, setItem] = useState([]);
  const [orderNumbers, setOrderNumbers] = useState([]);

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [backdropState, setBackdropState] = useState(false);
  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentState, setcurrentState] = useState({});
  const [filter, setFilter] = useState(false);
  const [stateId, setStateId] = useState("");
  const [opens, setOpens] = useState([]);
  const [sortFlag, setSortFlag] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [flagName, setflagName] = useState(false);
  const [flagAmount, setflagAmount] = useState(false);


  const navigate = useNavigate();

  const stopBackdrop = () => {
    setBackdropState(false);
  };
  const startBackdrop = () => {
    setBackdropState(true);
  };

  const openConfirmDialog = () => {
    setConfirmDialog(true);
  };

  const closeConfirmDialog = () => {
    setConfirmDialog(false);
  };



  const getOrderList = async (page, name = "", flag) => {
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }


    // startBackdrop();
    const res = await getAdminSupplierOrderList();
    if (res && res.status === true) {
      setOrderList(res.data);
     
     
      stopBackdrop();
    } else {

      setOrderList([]);
   
    //   stopBackdrop();
    }
  };

  useEffect(() => {
    getOrderList();
  }, [refresh, ]);

  const handleChangePage = (event, value) => {
    getOrderList(value);
    setPage(value);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event, value) => {
    setAnchorEl(event.currentTarget);
    setcurrentState(value);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setcurrentState({});
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };
  const onFilterSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialValues,
    true
  );

  useEffect(() => {
    handleClose();
  }, [orderList]);

  const productStatus = (productstatus) => {
    const statusColors = {
      0: {
        key: "Closed",
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#f05252;",
      },
      1: {
        key: "Paid",
        backgroundColor: "#ede7dd",
        color: "#0e9f6e",
      },
      2: {
        key: "Pending",
        backgroundColor: "#fdf6b2",
        color: "#c27803",
      },
      3: {
        key: "Partial",
        backgroundColor: "#def7ec",
        color: "gray",
      },
    };

    return (
      <StyledChip
        style={{
          width: "70%",
          height: "23px",
          fontSize: "10px",
        }}
        sx={statusColors[productstatus]}
        label={
          statusColors[productstatus] ? statusColors[productstatus].key : ""
        }
      />
    );
  };


  const orderStatus = (orderstatus) => {
    const statusColors = {
    
      1: {
        key: "Pending",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      2: {
        key: "InProgress",
        backgroundColor: "#fdf6b2",
        color: "#c27803",
      },
      3: {
        key: " Ready to dispatch ",
        backgroundColor: "#def7ec",
        color: "#0e9f6e",
      },
      4: {
        key: "Shipped ",
        backgroundColor: "#def7ec",
        color: "blue",
      },
      5: {
        key: "Delivered",
        backgroundColor: "#def7ec",
        color: "green",
      },
      6: {
        key: "Cancel",
        backgroundColor: "#def7ec",
        color: "black",
      },
      7: {
        key: "Failed",
        backgroundColor: "#def7ec",
        color: "red",
      },

    };

    return (
      <StyledChip
        style={{
          width: "80%",
          height: "23px",
          fontSize: "10px",
        }}
        sx={statusColors[orderstatus]}
        label={
          statusColors[orderstatus] ? statusColors[orderstatus].key : ""
        }
      />
    );
  };

  const dataShorting = (name) => {
    if (name === "name") {
      setflagName(!flagName);
      getOrderList(page, name, !flagName);
    } else {
      setflagName(false);
      setSortFlag(!sortFlag);
    }

    if (name === "amount") {
      setflagAmount(!flagAmount);
      getOrderList(page, name, !flagAmount);
    } else {
      setflagAmount(false);
      setSortFlag(!sortFlag);
    }
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        {/* search bar and filter button */}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "40px",
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                setSearch(e.target.value);
                setPage(1);
              }}
            />
          </Search>

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              fontWeight: "bold",
              color: "black",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            Filter
          </Button>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form onSubmit={onFilterSubmit}>
            <Box
              sx={{
                width: 220,
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={{ fontWeight: "bold" }}>Filter</Typography>
                <IconButton
                  sx={{ color: "black" }}
                  onClick={closeFilterDrawer1}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="name">Name </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Name"
                  fullWidth
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />

              <Grid item md={4} xs={12}>
                <StyledFormLabel htmlFor="status">Status</StyledFormLabel>
                <StyledTextField
                  id="status"
                  name="status"
                  fullWidth
                  select
                  value={values.status}
                  //how to add place holder
                  onChange={handleInputChange}
                  error={Boolean(errors.status)}
                  helperText={errors.status}
                >
                  <MenuItem key="1" value="0">
                    Inactive
                  </MenuItem>
                  <MenuItem key="2" value="1">
                    Active
                  </MenuItem>
                  <MenuItem key="2" value="2">
                    Delete
                  </MenuItem>
                </StyledTextField>
              </Grid>
            </Box>
            <Stack
              sx={{ margin: "410px 20px 10px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    // backgroundColor: "#60579A",
                  },
                }}
                onClick={closeFilterDrawer}
              >
                Reset
              </Button>
              <Button
                sx={{
                  backgroundColor: "#60579A",
                  color: "white",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#60579A",
                  },
                }}
                type="submit"
              >
                Filter
              </Button>
            </Stack>
          </form>
        </Drawer>
        <TableContainer component={Paper} sx={{ minHeight: "300px" }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableCell onClick={() => dataShorting("name")}>
                Order Number
                {flagName ? (
                  <ArrowUpwardIcon
                    style={{
                      fontSize: "1rem",
                      position: "relative ",
                      top: "2px ",
                      left: "3px ",
                      cursor: "pointer ",
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    style={{
                      fontSize: "1rem",
                      position: "relative ",
                      top: "2px ",
                      left: "3px ",
                      cursor: "pointer ",
                    }}
                  />
                )}
              </StyledTableCell>

              <StyledTableCell>
              Product Name
              </StyledTableCell>

              <StyledTableCell>
              
              Quantity
              </StyledTableCell>

              <StyledTableCell>
              
              Price
              </StyledTableCell>

              <StyledTableCell>
                Date
              </StyledTableCell>

              <StyledTableCell
             
            >
               Status
            </StyledTableCell>
            </TableHead>
            <TableBody>
              {orderList.map((row, index) => (

                <StyledTableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => {
                    navigate("/orders/supplierorderdetails", {
                      state: row.orderId,
                       state: row.id

                    });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell component="th" scope="row">
                  {orderNumbers?.orderNumber}
                  </TableCell>
                 <TableCell >{item.name}</TableCell>
                  <TableCell>{row.itemQty}</TableCell>
                  <TableCell>{row.itemPrice}</TableCell>
                  <TableCell>
                    {moment(row.createdAt).format("MMM DD, YYYY")}
                  </TableCell>
                  <TableCell>{orderStatus(row.status)}</TableCell>
                </StyledTableRow>
              ))}{" "}
              {orderList?.length === 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="7"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          sx={{ mt: 0.3 }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              // handelEditState();
              //set edit category name
            }}
          >
            <ListItemIcon>
              <img src={edit} alt="Edit" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
          <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              // handelDeletestate();
            }}
          >
            <ListItemIcon>
              <img src={deleteIcon} alt="Delete" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
          <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              // handelEditState();
              //set edit category name
              navigate("/orders/invoice");
            }}
          >
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText>Invoice</ListItemText>
          </MenuItem>
        </Menu>

        <Dialog
          id="confirm-dialog"
          open={confirmDialog}
          onClose={closeConfirmDialog}
          sx={{
            "&#confirm-dialog .MuiPaper-root": {
              borderRadius: "15px",
            },
          }}
        >
          <DialogContent>Are you sure you want to Change status?</DialogContent>
          <DialogActions
            sx={{
              margin: "15px",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <Button className="cancel-button" onClick={closeConfirmDialog}>
              Cancel
            </Button>
            <Button className="button" onClick={() => {}}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
};

export default NewAdminOrder;
