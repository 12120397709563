import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import moment from "moment";
import Modal from "@mui/material/Modal";
import {
  TableContainer,
  Paper,
  Switch,
  Table,
  TableHead,
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  Pagination,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
} from "@mui/material";
import edit from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import { tableCellClasses } from "@mui/material/TableCell";

//  filter icons
import filterIcon from "../../assets/filter.svg";
//  modals with respect to menu
import RoleEditModal from "./AddRole";
import RoleDeleteModal from "./DeleteRoles";

//  dipatch role actions
import { GetRoles, changeStatus } from "../../state/actions/roleActions";
import { useForm } from "../../utils/useForm";
import BackdropComponent from "../backdrop/backdrop";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "12px",
    paddingTop: "10px",
    paddingBottom: "10px",
    position: "sticky",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    // hover color add
    "&:hover": {
      color: "#60579A",
    },
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
}));

const initialValues = {
  name: "",
};

const UsersList = (props) => {
  const { refresh, refreshScreen } = props;
  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [filterData, setFilterData] = useState(true);
  const [rolesList, setRolesList] = useState([]);
  const [backdropState, setBackdropState] = useState(false);
  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRole, setCurrentRole] = useState({});
  const [filter, setFilter] = useState(false);
  const [editRole, setEditRole] = useState(false);
  const [deleteRole, setDeleteRole] = useState(false);
  const [sortFlag, setSortFlag] = useState(false);
  const [flagName, setflagName] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [status, setStatus] = useState();
  const [checked, setChecked] = useState(true);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [switchId, setSwitchId] = useState(null);

  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);

  const stopBackdrop = () => {
    setBackdropState(false);
  };
  const startBackdrop = () => {
    setBackdropState(true);
  };

  const handleChangeStatus = async (id) => {
    const payload = {
      status: checked ? 1 : 2,
    };
    const res = await changeStatus(id, payload);
    if (res && res.status === true) {
      // getSupplierApiCall();
      getRolesList(page, sortBy, flagName);
      handleCloseSwitch();
    }
  };

  const getRolesList = async (page, name = "", flag) => {
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }

    let queryString = `?page=${page}&pagePerSize=${pagePerSize}&search=${search}${string}&sortby=${name}&sortFlag=${flag}`;
    startBackdrop();
    const res = await GetRoles(queryString);
    if (res && res.status === true) {
      setSortBy(name);
      setflagName(!sortFlag);
      setRolesList(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setRolesList([]);
      setTotalRecords(0);
      setTotalPages(0);
      setflagName(!sortFlag);
    }
    stopBackdrop();
    setFilter(false);
  };

  useEffect(() => {
    getRolesList(page);
  }, [refresh, filterData, page, search]);

  const handleChangePage = (event, value) => {
    getRolesList(value);
    setPage(value);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event, value) => {
    setAnchorEl(event.currentTarget);
    setCurrentRole(value);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setCurrentRole({});
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };
  const onFilterSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  const { values, handleInputChange, resetForm } =
    useForm(initialValues, true);

  const handleEditRole = () => {
    setEditRole(true);
  };
  const closeEditRole = () => {
    setEditRole(false);
  };

  const handleDeleteRole = () => {
    setDeleteRole(true);
  };
  const closeDeleteRole = () => {
    setDeleteRole(false);
  };

  useEffect(() => {
    handleClose();
  }, [rolesList]);

  //  to enable sorting in every table while clicking on column name
  const dataShorting = (name) => {
    if (name === "name") {
      setflagName(!flagName);
      getRolesList(page, name, !flagName);
    } else {
      setflagName(false);
      setSortFlag(!sortFlag);
    }
  };

  return (
    <>
      <BackdropComponent open={backdropState} />
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        {/* search bar and filter button */}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "40px",
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                setSearch(e.target.value);
                setPage(1);
              }}
            />
          </Search>

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              fontWeight: "bold",
              color: "black",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            Filter
          </Button>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form onSubmit={onFilterSubmit}>
            <Box
              sx={{
                width: 220,
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={{ fontWeight: "bold" }}>Filter</Typography>
                <IconButton
                  sx={{ color: "black" }}
                  onClick={closeFilterDrawer1}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="name">Name </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Name"
                  fullWidth
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />
            </Box>
            <Stack
              sx={{ margin: "410px 20px 10px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    // backgroundColor: "#60579A",
                  },
                }}
                onClick={closeFilterDrawer}
              >
                Reset
              </Button>
              <Button
                sx={{
                  backgroundColor: "#60579A",
                  color: "white",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#60579A",
                  },
                }}
                type="submit"
              >
                Filter
              </Button>
            </Stack>
          </form>
        </Drawer>
        <TableContainer component={Paper} sx={{ minHeight: "300px" }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{
                  fontWeight: "700",
                  fontSize: "16px",
                }} onClick={() => dataShorting("name")}>
                  Name
                  {flagName ? (
                    <ArrowUpwardIcon
                      style={{
                        fontSize: "1rem",
                        position: "relative ",
                        top: "2px ",
                        left: "3px ",
                        cursor: "pointer ",
                      }}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      style={{
                        fontSize: "1rem",
                        position: "relative ",
                        top: "2px ",
                        left: "3px ",
                        cursor: "pointer ",
                      }}
                    />
                  )}
                </StyledTableCell>
                <StyledTableCell style={{
                  fontWeight: "700",
                  fontSize: "16px",
                }}>Status</StyledTableCell>
                <StyledTableCell style={{
                  fontWeight: "700",
                  fontSize: "16px",
                }}>Created At</StyledTableCell>
                <StyledTableCell style={{
                  fontWeight: "700",
                  fontSize: "16px",
                }}>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rolesList.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={row.isActive === 1 ? true : false}
                      onChange={(e) => {
                        handleSwitchModel();
                        setStatus(
                          e.target.checked === true ? "Active" : "Inactive"
                        );
                        setSwitchId(row.id);
                        setChecked(e.target.checked);
                      }}
                    />
                  </TableCell>
                  <TableCell> {moment(row.createdAt).format("MMM DD, YYYY")}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={(e) => {
                        const currentRole = rolesList.find(
                          (role) => role.id === row.id
                        );
                        handleClick(e, currentRole);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}{" "}
              {rolesList?.length === 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="7"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          sx={{ mt: 0.3 }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              handleEditRole();
              //set edit role name
            }}
          >
            <ListItemIcon>
              <img src={edit} alt="Edit" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
          <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              handleDeleteRole();
            }}
          >
            <ListItemIcon>
              <img src={deleteIcon} alt="Delete" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>
        <RoleEditModal
          role={currentRole}
          open={editRole}
          setOpen={setEditRole}
          handleClose={() => {
            handleClose();
            closeEditRole();
          }}
          refreshScreen={refreshScreen}
        />
        <RoleDeleteModal
          role={currentRole}
          open={deleteRole}
          text="role"
          handleClose={() => {
            handleClose();
            closeDeleteRole();
          }}
          refreshScreen={refreshScreen}
        />
      </div>
      <Modal
        open={openSwitchModel}
        // onClose={handleClose}
        disableAutoFocus={true}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            bgcolor: "#FFFFFF",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <p className="modal-title"> Update Status </p>
          <p style={{ fontSize: "16px" }}>
            Are you sure you want to Update Status ?
          </p>

          <br />
          <div className="form-button-container">
            <button className="form-action-button" onClick={handleCloseSwitch}>
              Cancel
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              className="form-action-button-submit"
              onClick={() => handleChangeStatus(switchId)}
            >
              YES
            </button>
          </div>
        </Box>
      </Modal>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
};

export default UsersList;
