import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { TextField, FormLabel, MenuItem, Input, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useForm } from "../../utils/useForm";
import { Close } from "@mui/icons-material";
import { AddRole, UpdateRole } from "../../state/actions/roleActions";
import { useSnackbar } from "notistack";
import BackdropComponent from "../backdrop/backdrop";
import "./role.css";

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
}));

const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});

const Role = (props) => {
  const { open, refreshScreen, handleClose, role } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [backdropState, setBackdropState] = useState(false);
  const stopBackdrop = () => {
    setBackdropState(false);
  };
  const startBackdrop = () => {
    setBackdropState(true);
  };

  const initialValues = {
    name: "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [checkRole, setCheckRole] = useState(false);

  const openConfirmDialog = () => {
    setConfirmDialog(true);
  };
  const closeConfirmDialog = () => {
    setConfirmDialog(false);
    // resetForm();
  };
  const reset = () => {
    resetForm();
  };
  const close = () => {
    reset();
    handleClose();
    // resetForm();
  };

  useEffect(() => {
    if (role) {
      setValues({ name: role.name });
      setCheckRole(true);
    }
  }, [props]);

  const addNewRole = async (e) => {
    e.preventDefault();
    if (validate()) {
      if (checkRole) {
        const data = {
          name: values.name,
        };
        startBackdrop();
        const res = await UpdateRole(data, role);
        if (res && res.status === true) {
          close();
          refreshScreen();
          enqueueSnackbar(res.message, { variant: "success" });
        }
        if (res && res.status === false) {
          enqueueSnackbar(res.message, { variant: "error" });
        }
        stopBackdrop();
      } else {
        const data = values;
        startBackdrop();
        const res = await AddRole(data);
        if (res && res.status === true) {
          close();
          refreshScreen();
          enqueueSnackbar(res.message, { variant: "success" });
        }
        if (res && res.status === false) {
          enqueueSnackbar(res.message, { variant: "error" });
        }
        stopBackdrop();
      }
    }
  };

  return (
    <div>
      <BackdropComponent open={backdropState} />
      {/* add new user modal */}
      <Dialog
        open={open}
        onClose={openConfirmDialog}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {checkRole ? "Edit Role" : "Add New Role"}
          <Tooltip title="Close">
            <IconButton onClick={openConfirmDialog}>
              <Close />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <form onSubmit={addNewRole} id="addNewRole">
            <Grid item md={12} sm={12} xs={12} container spacing={1}>
              <Grid item sx={{ m: 1 }} xs={12}>
                <StyledFormLabel htmlFor="name">
                  Name
                  {Boolean(errors.name) && <StyledRequired> * </StyledRequired>}
                </StyledFormLabel>
                <StyledTextField
                  fullWidth
                  autoFocus={true}
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                  error={Boolean(errors.name)}
                  helperText={errors.name}
                  inputProps={{ maxLength: 80 }}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions sx={{ margin: "15px", marginLeft: "auto" }}>
          <Button
            className="cancel-button"
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            className="button"
            form="addNewRole"
            type="submit"
          >
            {checkRole ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* confirmation dialog */}
      <Dialog
        id="confirm-dialog"
        open={confirmDialog}
        onClose={closeConfirmDialog}
        sx={{
          "&#confirm-dialog .MuiPaper-root": {
            borderRadius: "15px",
          },
        }}
      >
        <DialogContent>
          Are you sure you want to Close this form ?
        </DialogContent>
        <DialogActions
          sx={{
            margin: "15px",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <Button
            className="cancel-button"
            onClick={closeConfirmDialog}
          >
            Cancel
          </Button>
          <Button
            className="button"
            onClick={() => {
              closeConfirmDialog();
              close();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Role;
