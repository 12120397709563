import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  Stack,
  Grid,
  Typography,
  IconButton,
  TextField,
  FormLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { getBuyerDetails } from "../../state/actions/buyerActions";
import DefaultImage from "../../assets/user1.png";

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontFamily: "Work Sans",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "34px",
  color: "rgba(15, 15, 15, 0.5)",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiInput-root": {
    paddingBottom: "20px",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "34px",
  },
}));

const StyledButton = styled(Button)(() => ({
  backgroundColor: "white",
  color: "black",
  border: "1px solid #0F0F0F80",
  borderRadius: "10px",
  padding: "10px 50px",
  "&:hover": {
    // backgroundColor: "#60579A",
  },
}));

const UserDetails = (props) => {
  const { user, open, handleClose, buyerId } = props;
  const [users, setUser] = useState({});

  useEffect(() => {
    if (buyerId) {
      console.log(123);
      getUsetDetails(buyerId);
    }
  }, [buyerId]);

  const getUsetDetails = async (buyerId) => {
    const res = await getBuyerDetails(buyerId);
    if (res && res.status === true) {
      setUser(res.data);
    }
  };

  const getStatus = (status) => {
    const mapStatus = {
      0: "Pending",
      1: "Active",
      2: "InActive",
      4: "Blocked",
    };
    return mapStatus[status];
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
        <DialogTitle
          sx={{
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "34px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Customer Details
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={3} sm={4} xs={12}>
              <Stack
                sx={{
                  mt: 2,
                  padding: "30px 0px",
                  border: "1px solid rgba(15, 15, 15, 0.15)",
                  // height: "206px",
                  borderRadius: "10px",
                }}
                flexDirection="column"
                alignItems="center"
                spacing={2}
              >
                <img
                  src={
                    users?.profile_image ? users?.profile_image : DefaultImage
                  }
                  style={{ width: "80px", height: "80px" }}
                  alt="customer profile"
                />
                <div style={{ textAlign: "center" }}>
                  <Typography
                    sx={{
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "34px",
                      color: "#0F0F0F",
                    }}
                  >
                    {users?.fname + " " + users?.lname}
                  </Typography>
                </div>

                {users?.status === "Pending" ? (
                  <>
                    <StyledButton>Approve</StyledButton>
                    <StyledButton sx={{ px: "60px" }}>Block</StyledButton>
                  </>
                ) : users?.status === "Approved" ? (
                  <>
                    <StyledButton>Block</StyledButton>
                    <StyledButton>Delete</StyledButton>
                  </>
                ) : users?.status === "Rejected" ? (
                  <>
                    <StyledButton>Approve</StyledButton>
                  </>
                ) : (
                  ""
                )}
              </Stack>
            </Grid>
            <Grid item md={9} sm={8} xs={12} container spacing={1}>
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <StyledFormLabel htmlFor="email">Email </StyledFormLabel>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  variant="standard"
                  fullWidth
                  id="email"
                  name="email"
                  value={users?.email}
                />
              </Grid>
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <StyledFormLabel htmlFor="mobile">Phone Number</StyledFormLabel>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  variant="standard"
                  fullWidth
                  id="mobile"
                  value={users?.mobile}
                />
              </Grid>

              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <StyledFormLabel htmlFor="status">Status</StyledFormLabel>

                <StyledTextField
                  inputProps={{ readOnly: true }}
                  variant="standard"
                  fullWidth
                  id="status"
                  value={getStatus(users?.isActive)}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ margin: "15px" }}>
          <Button
            sx={{
              backgroundColor: "#60579A",
              color: "white",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                backgroundColor: "#60579A",
              },
            }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserDetails;
