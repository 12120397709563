import { useState } from "react";
import { Button, Stack,  Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { Box } from "@mui/material";
import RolesList from "./RolesList";
import AddRoleModal from "./AddRole";
import "./role.css";




const Roles = () => {
  const [refresh, setRefresh] = useState(false);
  const refreshScreen = () => {
    setRefresh(!refresh);
  };
  const [addRole, setAddRole] = useState(false);
  const openRoleModal = () => {
    setAddRole(true);
  };
  const closeRoleModal = () => {
    setAddRole(false);
  };
  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
          Roles
        </Typography>
        <Button
          className="button"
          // style={{ backgroundColor: "#60579A", color: "white", borderRadius: "10px", padding: "5px 43px" }}
          // sx={{
          //   borderRadius: "10px",
          //   padding: "5px 43px",
          // }}
          onClick={openRoleModal}
          startIcon={<Add />}
          color="primary"
        >
          Add Role
        </Button>
      </Stack>
      <AddRoleModal
        open={addRole}
        setOpen={setAddRole}
        handleClickOpen={openRoleModal}
        handleClose={closeRoleModal}
        refreshScreen={refreshScreen}
      />
      <Box sx={{ mt: 4 }}>
        <RolesList refresh={refresh} refreshScreen={refreshScreen} />
      </Box>
    </>
  );
};
export default Roles;
