import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Button, Stack, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { Box } from "@mui/material";

import ProductList from "./ProductList";

const Products = () => {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "32px",
            color: "#0F0F0F",
          }}
        >
          Products
        </Typography>

        <Button
          sx={{
            backgroundColor: "#60579A",
            color: "white",
            borderRadius: "10px",
            padding: "5px 40px",
            "&:hover": {
              backgroundColor: "#60579A",
            },
          }}
          onClick={() => {
            navigate("/products/new");
          }}
          startIcon={<Add />}
          color="primary"
        >
          Add Product
        </Button>
      </Stack>

      <Box sx={{ mt: 4 }}>
        <ProductList refresh={refresh} refreshScreen={refreshScreen} />
      </Box>
    </>
  );
};
export default Products;
