import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Pagination,
  Tooltip,
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
  FormControl,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch,
  tableCellClasses,
} from "@mui/material";
import eye from "../../assets/eye.svg";
// import edit from "../../Assets/edit.svg";
import filterIcon from "../../assets/filter.svg";

import { useForm } from "../../utils/useForm";
import { useNavigate } from "react-router-dom";
import {
  getCountry,
  countrytStatusChange,
} from "../../state/actions/countryAction";
import BackdropComponent from "../backdrop/backdrop";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#0957dd0a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgb(241 241 241)",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "34px",
    color: "rgba(15, 15, 15, 0.5)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    // hover color add
    "&:hover": {
      color: "#60579A",
    },
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontFamily: "Work Sans",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "34px",
  color: "#0F0F0F",
}));

const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "45px",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "34px",
  },
}));

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const CountriesList = (props) => {
  const { refreshScreen, refresh } = props;

  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentState, setCurrentState] = useState(-1);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  async function getCountryList(page) {
    let queryString =
      "?page=" + page + "&size=" + pagePerSize + "&search=" + searchValue;

    Object.keys(values).map((key) => {
      queryString += "&" + key + "=" + values[key];
    });

    setOpenBreakdrop(true);
    const res = await getCountry(queryString);
    if (res && res.status) {
      setCountries(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setCountries([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setOpenBreakdrop(false);
  }
  // api call for get a category-list
  useEffect(() => {
    getCountryList(page);
  }, [refresh]);

  const handleChangePage = (event, value) => {
    setPage(value);
    getCountryList(value);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCurrentState(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSearch = async (search) => {
    if (search.target.value === "") {
      resetForm();
    }
    setSearchValue(search.target.value);

    let queryString =
      "?page=" + 1 + "&size=" + pagePerSize + "&search=" + search.target.value;

    setOpenBreakdrop(true);
    const res = await getCountry(queryString);
    if (res && res.status) {
      setCountries(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setCountries([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setOpenBreakdrop(false);
  };
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 700);
    };
  };

  const debounceserach = debounce(onSearch);
  const [filter, setFilter] = useState(false);
  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
  };
  const initialValues = {
    name: "",
    status: "",
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const onFilterSubmit = async (e) => {
    if (typeof e != "undefined") {
      e.preventDefault();
    }
    //  we are using initialValues because our values object is not resetting.
    var formValues = typeof e === "undefined" ? initialValues : values;
    let queryString = "?search=" + "&page=" + 1 + "&size=" + pagePerSize;
    Object.keys(formValues).map((key) => {
      queryString += "&" + key + "=" + formValues[key];
    });

    setOpenBreakdrop(true);
    const res = await getCountry(queryString);
    if (res && res.status) {
      setCountries(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setCountries([]);
      setTotalRecords(0);
      setTotalPages(0);
    }

    setOpenBreakdrop(false);
    closeFilterDrawer();
  };

  const [countryId, setCountryId] = useState("");

  const openConfirmDialog = () => {
    setConfirmDialog(true);
  };
  const closeConfirmDialog = () => {
    setConfirmDialog(false);
    // resetForm();
  };

  const changeStatus = async () => {
    const res = await countrytStatusChange(countryId);
    if (res && res.status === true) {
      closeConfirmDialog();
      refreshScreen();
    } else {
      closeConfirmDialog();
      refreshScreen();
    }
  };

  const handleChange = async (event, id) => {
    // openStatusModal();
    openConfirmDialog();
    setCountryId(id);
  };

  return (
    <>
      <BackdropComponent open={openBackdrop} />

      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        {/* search bar and filter button */}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "50px",
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onKeyUp={debounceserach}
            />
          </Search>

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              color: "black",
              fontFamily: "Work Sans",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "34px",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            Filter
          </Button>
        </Stack>
        {/* drawer */}
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form
            onSubmit={onFilterSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "250px",
            }}
          >
            <Box
              sx={{
                // width: 220,
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={{ fontWeight: "bold" }}>Filter</Typography>
                <IconButton sx={{ color: "black" }} onClick={closeFilterDrawer}>
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="name">Name </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Name"
                  fullWidth
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                />
              </Box>

              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="status">Status</StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Status"
                  fullWidth
                  id="status"
                  name="status"
                  value={values.status}
                  select
                  onChange={handleInputChange}
                >
                  <MenuItem sx={{ width: "250px" }} value={1}>
                    Active
                  </MenuItem>
                  <MenuItem sx={{ width: "250px" }} value={0}>
                    InActive
                  </MenuItem>
                </StyledTextField>
              </Box>
              <Divider sx={{ mt: 2 }} />
            </Box>
            <Stack
              sx={{ margin: "80px 20px 25px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "9px 19px",
                  "&:hover": {
                    // backgroundColor: "#60579A",
                  },
                  fontFamily: "Work Sans",
                  fontStyle: "normal",
                  fontWeight: 400,
                  top: "20px",
                }}
                onClick={closeFilterDrawer}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: "#60579A",
                  color: "white",
                  borderRadius: "10px",
                  padding: "9px 19px",
                  "&:hover": {
                    backgroundColor: "#60579A",
                  },
                  fontFamily: "Work Sans",
                  fontStyle: "normal",
                  fontWeight: 400,
                  top: "20px",
                }}
                type="submit"
              >
                Filter
              </Button>
            </Stack>
          </form>
        </Drawer>
        {/* table */}
        <TableContainer
          component={Paper}
          sx={{ minHeight: "300px", overflowY: "scroll" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Country Name</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {countries.map((row, index) => {
                var { id, countryName: name, isActive } = row;

                return (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    hover
                    onClick={() => {
                      navigate("/states", {
                        state: {
                          countryId: row.id,
                        },
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell
                      component="th"
                      sx={{
                        fontFamily: "Work Sans",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "34px",
                      }}
                    >
                      <Tooltip title="Click to view States" placement="top">
                        <Typography
                          sx={{
                            fontFamily: "Work Sans",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "34px",
                            mi: 2,
                            display: "inline",
                          }}
                        >
                          {row.name}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Work Sans",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "34px",
                      }}
                    >
                      <Switch
                        checked={row.isActive === 1 ? true : false}
                        onChange={(e) => {
                          handleChange(e, row.id);
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        name="checkedB"
                        color="primary"
                        id={row.id}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Work Sans",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "34px",
                      }}
                    >
                      <Tooltip title="Click to view States">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            const currentdata = countries.find(
                              (role) => role.id === row.id
                            );
                            handleClick(e, currentdata.id);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
              {countries?.length === 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="7"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          sx={{ mt: 0.3 }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={() => {
            handleClose();
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              navigate("/states", {
                state: {
                  countryId: currentState,
                },
              });
            }}
          >
            <ListItemIcon>
              <img src={eye} alt="Edit" />
            </ListItemIcon>
            <ListItemText
              sx={{
                fontFamily: "Work Sans",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "30px",
              }}
            >
              View States
            </ListItemText>
          </MenuItem>
        </Menu>

        <Dialog
          id="confirm-dialog"
          open={confirmDialog}
          onClose={closeConfirmDialog}
          sx={{
            "&#confirm-dialog .MuiPaper-root": {
              borderRadius: "15px",
            },
          }}
        >
          <DialogContent
            sx={{
              fontFamily: "Work Sans",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "15px",
              lineHeight: "30px",
            }}
          >
            Are you sure you want to Change status?
          </DialogContent>
          <DialogActions
            sx={{
              margin: "15px",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <Button className="cancel-button" onClick={closeConfirmDialog}>
              Cancel
            </Button>
            <Button
              className="button"
              onClick={() => {
                // closeConfirmDialog();
                // close();
                changeStatus();
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
};

export default CountriesList;
