import { useState } from "react";

import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/material";

import AdminSupplierOrderList from "./AdminSupplierOrdersList";

const SupplierOrder = () => {
  const [refresh, setRefresh] = useState(false);
  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "32px",
            color: "#0F0F0F",
          }}
        >
          Supplier Order Details
        </Typography>
      </Stack>

      <Box sx={{ mt: 4 }}>
        {/* <AdminSupplierOrderList
          refresh={refresh}
          refreshScreen={refreshScreen}
        /> */}
      </Box>
    </>
  );
};
export default SupplierOrder;
